import { useExcludePart, useIncludePart } from '@assemblio/frontend/data-access';
import { ModelController, StepController, useSequenceStore } from '@assemblio/frontend/stores';
import { Button, Tooltip } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { useEffect, useState } from 'react';

interface PartExIncludeButtonProps {
  modelContext: number;
}

export const PartExIncludeButton = ({ modelContext }: PartExIncludeButtonProps) => {
  const isPartExcluded = ModelController.isPartExcluded(modelContext);
  const partExcludeMutation = useExcludePart();
  const partIncludeMutation = useIncludePart();
  const [hasSteps, setHasSteps] = useState(false);

  useEffect(() => {
    if (modelContext !== undefined) {
      const hasStep = StepController.hasStep(modelContext);
      setHasSteps(hasStep);
    }
  }, [modelContext]);

  const setPartExcludedProperty = (exclude: boolean) => {
    const wasExcludedSet = ModelController.setPartsExcluded([modelContext], exclude);
    const currentSequenceId = useSequenceStore.getState().selectedSequenceId;

    if (wasExcludedSet.includes(modelContext)) {
      const catchFunction = () => {
        notifications.show({
          id: 'hide-part',
          message: "Couldn't change excluded state of part",
          color: 'red',
        });

        ModelController.setPartsExcluded([modelContext], !exclude);
      };

      if (exclude) {
        partExcludeMutation.mutate(
          { id: currentSequenceId, data: { gltfIndices: [modelContext] } },
          { onError: () => catchFunction() }
        );
      } else {
        partIncludeMutation.mutate(
          { id: currentSequenceId, data: { gltfIndices: [modelContext] } },
          { onError: () => catchFunction() }
        );
      }
    } else {
      notifications.show({
        id: 'hide-part',
        message: "Couldn't change excluded state of part. It may be already used in a step",
        color: 'red',
      });
    }
  };
  return (
    <Tooltip
      label={
        hasSteps
          ? 'Part cannot be excluded, because it is already used in the instruction.'
          : 'Exclude the part from this instruction.'
      }
      position="bottom"
      color={!hasSteps ? '' : 'red'}
    >
      <Button
        variant="default"
        disabled={hasSteps}
        onClick={() => {
          setPartExcludedProperty(!isPartExcluded);
        }}
      >
        {isPartExcluded ? 'Include' : 'Exclude'}
      </Button>
    </Tooltip>
  );
};
