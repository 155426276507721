const getNodeEnv = (): string => {
  const nodeEnv = process.env['NODE_ENV'];
  if (nodeEnv === undefined) return 'development';
  else return nodeEnv;
};

const getServerURL = (): string | undefined => {
  return process.env['NX_THE_SERVER_URL'];
};

const getApplicationType = (): string | undefined => {
  return process.env['NX_PUBLIC_APPLICATION'];
};

export const ConfigService = {
  getNodeEnv,
  getServerURL,
  getApplicationType,
};
