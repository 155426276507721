import { Sphere } from 'three';
import { GLTF } from 'three-stdlib';
import { create } from 'zustand';
import { devtools, subscribeWithSelector } from 'zustand/middleware';
import { AssemblyInformation, PartInformation } from './types/model.types';

export type ModelInformation = PartInformation | AssemblyInformation;

export type ModelInformationMap = Map<number, ModelInformation>;

export interface ModelStore extends Model {
  reset: () => void;
}

export interface Model {
  model: GLTF;
  modelInformationMap: ModelInformationMap;
  modelInformationMapCache: ModelInformationMap;
  initialBounds: Sphere;
  yOffset: number;
}

const init = (): Model => {
  return {
    modelInformationMap: new Map<number, ModelInformation>(),
    modelInformationMapCache: new Map<number, ModelInformation>(),
    model: {} as GLTF,
    initialBounds: new Sphere(),
    yOffset: 0.0,
  };
};

export const useModelStore = create<ModelStore>()(
  subscribeWithSelector(
    devtools(
      (set) => ({
        ...init(),
        reset: () => {
          set(init());
        },
      }),
      {
        name: 'Model Store',
        stateSanitizer: <ModelStore>(state: ModelStore) => {
          return { ...state, model: undefined, initialBounds: null };
        },
      }
    )
  )
);
