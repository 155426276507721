import { IntermediateProjectInstructionDto } from '@assemblio/shared/dtos';
import { Project } from '../stores/ProjectStore';

export const transformProjectDtoToProject = (
  dto: IntermediateProjectInstructionDto
): Project => {
  const project: Project = {
    projectId: dto.projectId,
    instructionId: dto.instructionId,
    name: dto.name,
    owner: dto.owner,
    state: dto.state,
    input: dto.input,
  };
  return project;
};
