interface CameraEvents {
  update: (camera: any) => void;
}

export class CameraEventEmitter {
  private target: EventTarget;

  constructor() {
    this.target = new EventTarget();
  }

  addEventListener<E extends keyof CameraEvents>(event: E, callback: CameraEvents[E]) {
    this.target.addEventListener(event, callback);
  }

  removeEventListener<E extends keyof CameraEvents>(event: E, callback: CameraEvents[E]) {
    this.target.removeEventListener(event, callback);
  }

  dispatchEvent<E extends keyof CameraEvents>(event: E) {
    this.target.dispatchEvent(new Event(event));
  }
}

export const cameraEvents = new CameraEventEmitter();
