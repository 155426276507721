import { cameraEvents } from '@assemblio/frontend/events';
import {
  CanvasController,
  StepController,
  UIController,
  quaternionToData,
  useModelStore,
  useUIStore,
  vector3ToData,
} from '@assemblio/frontend/stores';
import { useThree } from '@react-three/fiber';
import { useEffect, useState } from 'react';
import { OrthographicCamera, Vector3, Quaternion as ThreeQuaternion, Matrix4 } from 'three';
import { BrowserEvent } from '@assemblio/type/events';

export const useFramer = () => {
  const { camera, size } = useThree();
  const [initialized, setInitialized] = useState(false);
  const model = useModelStore((state) => state.model);
  const bounds = useModelStore((state) => state.initialBounds);
  const selectedStep = useUIStore((state) => state.selectedStep);
  useEffect(() => {
    if (model && model.scene && bounds) {
      const aspectRatio = size.width / size.height;
      const ortho = camera as OrthographicCamera;
      if (!initialized) {
        const cameraDirection = new Vector3(1, 0.5, 1).normalize();
        const cameraPosition = bounds.center.clone().add(cameraDirection.multiplyScalar(bounds.radius * 5));
        const cameraRotation = new ThreeQuaternion().setFromRotationMatrix(
          new Matrix4().lookAt(cameraPosition, bounds.center, new Vector3(0, 1, 0))
        );
        const zoom = 1 / (bounds.radius * 4);
        const distance = bounds.center.clone().distanceTo(cameraPosition);
        if (!selectedStep) {
          CanvasController.setCameraLookAt(cameraPosition, bounds.center);
          CanvasController.setCameraZoom(zoom);
          CanvasController.setCameraDistance(distance);
        } else {
          const step = StepController.getStep(selectedStep.id);
          if (step) {
            CanvasController.setCameraTransform(step.cameraSettings.transform);
            CanvasController.setCameraZoom(step.cameraSettings.zoom);
            CanvasController.setCameraDistance(step.cameraSettings.distance);
          }
        }

        UIController.setDefaultTransform({
          position: vector3ToData(cameraPosition),
          rotation: quaternionToData(cameraRotation),
        });
        UIController.setDefaultZoomAndDistance(zoom, distance);
        setInitialized(true);
      }
      ortho.near = bounds.radius / 1000.0;
      ortho.far = bounds.radius * 100;
      const horizontal = aspectRatio < 1 ? 1 * aspectRatio : 1;
      const vertical = aspectRatio < 1 ? 1 : 1 / aspectRatio;
      CanvasController.setCameraParameters(vertical / 2, vertical / -2, horizontal / -2, horizontal / 2);
      ortho.updateProjectionMatrix();
      cameraEvents.dispatchEvent('update');

      /**
       * Used by the media exporter to be informed about when resizing is complete.
       * We have to check for 0 on size.top and size.left, because for some reason
       * resizing is a two step process.
       * First:
       * size.top = size.height - targetHeight
       * Then:
       * size.top = 0, size.height = targetHeight
       * Same for width obviously.
       */
      if (size.top === 0 && size.left === 0) {
        window.dispatchEvent(new Event(BrowserEvent.RESIZE_COMPLETE));
      }
    }
  }, [model, camera, size, bounds, initialized, selectedStep]);
};
