import {
  downloadGLTFAPI,
  fetchAnnotationsAPI,
  fetchInstructionAPI,
  fetchInstructionPartsAPI,
  fetchStepGroupsAPI,
} from '@assemblio/frontend/data-access';
import { StepGroupDto } from '@assemblio/shared/next-types';
import { FetchInstructionResult, VideoContext, VideoEvent } from './types/video.machine.types';
import { GLTF } from 'three-stdlib';
import { assertEvent, assign } from 'xstate';
import { AnimationController } from '../controller';
import { createLineMesh } from '../helper';
import { useAnimationStore } from '../stores';
import { videoActor } from './video.machine';
import { ExportVideoJobData } from '@assemblio/type/job';

export const projectHasSteps = ({ context }: { context: VideoContext }): boolean => {
  // TODO: Fix Exporters with new requests
  // const sequence = useVideoRendererStore.getState().sequence;
  // return sequence !== undefined && sequence[0].steps.length > 0;

  return context.stepGroups !== undefined && context.stepGroups.length > 0;
};

export const applyStepGroupSelection = ({ context }: { context: VideoContext }) => {
  if (!context.exportMessage || !context.stepGroups) return;
  const clipIds = context.exportMessage.selection.reduce((clipIds, { sequenceId, stepGroupIds }) => {
    return clipIds.concat(
      stepGroupIds
        .flatMap((id) => {
          const group = context.stepGroups?.find((group) => {
            const stepGroup = group as StepGroupDto;
            return stepGroup.instructionSequenceId === sequenceId && stepGroup.id === id;
          }) as StepGroupDto | undefined;
          if (group) {
            return group.steps.flatMap((step) => {
              const clips = AnimationController.getClipIdForStepId(step.id);
              return step.playWithAbove ? [] : [clips?.main, clips?.prelude];
            });
          }
          return undefined;
        })
        .filter((value) => value !== undefined) as Array<string>
    );
  }, new Array<string>());
  AnimationController.applySequence(clipIds);
};

export const clearContext = assign(() => {
  return {
    exportMessage: undefined,
    instruction: undefined,
    instructionParts: undefined,
    stepGroups: undefined,
    annotations: undefined,
    gltf: undefined,
  };
});

export const fetchInstruction = async (context: VideoContext): Promise<FetchInstructionResult> => {
  if (!context.exportMessage) {
    videoActor.send({
      type: 'ERROR',
      message: `Error fetching instruction from server.`,
    });
    return Promise.reject();
  }

  const instructionId = context.exportMessage.instructionId;
  const instruction = await fetchInstructionAPI(instructionId)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      videoActor.send({
        type: 'ERROR',
        message: `Error fetching instruction ${instructionId} from server.`,
      });
      return undefined;
    });
  if (!instruction) return Promise.reject();
  const instructionParts = await fetchInstructionPartsAPI(instructionId)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      videoActor.send({
        type: 'ERROR',
        message: `Error fetching instruction ${instructionId} from server.`,
      });
      return undefined;
    });
  if (!instructionParts) return Promise.reject();
  const stepGroups = await Promise.all(
    context.exportMessage.selection.map((selection) => {
      return fetchStepGroupsAPI({
        sequenceId: selection.sequenceId,
        reverseOrder: false,
      });
    })
  )
    .then((result) => {
      return result.flat();
    })
    .catch((error) => {
      console.log(error);
      videoActor.send({
        type: 'ERROR',
        message: `Error fetching step groups from server.`,
      });
      return undefined;
    });
  if (!stepGroups) return Promise.reject();
  const gltf = await downloadGLTFAPI(instruction.fileId)
    .then((result) => result)
    .catch((error) => {
      console.log(error);
      videoActor.send({
        type: 'ERROR',
        message: `Error fetching GLTF from server.`,
      });
      return undefined;
    });
  if (!gltf) return Promise.reject();
  const annotations = await fetchAnnotationsAPI(instructionId)
    .then((result) => result)
    .catch((error) => {
      console.debug(error);
      videoActor.send({
        type: 'ERROR',
        message: `Error fetching Annotations from server.`,
      });
      return undefined;
    });
  if (annotations === undefined) return Promise.reject();
  return { gltf, stepGroups, instructionParts, instruction, annotations };
};

export const downloadGLTF = async (context: VideoContext) => {
  if (!context.instruction) {
    videoActor.send({
      type: 'ERROR',
      message: 'Instruction not initialized on Context.',
    });
    return Promise.reject();
  }

  const fileId = context.instruction.fileId;
  await downloadGLTFAPI(fileId)
    .then((gltf: GLTF) => {
      assign({ gltf });
      createLineMesh(gltf.scene);
    })
    .catch((error) => {
      console.log(error);
      videoActor.send({
        type: 'ERROR',
        message: `Failed loading GLTF file ${fileId} from server.`,
      });
    });
};

// Following console logs were useful for debugging with the Video Renderer and should therefore be untouched
export const notifyReadyToRender = () => {
  console.log('[video.actions] readyToRender');
  const progress = AnimationController.getAnimationProgress();
  window.readyToRender(progress);
};

export const notifyReadyToCapture = () => {
  console.log('[video.actions] notifyReadyToCapture');
  const progress = AnimationController.getAnimationProgress();

  window.readyToCapture(progress);
};

export const notifyComplete = () => {
  console.log('[video.actions] notifyComplete');
  window.finished();
};

export const notifyInvalid = () => {
  console.log('[video.actions] notifyInvalid');
  window.invalid();
};

export const notifyError = ({ event }: { event: VideoEvent }) => {
  console.log('[video.actions] notifyError');
  assertEvent(event, 'ERROR');
  window.error(event.message);
};

export const advance = ({ context }: { context: VideoContext }) => {
  context.exportMessage && AnimationController.advance(1000 / context.exportMessage.exportSettings.framerate);
};

export const registerEventHandlers = () => {
  document.body.addEventListener('loadProject', (event: Event) => {
    const exportEvent = event as CustomEvent<ExportVideoJobData>;
    videoActor.send({ type: 'PREPARE', message: exportEvent.detail });
  });
  document.body.addEventListener('readyToReceive', (event: Event) => {
    videoActor.send({ type: 'START' });
  });
  document.body.addEventListener('frameRecorded', (event: Event) => {
    videoActor.send({ type: 'CAPTURED' });
  });
};

export const createAnimations = () => {
  AnimationController.createAnimations({
    onStepComplete: () => {
      // onStepComplete is emitted for every skipped
      // step, so this command led to the video renderer
      // skipping several frames. It seems the onStepComplete
      // event is fired too often regardless. This needs
      // investigating.
      // See https://arcaide.atlassian.net/browse/AIO-1744
      //videoActor.send({ type: 'NEXT' });
    },
    onAnimationComplete: () => videoActor.send({ type: 'STOP' }),
  });
};

export const nextStep = () => {
  const { currentClipIndex, sequence: clipSequence } = useAnimationStore.getState();
  let nextClipIndex = -1;
  if (currentClipIndex < clipSequence.length - 1) {
    nextClipIndex = currentClipIndex + 1;
  } else {
    nextClipIndex = -1;
    videoActor.send({ type: 'STOP' });
  }
  if (!clipSequence.length) {
    nextClipIndex = -1;
  }
  useAnimationStore.setState({
    currentClipIndex: nextClipIndex,
  });
};
