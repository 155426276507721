import { useStepDelete } from '@assemblio/frontend/data-access';
import {
  MachineController,
  StepController,
  useUIStore,
} from '@assemblio/frontend/stores';
import { Step as StepDef } from '@assemblio/shared/next-types';
import {
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
} from '@hello-pangea/dnd';
import React from 'react';
import { useStepCamera } from './Hooks/useStepCamera';
import Step from './Step';
import CompactStep from './CompactStep';
import { notifications } from '@mantine/notifications';

interface InnerStepListProps {
  steps: StepDef[];
}

const InnerStepList = (props: InnerStepListProps) => {
  const {
    handleSetCamera,
    handleResetCamera,
    handleSetCameraPositionToStep,
    copyCameraSettings,
    pasteCameraSettings,
  } = useStepCamera();
  const stepDeleteMutation = useStepDelete();

  const handleRemoveStep = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    const selected = useUIStore.getState().selectedStep;
    if (selected && selected.id) {
      if (StepController.canStepBeDeleted(selected.id)) {
        stepDeleteMutation.mutate(selected.id, {
          onSuccess: () => {
            StepController.removeStep(selected.id);
          },
        });
      } else {
        notifications.show({
          id: 'step-deletion-forbidden',
          message:
            "Step can't be deleted. Parts it contains are used in subsequent steps. Please delete those steps first.",
          color: 'red',
        });
      }
    }
  };

  const draggable = useUIStore.getState().view === 'editor';

  return (
    <>
      {props.steps.map((step: StepDef, index: number) => {
        return draggable ? (
          <Draggable key={step.id} draggableId={step.id} index={index}>
            {(
              dragProvided: DraggableProvided,
              dragSnapshot: DraggableStateSnapshot
            ) => (
              <Step
                key={step.id}
                step={step}
                onClick={handleClick}
                onRemoveStep={(event) => handleRemoveStep(event)}
                onSetCamera={handleSetCamera}
                onResetCamera={handleResetCamera}
                onSetCameraPositionToStep={handleSetCameraPositionToStep}
                onCopyCameraSettings={copyCameraSettings}
                onPasteCameraSettings={pasteCameraSettings}
                isDragging={dragSnapshot.isDragging}
                provided={dragProvided}
              />
            )}
          </Draggable>
        ) : (
          <CompactStep step={step} onClick={handleClick} key={step.id} />
        );
      })}
    </>
  );
};

export const InnerStepListMemo = React.memo<InnerStepListProps>(InnerStepList);

const handleClick = (step: StepDef) => {
  if (step.id !== useUIStore.getState().selectedStep?.id) {
    MachineController.selectStep(step);
  }
};
