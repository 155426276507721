import { create } from 'zustand';
import { devtools, subscribeWithSelector } from 'zustand/middleware';
import {
  AnnotationDto,
  AnnotationDtoWithId,
} from '@assemblio/shared/next-types';

export type AnnotationMode = 'editing' | 'display';

export interface AnnotationState {
  visible: boolean;
  highlight: boolean;
  mode: AnnotationMode;
  data: AnnotationDtoWithId;
}

export type ClipboardData = {
  context: string;
  annotation: AnnotationDto;
  instructionId: string;
  copiedIds: Map<string, Array<string>>;
};

export interface AnnotationStore {
  annotationMap: Map<string, AnnotationState>;
  stepAnnotationMap: Map<string, Set<string>>;
  annotationStepMap: Map<string, Set<string>>;
  clipboard: ClipboardData | null;
  editedAnnotation?: string;
  hoveredAnnotation?: string;
}

const init = (): AnnotationStore => {
  return {
    annotationMap: new Map<string, AnnotationState>(),
    stepAnnotationMap: new Map<string, Set<string>>(),
    annotationStepMap: new Map<string, Set<string>>(),
    clipboard: null,
  };
};

export const useAnnotationStore = create<
  AnnotationStore & { reset: () => void }
>()(
  subscribeWithSelector(
    devtools(
      (set) => ({
        ...init(),
        reset: () => {
          set(init());
        },
      }),
      {
        name: 'Annotation Store',
        serialize: {
          options: {
            map: true,
          },
        } as any,
      }
    )
  )
);
