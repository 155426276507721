import { notifications } from '@mantine/notifications';
import { useMutation } from '@tanstack/react-query';
import {
  deleteStep,
  deleteStepText,
  moveStep,
  renameStep,
  setAnimationSpeed,
  setCamera,
  setMTM,
  setPlayWithAbove,
  setStepText,
  setType,
  updateStepData,
} from './step.requests';
import {
  MoveStep,
  StepRename,
  StepSetAnimationSpeed,
  StepSetCamera,
  StepSetData,
  StepSetMTM,
  StepSetPlayWithAbove,
  StepSetText,
  StepSetType,
} from './types/step.mutation.types';

export const useStepUpdate = () => {
  return useMutation((patch: StepSetData) => updateStepData(patch), {
    onError: (_error) => {
      notifications.show(getModifiedNotification('update-step'));
    },
  });
};

export const useStepRename = () => {
  return useMutation((patch: StepRename) => renameStep(patch), {
    onError: (_error) => {
      notifications.show(getModifiedNotification('rename-step'));
    },
  });
};

export const useStepSetPlayWithAbove = () => {
  return useMutation((patch: StepSetPlayWithAbove) => setPlayWithAbove(patch), {
    onError: (_error) => {
      notifications.show(getModifiedNotification('set-play-with-above-step'));
    },
  });
};

export const useStepDelete = () => {
  return useMutation((stepId: string) => deleteStep(stepId), {
    onError: (_error) => {
      notifications.show({
        id: 'delete-step',
        message: 'Step could not be deleted',
        color: 'red',
      });
    },
  });
};

export const useStepSetType = () => {
  return useMutation((patch: StepSetType) => setType(patch), {
    onError: (_error) => {
      notifications.show(getModifiedNotification('set-type-step'));
    },
  });
};

export const useStepSetCamera = () => {
  return useMutation((patch: StepSetCamera) => setCamera(patch), {
    onError: (_error) => {
      notifications.show(getModifiedNotification('set-camera-step'));
    },
  });
};

export const useStepSetAnimationSpeed = () => {
  return useMutation((patch: StepSetAnimationSpeed) => setAnimationSpeed(patch), {
    onError: (_error) => {
      notifications.show(getModifiedNotification('set-animation-speed-step'));
    },
  });
};

export const useStepSetMTM = () => {
  return useMutation((patch: StepSetMTM) => setMTM(patch), {
    onError: (_error) => {
      notifications.show(getModifiedNotification('set-mtm-step'));
    },
  });
};

export const useStepMove = () => {
  return useMutation((patch: MoveStep) => moveStep(patch), {
    onError: (_error) => {
      notifications.show({
        id: 'move-step',
        message: 'Step could not be moved',
        color: 'red',
      });
    },
  });
};

export const useStepSetText = () => {
  return useMutation((patch: StepSetText) => setStepText(patch), {
    onError: (_error) => {
      notifications.show({
        id: 'set-step-text',
        message: 'Step text could not be set',
        color: 'red',
      });
    },
  });
};

export const useStepDeleteText = () => {
  return useMutation((stepId: string) => deleteStepText(stepId), {
    onError: (_error) => {
      notifications.show({
        id: 'set-step-text',
        message: 'Step text could not be deleted',
        color: 'red',
      });
    },
  });
};

const getModifiedNotification = (id: string) => {
  return {
    id,
    message: 'Step could not be modified',
    color: 'red',
  };
};
