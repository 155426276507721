import { stepEvents, StepEventDetail } from '@assemblio/frontend/events';
import { useEffect, useState } from 'react';

export function useForceHierarchyFilterUpdate() {
  const [stepTypeEventTrigger, setStepTypeEventTrigger] = useState<boolean>(false);

  useEffect(() => {
    const onUpdateStepType = (_event: CustomEvent<StepEventDetail>) => {
      setStepTypeEventTrigger((prev) => !prev);
    };

    stepEvents.addEventListener('updateStepType', onUpdateStepType);
    return () => {
      stepEvents.removeEventListener('updateStepType', onUpdateStepType);
    };
  }, []);

  return stepTypeEventTrigger;
}
