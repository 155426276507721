import { CameraControlsAPI } from '@assemblio/frontend/types';
import { Transform, Vector, Quaternion } from '@assemblio/type/3d';
import { Camera } from '@react-three/fiber';
import { Matrix4, OrthographicCamera, Scene, Vector3 } from 'three';
import { useCanvasStore } from '../stores/CanvasStore';
import { cameraEvents } from '@assemblio/frontend/events';

export namespace CanvasController {
  export const setCamera = (camera: Camera) => {
    useCanvasStore.setState({ camera });
  };

  export const setCameraControls = (cameraControls: CameraControlsAPI) => {
    useCanvasStore.setState({ cameraControls });
  };

  export const setScene = (scene: Scene) => {
    useCanvasStore.setState({ scene });
  };

  export const setClippingPlanes = (near: number, far: number) => {
    const camera = useCanvasStore.getState().camera;
    if (camera) {
      camera.near = near;
      camera.far = far;
      camera.updateProjectionMatrix();
    }
  };

  export const setCameraTransform = (transform: Transform) => {
    setCameraPosition(transform.position);
    setCameraRotation(transform.rotation);
  };

  export const setCameraPosition = ({ x, y, z }: Vector) => {
    const camera = useCanvasStore.getState().camera;
    if (camera) {
      camera.position.set(x, y, z);
      cameraEvents.dispatchEvent('update');
    }
  };

  export const setCameraRotation = ({ x, y, z, w }: Quaternion) => {
    const camera = useCanvasStore.getState().camera;
    if (camera) {
      camera.quaternion.set(x, y, z, w);
      cameraEvents.dispatchEvent('update');
    }
  };

  export const setCameraLookAt = ({ x, y, z }: Vector, { x: tx, y: ty, z: tz }: Vector) => {
    const camera = useCanvasStore.getState().camera;
    if (camera) {
      const position = new Vector3(x, y, z);
      const target = new Vector3(tx, ty, tz);
      camera.position.set(x, y, z);
      camera.quaternion.setFromRotationMatrix(new Matrix4().lookAt(position, target, new Vector3(0, 1, 0)));
      camera.distance = position.distanceTo(target);
      cameraEvents.dispatchEvent('update');
    }
  };

  export const setCameraZoom = (zoom: number) => {
    const camera = useCanvasStore.getState().camera;
    if (camera) {
      camera.zoom = zoom;
      camera.updateProjectionMatrix();
      cameraEvents.dispatchEvent('update');
    }
  };

  export const setCameraDistance = (distance: number) => {
    const camera = useCanvasStore.getState().camera;
    if (camera) {
      camera.distance = distance;
      cameraEvents.dispatchEvent('update');
    }
  };

  export const setCameraParameters = (top: number, bottom: number, left: number, right: number) => {
    const ortho = useCanvasStore.getState().camera as OrthographicCamera;
    ortho.top = top;
    ortho.bottom = bottom;
    ortho.left = left;
    ortho.right = right;
    ortho.updateProjectionMatrix();
    cameraEvents.dispatchEvent('update');
  };
}
