import { Button, Dialog, Group, Text } from '@mantine/core';
import React from 'react';

interface ConfirmationDialogProps {
  opened: boolean;
  setOpened: React.Dispatch<React.SetStateAction<boolean>>;
  handleConfirm: () => void;
}

export const ConfirmationDialog = ({
  opened,
  setOpened,
  handleConfirm,
}: ConfirmationDialogProps) => {
  return (
    <Dialog
      position={{ top: 20, left: 350 }}
      opened={opened}
      withCloseButton
      onClose={() => setOpened(false)}
      size="lg"
      radius={0}
    >
      <Text size="xs" style={{ marginBottom: 10 }} fw={500}>
        Are you sure you want to reset all part and assembly names?
      </Text>

      <Group justify="right">
        <Button
          size="xs"
          onClick={() => handleConfirm()}
          data-cy="confirm-reset-button"
        >
          Confirm
        </Button>
        <Button
          size="xs"
          onClick={() => setOpened(false)}
          data-cy="cancel-reset-button"
        >
          Cancel
        </Button>
      </Group>
    </Dialog>
  );
};
