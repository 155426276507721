import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  changeOwnerOfInstruction,
  changeProductOwner,
  changeStateOfInstruction,
  createProductRevision,
  deleteInstruction,
  renameInstruction,
  setInstructionEditorSettings,
  setInstructionFavorite,
} from './instruction.requests';
import {
  ChangeInstructionOwner,
  ChangeInstructionState,
  ChangeProductOwner,
  DeleteInstruction,
  ProductSetFavorite,
  RenameInstruction,
  SetInstructionEditorSettings,
} from './types/instruction.request.types';
import { notifications } from '@mantine/notifications';
import axios from 'axios';
import { CreateRevisionDto } from '@assemblio/shared/dtos';

export const useSetInstructionEditorSettings = () => {
  return useMutation((patch: SetInstructionEditorSettings) => setInstructionEditorSettings(patch), {
    onError: (_error) => {
      notifications.show({
        id: 'set-instruction-editor-settings',
        message: 'Editor Settings could not be modified',
        color: 'red',
      });
    },
  });
};

export const useInstructionRename = () => {
  const queryClient = useQueryClient();

  return useMutation((patch: RenameInstruction) => renameInstruction(patch), {
    onSuccess: (_, values) => {
      if (typeof values.projectId !== 'undefined') {
        queryClient.invalidateQueries(['project', values.projectId]);
      } else {
        queryClient.invalidateQueries(['product', values.id]);
      }
    },
    onError: (_error) => {
      notifications.show({
        id: 'rename-instruction',
        message: 'Instruction could not be renamed',
        color: 'red',
      });
    },
  });
};

export const useInstructionOwnerChange = () => {
  const queryClient = useQueryClient();
  return useMutation((patch: ChangeInstructionOwner) => changeOwnerOfInstruction(patch), {
    onSuccess: (_, values) => {
      queryClient.invalidateQueries(['project', values.projectId]);
    },
    onError: (_error) => {
      notifications.show({
        id: 'owner-instruction',
        message: 'Instruction owner could not be changed',
        color: 'red',
      });
    },
  });
};

export const useProductOwnerChange = () => {
  const queryClient = useQueryClient();
  return useMutation((patch: ChangeProductOwner) => changeProductOwner(patch), {
    onSuccess: (_, values) => {
      queryClient.invalidateQueries(['product', values.productId]);
    },
    onError: (_error) => {
      notifications.show({
        id: 'owner-product',
        message: 'Product owner could not be changed',
        color: 'red',
      });
    },
  });
};

export const useInstructionStateChange = () => {
  return useMutation((patch: ChangeInstructionState) => changeStateOfInstruction(patch), {
    onError: (_error) => {
      notifications.show({
        id: 'state-instruction',
        message: 'Instruction state could not be set',
        color: 'red',
      });
    },
  });
};

export const useInstructionDelete = () => {
  const queryClient = useQueryClient();
  return useMutation((toDelete: DeleteInstruction) => deleteInstruction(toDelete.instructionId), {
    onSuccess: (_, values) => {
      queryClient.invalidateQueries(['project', values.projectId]);
    },
    onError: (error) => {
      if (axios.isAxiosError(error)) {
        console.error(error?.response?.data.message);
      }
      notifications.show({
        id: 'delete-instruction-error',
        message: 'Product could not be deleted.',
        color: 'red',
      });
    },
  });
};

export const useInstructionSetFavorite = () => {
  const queryClient = useQueryClient();
  return useMutation((patch: ProductSetFavorite) => setInstructionFavorite(patch), {
    onSuccess: (_, values) => {
      switch (values.usedBy) {
        case 'project':
          queryClient.invalidateQueries(['project', values.projectId]);
          break;
        case 'details':
          queryClient.invalidateQueries(['product', values.productId]);
          break;
        case 'favorites':
          queryClient.invalidateQueries(['favorite-products']);
          break;
      }
    },
    onError: (_error, values) => {
      notifications.show({
        id: 'set-instruction-favorite',
        message: `${values.action === 'add' ? 'Adding' : 'Removing'} favorite failed.`,
        color: 'red',
      });
    },
  });
};

export const useCreateProductRevision = () => {
  return useMutation((data: CreateRevisionDto) => createProductRevision(data), {
    onError: (_error) => {
      notifications.show({
        id: 'create-product-revision',
        message: 'Could not request review',
        color: 'red',
      });
    },
  });
};
