import {
  axiosBaseRequest,
  getKIMInfo,
  useAuthStore,
} from '@assemblio/frontend/data-access';
import { ActiveUserData, AppRole } from '@assemblio/type/user';
import { Alert, ComboboxItem, Container } from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconAlertCircle } from '@tabler/icons-react';
import axios, { AxiosError } from 'axios';
import jwtDecode from 'jwt-decode';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Logo } from '../ImageMark/Logo';
import classes from './Login.module.scss';
import { LoginForm } from './LoginForm';
import { TenantSelection } from './TenantSelection';
type Tenant = { id: string; name: string };

export const Login = () => {
  const setSession = useAuthStore((state) => state.setSession);
  const location = useLocation();
  const navigate = useNavigate();

  const [errMsg, setErrMsg] = useState<string>('');
  const [showTenantSelection, setShowTenantSelection] =
    useState<boolean>(false);
  const [tenants, setTenants] = useState<ComboboxItem[]>([]);
  const [selectedTenant, setSelectedTenant] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const form = useForm({
    initialValues: {
      email: '',
      password: '',
    },
    validate: {
      email: (value) =>
        /^\S+@\S+$/.test(value) ? null : 'Please enter a valid email address.',
      password: (value) =>
        value.trim().length > 0 ? null : 'Please enter a password.',
    },
  });

  useEffect(() => {
    setErrMsg('');
  }, [form.isTouched]);

  const setSessionStore = (accessToken: string, user: ActiveUserData) => {
    setSession({
      userId: user.sub,
      email: user.email,
      accessToken,
      appRole: user.tenant.role as AppRole,
      organizationId: user.tenant.id,
      tenant: user.tenant,
    });
    form.reset();

    if (user.tenant.role === 'viewer') {
      getKIMInfo()
        .then((data) => {
          window.location.href = `${data.baseURL}/explorer`;
        })
        .catch((e) => {
          navigate('/unauthorized');
        });
      return;
    }

    if (location.state?.from) {
      navigate(location.state?.from, { replace: true });
    } else {
      navigate('/explorer', { replace: true });
    }
  };

  const handleSubmit = async (
    values: {
      email: string;
      password: string;
    },
    event: React.FormEvent<HTMLFormElement> | undefined
  ) => {
    if (event) event.preventDefault();
    setLoading(true);
    const { email, password } = values;
    try {
      const response = await axiosBaseRequest({
        url: '/auth/sign-in',
        method: 'post',
        data: {
          email,
          password,
        },
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          withCredentials: true,
        },
      });

      const accessToken = response?.data?.accessToken;

      if (accessToken) {
        const user = jwtDecode<ActiveUserData>(accessToken);
        setSessionStore(accessToken, user);
      } else {
        const parsedTenants = (response?.data?.tenants as Tenant[]).map(
          (tenant) => {
            return { value: tenant.id, label: tenant.name };
          }
        );
        setTenants(parsedTenants);
        setSelectedTenant(parsedTenants[0].value);
        setShowTenantSelection(true);
      }
    } catch (error: unknown | AxiosError) {
      if (!axios.isAxiosError(error)) {
        setErrMsg('No Response');
      } else {
        if (!error.response) setErrMsg('No Response');
        else if (error.response.status === 400 || error.response.status === 404)
          setErrMsg('Incorrect Credentials');
        else setErrMsg('Login Failed');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleTenantSelection = async () => {
    const { email, password } = form.values;
    try {
      const response = await axiosBaseRequest({
        url: '/auth/sign-in',
        method: 'post',
        data: {
          email,
          password,
          tenantId: selectedTenant,
        },
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          withCredentials: true,
        },
      });
      const accessToken = response?.data?.accessToken;
      if (accessToken) {
        const user = jwtDecode<ActiveUserData>(accessToken);
        setSessionStore(accessToken, user);
      } else {
        throw Error();
      }
    } catch (error: unknown | AxiosError) {
      if (!axios.isAxiosError(error)) {
        setErrMsg('No Response');
      } else {
        if (!error.response) setErrMsg('No Response');
        else if (error.response.status === 400 || error.response.status === 404)
          setErrMsg('Incorrect Credentials');
        else setErrMsg('Login Failed');
      }
    }
  };

  return (
    <div className={classes['wrapper']}>
      <Container size={420} my={120} data-cy="login-form">
        <Logo width={388} />
        {showTenantSelection ? (
          <TenantSelection
            tenants={tenants}
            selectedTenant={selectedTenant}
            onTenantChange={setSelectedTenant}
            onTenantSelection={handleTenantSelection}
          />
        ) : (
          <LoginForm loading={loading} form={form} onSubmit={handleSubmit} />
        )}
        {errMsg.length > 0 && <LoginAlert message={errMsg} />}
      </Container>
    </div>
  );
};

interface LoginAlertProps {
  message: string;
}

const LoginAlert = ({ message }: LoginAlertProps) => (
  <Alert
    style={{ marginTop: '20px' }}
    icon={<IconAlertCircle size={16} />}
    title="Attention!"
    color="orange"
  >
    It seems like there was an error while logging you in. {message}
  </Alert>
);
