import { assertEvent, assign, createActor, setup } from 'xstate';
import {
  activateIsAnimating,
  cacheCameraTransform,
  createAnimations,
  deactivateIsAnimating,
  nextClip,
  pauseAnimation,
  playAnimation,
  previousClip,
  restoreCameraTransform,
  resumeAnimation,
  skipBackward,
  skipForward,
  stopAnimation,
  unhighlightAllSteps,
} from './animation.actions';
import {
  hideAllAnnotations,
  hideAnnotationsInPlayingSequence,
  hideAnnotationsInSelectedStep,
  showAnnotationsInPlayingSequence,
  showAnnotationsInSelectedStep,
  unhighlightAllAnnotations,
} from './annotation.actions';
import {
  allowCameraInteraction,
  deselectParts,
  disableSurfaceCollision,
  enableSurfaceCollision,
  hideCameraGizmo,
  hideLineRenderer,
  hideStepActions,
  hideTransformGizmo,
  hideViewportControls,
  moveCameraGizmo,
  moveCameraToSelectedStep,
  preventCameraInteraction,
  selectPartsInSelectedStep,
  showCameraGizmo,
  showLineRenderer,
  showStepActions,
} from './editor.actions';
import {
  hidePartsInSelectedStepAndFollowing,
  hidePartsInSteps,
  movePartsInPreviousStepsToAssembledPosition,
  movePartsInSelectedStepToDisassembledPosition,
  movePartsToDisassembledPosition,
  movePartsToStepStartPosition,
  restoreTransparency,
  setPartsToStartPosition,
  setTransparencyOff,
  showPartsInSelectedAndPreviousSteps,
  updateSelectionBounds,
} from './model.actions';
import { deselectStep, selectStep } from './step.actions';
import { isNotFirstStep, isStepListNotEmpty } from './transition.guards';
import { EditorEvent } from './types/machine.types';

export const viewerMachine = setup({
  types: {
    context: {},
    events: {} as EditorEvent,
  },
  actions: {
    activateIsAnimating,
    allowCameraInteraction,
    cacheCameraTransform,
    createAnimations,
    deactivateIsAnimating,
    deselectParts,
    deselectSteps: deselectStep,
    disableSurfaceCollision,
    enableSurfaceCollision,
    hideAllAnnotations,
    hideAnnotationsInPlayingSequence,
    hideAnnotationsInSelectedStep,
    hideCameraGizmo,
    hideLineRenderer,
    hidePartsInSelectedStepAndFollowing,
    hidePartsInSteps,
    hideStepActions,
    hideTransformGizmo,
    hideViewportControls,
    moveCameraGizmo,
    moveCameraToSelectedStep,
    movePartsInPreviousStepsToAssembledPosition,
    movePartsInSelectedStepToDisassembledPosition,
    movePartsToDisassembledPosition,
    movePartsToStepStartPosition,
    nextClip,
    pauseAnimation,
    playAnimation,
    preventCameraInteraction,
    previousClip,
    restoreCameraTransform,
    restoreTransparency,
    resumeAnimation,
    selectPartsInSelectedStep,
    selectStep,
    setPartsToStartPosition,
    setTransparencyOff,
    showAnnotationsInPlayingSequence,
    showAnnotationsInSelectedStep,
    showCameraGizmo,
    showLineRenderer,
    showPartsInSelectedAndPreviousSteps,
    showStepActions,
    skipBackward,
    skipForward,
    stopAnimation,
    unhighlightAllAnnotations,
    unhighlightAllSteps,
    updateSelectionBounds,
  },
  guards: {
    isStepListNotEmpty,
    isNotFirstStep,
  },
}).createMachine({
  id: 'viewer-machine',
  initial: 'ready',
  states: {
    ready: {
      entry: [
        assign({
          selectedParts: undefined,
          selectedStep: undefined,
        }),
        'hideTransformGizmo',
        'hideCameraGizmo',
        'hideLineRenderer',
        'hidePartsInSteps',
        'hideStepActions',
        'deselectParts',
        'deselectSteps',
        'allowCameraInteraction',
        'hideViewportControls',
        'updateSelectionBounds',
        'hideAllAnnotations',
        'movePartsToDisassembledPosition',
      ],
      on: {
        PLAY: { target: 'animating', guard: 'isStepListNotEmpty' },
        SELECT_STEP: {
          target: 'stepSelected',
          actions: ['selectStep'],
        },
      },
    },
    animating: {
      entry: [
        'cacheCameraTransform',
        'disableSurfaceCollision',
        'createAnimations',
        'deselectParts',
        'unhighlightAllAnnotations',
        'hideLineRenderer',
        'hideTransformGizmo',
        'hideCameraGizmo',
        //'hidePartsInSelectedStepAndFollowing',
        //'movePartsInPreviousStepsToAssembledPosition',
        'hideStepActions',
        'preventCameraInteraction',
        'setTransparencyOff',
        //'movePartsToDisassembledPosition',
        'activateIsAnimating',
        'playAnimation',
      ],
      exit: [
        'stopAnimation',
        'restoreTransparency',
        //'setPartsToStartPosition',
        'unhighlightAllSteps',
        'enableSurfaceCollision',
        'restoreCameraTransform',
        'deactivateIsAnimating',
      ],
      on: {
        STOP: { target: 'ready' },
        RESET: { target: 'ready' },
      },
      /* Playback substate */
      initial: 'playing',
      states: {
        playing: {
          entry: ['hideAllAnnotations', 'showAnnotationsInPlayingSequence'],
          on: {
            PAUSE: { target: 'paused' },
            NEXT: {
              target: 'playing',
              reenter: true,
            },
            PREVIOUS: {
              target: 'playing',
              reenter: true,
              guard: 'isNotFirstStep',
              actions: ['previousClip'],
            },
            SKIP_FORWARD: {
              target: 'playing',
              reenter: true,
              actions: ['nextClip'],
            },
            SKIP_BACKWARD: {
              target: 'playing',
              reenter: true,
              actions: ['previousClip'],
            },
          },
        },
        paused: {
          entry: ['pauseAnimation', 'allowCameraInteraction'],
          exit: ['preventCameraInteraction'],
          on: {
            PLAY: { target: 'playing', guard: 'isStepListNotEmpty', actions: ['resumeAnimation'] },
            SKIP_FORWARD: {
              target: 'paused',
              reenter: true,
              actions: ['nextClip'],
            },
            SKIP_BACKWARD: {
              target: 'paused',
              reenter: true,
              actions: ['previousClip'],
            },
          },
        },
      },
      /* End Playback substate */
    },
    stepSelected: {
      entry: [
        'hideAllAnnotations',
        'deselectParts',
        'moveCameraGizmo',
        'showCameraGizmo',
        'showStepActions',
        'hidePartsInSteps',
        'movePartsInPreviousStepsToAssembledPosition',
        'movePartsInSelectedStepToDisassembledPosition',
        'showPartsInSelectedAndPreviousSteps',
        'selectPartsInSelectedStep',
        'updateSelectionBounds',
        'showLineRenderer',
        'showAnnotationsInSelectedStep',
        'moveCameraToSelectedStep',
      ],
      exit: ['hideAnnotationsInSelectedStep'],
      //exit: ['movePartsToDisassembledPosition'], // At this position it collides with part movement / not sure if needed somewhere else
      on: {
        DESELECT: {
          target: 'ready',
        },
        SELECT_STEP: {
          target: 'stepSelected',
          reenter: true,
          actions: ['selectStep'],
        },
        PLAY: { target: 'animating', guard: 'isStepListNotEmpty' },
        RESET: { target: 'ready' },
      },
    },
  },
});
