import {
  NextProjectCreate,
  useNextProjectCreate,
} from '@assemblio/frontend/data-access';
import {
  Button,
  Center,
  Group,
  Loader,
  Modal,
  Progress,
  Stack,
  Text,
  TextInput,
} from '@mantine/core';
import { joiResolver, useForm } from '@mantine/form';
import { notifications } from '@mantine/notifications';
import axios from 'axios';
import Joi from 'joi';
import _ from 'lodash';
import { useState } from 'react';
import { FileInputButton } from './FileInputButton';
import { ConfirmationButton } from '../../Utilities';

const schema = Joi.object({
  name: Joi.string().trim().min(3).max(55).required().messages({
    'string.empty': `Project title must not be empty`,
    'string.min': `Project title should have at least {#limit} characters`,
    'string.max': `Project title should not have more than {#limit} characters`,
    'any.required': `Project title is a required field`,
  }),
});

interface ProjectCreationModalProps {
  opened: boolean;
  onClose: () => void;
  folderId?: string;
  projectId?: string | null;
}

export const ProjectCreationModal = ({
  opened,
  onClose,
  folderId,
  projectId,
}: ProjectCreationModalProps) => {
  const [state, setState] = useState<'input' | 'upload' | 'create'>('input');
  const [progress, setProgress] = useState(0);
  const onProgress = (fraction: number) => {
    setProgress(fraction * 100);
  };
  const onComplete = () => {
    setState('create');
  };
  const [selectedFile, setSelectedFile] = useState<File>();
  const [isFileSelected, setIsFileSelected] = useState(false);
  const [createProjectMutation, abortRef] = useNextProjectCreate({
    onProgress,
    onComplete,
  });

  const form = useForm({
    validate: joiResolver(schema),
    initialValues: {
      name: '',
    },
    validateInputOnChange: true,
  });

  const changeFileHandler = (files: FileList | undefined) => {
    const currentInput: string = form.getInputProps('name').value;
    const fileNameUsed = selectedFile?.name.includes(currentInput);
    if (files) {
      /** Replace Input for name when none is set or current name is equal to previuos fileName */
      if (!currentInput || fileNameUsed) {
        /** Remove extension before setting name */
        form.setFieldValue('name', files[0].name.replace(/\.[^/.]+$/, ''));
      }
      setSelectedFile(files[0]);
      setIsFileSelected(true);
    } else {
      /** Only clear Input when current name equals fileName */
      if (fileNameUsed) {
        form.setFieldValue('name', '');
      }
      setSelectedFile(undefined);
      setIsFileSelected(false);
    }
  };

  const reset = () => {
    form.reset();
    setSelectedFile(undefined);
    setIsFileSelected(false);
    setProgress(0);
    setState('input');
  };

  const handleSubmission = async (formValues: any) => {
    if (selectedFile && (folderId || projectId)) {
      const project: NextProjectCreate = {
        file: selectedFile,
        projectData: {
          name: formValues.name,
          folderId,
          projectId: projectId ? projectId : undefined,
        },
      };

      try {
        setState('upload');
        await createProjectMutation.mutateAsync(project);
        onClose();
        reset();
      } catch (e) {
        if (_.get(e, 'message') === 'canceled') {
          notifications.show({
            id: 'create-project-error',
            message: 'Upload canceled. Project could not be created.',
            color: 'orange',
          });
        } else {
          let errorMessage = '';

          if (axios.isAxiosError(createProjectMutation.error)) {
            errorMessage = errorMessage.concat(
              createProjectMutation.error?.response?.data.message
            );
          } else if (e instanceof Error) {
            errorMessage = errorMessage.concat(e.message);
          }

          notifications.show({
            id: 'create-project-error',
            title: 'Project could not be created.',
            message: errorMessage,
            color: 'red',
          });
        }
        reset();
      }
    }
  };

  const handleClose = () => {
    setSelectedFile(undefined);
    setIsFileSelected(false);
    form.reset();
    onClose();
  };

  return (
    <Modal
      title={projectId ? 'Create New Instruction' : 'Create New Project'}
      centered
      opened={opened}
      onClose={handleClose}
      withCloseButton={state !== 'upload'}
      closeOnClickOutside={state !== 'upload'}
      closeOnEscape={state !== 'upload'}
      withinPortal
    >
      {state === 'input' && (
        <form onSubmit={form.onSubmit((values) => handleSubmission(values))}>
          <TextInput
            required
            label={projectId ? 'Instruction Title' : 'Project Title'}
            placeholder={projectId ? 'Instruction Title' : 'Project Title'}
            {...form.getInputProps('name')}
            data-cy="create-new-project-name"
          />
          <FileInputButton fileHandler={changeFileHandler} />
          <Group justify="right" mt="md">
            <Button
              disabled={!isFileSelected || !form.isValid('name')}
              type="submit"
            >
              Submit
            </Button>
          </Group>
        </form>
      )}

      {state === 'upload' && (
        <Stack>
          <Text>Uploading your file, please wait.</Text>
          <Progress value={progress} size="xl" />
          <ConfirmationButton
            onClose={(ok) => {
              if (ok && abortRef.current) {
                abortRef.current.abort();
              }
            }}
            prompt={'Are you sure you want to cancel the upload?'}
          >
            Cancel
          </ConfirmationButton>
        </Stack>
      )}

      {state === 'create' && (
        <Stack>
          <Text>Your project is being created, please wait.</Text>
          <Center>
            <Loader />
          </Center>
        </Stack>
      )}
    </Modal>
  );
};
