import {
  Button,
  Fieldset,
  Grid,
  Stack,
  Text,
  TextInput,
  Radio,
  useComputedColorScheme,
  useMantineColorScheme,
  Group,
  Badge,
  MantineSize,
  MantineStyleProp,
} from '@mantine/core';
import classes from '../UserProfile.module.scss';
import { useCurrentUserQuery } from '@assemblio/frontend/data-access';
import { joiResolver, useForm } from '@mantine/form';
import Joi from 'joi';
import { useEffect } from 'react';
import { AppRole } from '@assemblio/type/user';
import { ProfileCard } from './ProfileCard';

type UserSettingsFormValues = {
  firstName: string;
  lastName: string;
  email: string;
  role: string;
};

const userSettingsSchema = Joi.object({
  firstName: Joi.string().trim().strict().min(3).required(),
  lastName: Joi.string().trim().strict().min(3).required(),
  email: Joi.string().email({ minDomainSegments: 2, tlds: false }).required(),
  role: Joi.string().trim().strict().min(3).required(),
});

export const UserSettings = () => {
  const { setColorScheme } = useMantineColorScheme();
  const computedColorScheme = useComputedColorScheme('dark');
  const { data: userData } = useCurrentUserQuery();
  const form = useForm<UserSettingsFormValues>({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      role: '',
    },
    validate: joiResolver(userSettingsSchema),
  });

  useEffect(() => {
    if (userData) {
      form.setFieldValue('firstName', userData.firstName);
      form.setFieldValue('lastName', userData.lastName);
      form.setFieldValue('email', userData.email);
      form.setFieldValue('role', (userData.role as AppRole) ?? ('viewer' as AppRole));
    }
  }, [userData]);

  return (
    <ProfileCard>
      <Grid gutter={'xl'} overflow={'hidden'}>
        <Grid.Col span={12}>
          <Text mb={'xs'} size={'xl'} fw={700}>
            Your Profile
          </Text>
        </Grid.Col>

        <Grid.Col
          span={{
            lg: 12,
            sm: 6,
          }}
        >
          <Fieldset className={classes.profileCard__section} legend="Personal Information">
            <form
              onSubmit={form.onSubmit((values, event) => {
                //SUBMIT
              })}
            >
              <Stack w={'100%'}>
                <TextInput
                  label="First Name"
                  placeholder="Name"
                  {...form.getInputProps('firstName')}
                  readOnly
                  disabled
                />
                <TextInput label="Last Name" placeholder="Name" {...form.getInputProps('lastName')} readOnly disabled />
                <TextInput label="E-Mail" placeholder="E-Mail" {...form.getInputProps('email')} readOnly disabled />
                <TextInput label="Role" placeholder="Role" {...form.getInputProps('role')} readOnly disabled />
                <Button type="submit" disabled={form.isTouched()}>
                  Save
                </Button>
              </Stack>
            </form>
          </Fieldset>
        </Grid.Col>

        <Grid.Col
          span={{
            lg: 12,
            sm: 6,
          }}
        >
          <Fieldset className={classes.profileCard__section} legend={'Appearance'}>
            <Radio.Group
              value={computedColorScheme}
              onChange={(value) => {
                if (value === 'dark' || value === 'light') {
                  setColorScheme(value);
                }
              }}
              name="colorScheme"
              label="Theme"
            >
              <BetaBadge
                style={{
                  position: 'absolute',
                  left: '20%',
                  top: '20%',
                }}
              />
              <Group mt="xs">
                <Radio value="dark" label="Dark" />
                <Radio value="light" label="Light" />
              </Group>
            </Radio.Group>
          </Fieldset>
        </Grid.Col>

        {/* <Grid.Col span={12}>
            <Fieldset
              className={classes.profileCard__section}
              legend="Security"
            >
              <ChangeUserPassword width={'50%'} label="Change Password" />
            </Fieldset>
          </Grid.Col> */}
      </Grid>
    </ProfileCard>
  );
};

interface BetaBadgeProps {
  size?: MantineSize;
  style?: MantineStyleProp;
}

const BetaBadge = ({ size = 'xs', style = {} }: BetaBadgeProps) => {
  const { colorScheme } = useMantineColorScheme();

  return (
    <Badge
      style={style}
      variant={colorScheme === 'dark' ? 'outline' : 'light'}
      color={'brandPink'}
      styles={{
        label: {
          paddingTop: '2px',
        },
      }}
      size={size}
    >
      Beta
    </Badge>
  );
};
