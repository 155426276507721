import { SyncProfileDto } from './instruction.dto';

export interface SyncProfileConfig {
  triggerRules?: string[];
  removeEmptySteps: boolean;
  removeEmptyGroups: boolean;
  duplicatePartColors: boolean;
  colorizeNewParts: boolean;
}

export enum SyncProfileState {
  INITIALISING = 'initialising',
  PROCESSING = 'processing',
  UNRESOLVED = 'unresolved',
  INVALID = 'invalid',
  DONE = 'done',
}

export class AvailableSyncProfileDto {
  id!: string;
  name!: string;
  isAvailable!: boolean;
  reason?: string;
}

export class SyncProfileRelationDto {
  to!: SyncProfileDto;
  from!: SyncProfileDto;
  lastSyncedAt?: Date;
  state!: SyncProfileState;
}

export interface SyncProfileData {
  meta: {
    sourceId: string;
    targetId: string;
  };
  pairs: SyncProfilePairs;
  parts: SyncProfileParts;
}

export interface SyncProfilePairs {
  replace: SyncProfilePair[];
  unresolved: SyncProfilePair[];
  resolved: SyncProfileResolvedPair;
}

export interface SyncProfileResolvedPair {
  replace: SyncProfilePair[];
  keep: SyncProfilePair[];
}

export interface SyncProfilePair {
  source: {
    id: number;
    gltfIndex?: number;
    name: string;
  };
  target: {
    id: number;
    gltfIndex?: number;
    name: string;
  };
}

export interface RemappedSyncProfilePair {
  source: {
    id: number;
    gltfIndex: number;
    name: string;
  };
  target: {
    id: number;
    gltfIndex: number;
    name: string;
  };
}

export interface SyncProfileParts {
  deprecated: number[];
  new: number[];
}
