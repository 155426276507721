import React from 'react';
import classes from './ExplorerCard.module.scss';
import { ElementProps } from '@mantine/core';

interface FooterProps extends ElementProps<'div'> {
  children: React.ReactNode;
}

export const Footer = ({ children, ...props }: FooterProps) => (
  <div className={classes.explorerCard__footer} {...props}>
    {children}
  </div>
);
Footer.displayName = 'Footer';
