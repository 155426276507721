import { Button, CloseButton, Group, Stack, Text, Tooltip } from '@mantine/core';
import { useRef, useState } from 'react';
import { useHasFeatureFlag } from '../../Utilities/useHasFeatureFlag.hook';
import { FeatureFlag } from '@assemblio/type/feature-flag';

interface FileInputButtonProps {
  fileHandler: (files: FileList | undefined) => void;
}

export const FileInputButton = ({ fileHandler }: FileInputButtonProps) => {
  const fileInput = useRef<HTMLInputElement>(null);
  const [fileLabel, setFileLabel] = useState('');

  const isJTFeatureEnabled = useHasFeatureFlag(FeatureFlag.JTFileType);

  const { acceptedFileTypes, toolTip, buttonText } = getFileInputProperties(isJTFeatureEnabled);

  return (
    <>
      <input
        data-cy="upload-input"
        type="file"
        name="File"
        accept={acceptedFileTypes}
        ref={fileInput}
        onChange={(e) => {
          if (e.target.files && e.target.files.length > 0) {
            setFileLabel(e.target.files[0].name);
            fileHandler(e.target.files);
          } else {
            setFileLabel('');
            fileHandler(undefined);
          }
        }}
        style={{ display: 'none' }}
      />
      <Stack style={{ marginTop: '20px' }}>
        <Group justify="left">
          <Tooltip label={toolTip} openDelay={500}>
            <Button
              style={{ width: '220px', m: 1 }}
              variant={'outline'}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                fileInput?.current?.click();
              }}
            >
              {buttonText}
            </Button>
          </Tooltip>
          {fileLabel !== '' && (
            <CloseButton
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setFileLabel('');
                fileHandler(undefined);
              }}
              aria-label="Remove file"
            />
          )}
        </Group>
        <Text style={{ marginLeft: '10px' }} size="sm">
          {fileLabel}
        </Text>
      </Stack>
    </>
  );
};

const getFileInputProperties = (
  isJTFeatureEnabled: boolean
): {
  acceptedFileTypes: string;
  toolTip: JSX.Element;
  buttonText: string;
} => {
  if (isJTFeatureEnabled) {
    return {
      acceptedFileTypes: '.step, .stp, .p21, .jt',
      toolTip: <Text size="xs">Accepts STEP and JT</Text>,
      buttonText: 'Select CAD File',
    };
  } else {
    return {
      acceptedFileTypes: '.step, .stp, .p21',
      toolTip: <Text size="xs">Please contact us for additional formats</Text>,
      buttonText: 'Select STEP File',
    };
  }
};
