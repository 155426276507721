import { SortingOption } from '../../../types';
import { FolderContentSortProps } from '@assemblio/type/folder';

export const FOLDER_QUERY_SORTING_OPTIONS: SortingOption<FolderContentSortProps>[] = [
  {
    value: 'name-desc',
    queryValue: FolderContentSortProps.name,
    label: 'Name (A to Z)',
    ascending: true,
  },
  {
    value: 'name-asc',
    queryValue: FolderContentSortProps.name,
    label: 'Name (Z to A)',
    ascending: false,
  },
  {
    value: 'updatedAt',
    queryValue: FolderContentSortProps.updatedAt,
    label: 'Last modified',
    ascending: true,
  },
  {
    value: 'createdAt',
    queryValue: FolderContentSortProps.createdAt,
    label: 'Oldest created',
    ascending: false,
  },
];
