import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { Quaternion, Vector } from '@assemblio/type/3d';
import { ITimeline } from '@assemblio/type/animation';

export type AnimationValue =
  | number
  | string
  | Array<number>
  | Vector
  | Quaternion;

export type AnimationAttribute = Map<string, AnimationValue>;

/**
 * An animation referencing a part altering one or more attributes over time
 */
export interface PartAnimation {
  gltfIndex: number;
  attributeSequence: Array<AnimationAttribute>;
}

export enum AnimationState {
  PLAYING,
  PAUSED,
  STOPPED,
}

/**
 * An animation of parts referencing one or more steps
 */
export interface AnimationClip {
  partAnimations: Array<PartAnimation>;
  stepReferences: Array<string>;
  cameraAttributes: AnimationAttribute;
  preludeIndex?: number;
  mainIndex?: number;
}

export interface AnimationStore {
  sequence: Array<AnimationClip>;
  timeline?: ITimeline;
  currentClipIndex: number;
  animationState: AnimationState;
  loop: boolean;
}

export const useAnimationStore = create<AnimationStore>()(
  devtools(
    () =>
      ({
        sequence: [],
        currentClipIndex: -1,
        animationState: AnimationState.STOPPED,
        loop: false,
      } as AnimationStore),
    {
      name: 'Animation Store',
      stateSanitizer: <AnimationStore>(state: AnimationStore) => {
        return { ...state, sequence: [] };
      },
    }
  )
);
