import { fetchBrandingFile, fetchBrandingOptions } from './branding.requests';
import { useQuery } from '@tanstack/react-query';
import { BrandingFileType } from '@assemblio/type/branding';

export const useBrandingQuery = () => {
  return useQuery(['branding'], () => fetchBrandingOptions());
};

export const useBrandingFileQuery = (type: BrandingFileType) => {
  return useQuery(['brandingFile', type], () => fetchBrandingFile(type), {
    retry: false,
  });
};
