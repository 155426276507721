import _ from 'lodash';
import { CanvasController, StepController, UIController } from '../controller';
import { SelectionMode, useSettingsStore } from '../stores';
import { EditorActionArgs } from './types/machine.types';
import { assertEvent } from 'xstate';

export const selectParts = ({ event }: EditorActionArgs) => {
  assertEvent(event, ['SELECT_PARTS', 'DESELECT_PARTS']);
  let selectionMode = useSettingsStore.getState().selectionMode;
  const {
    setSelectedParts,
    selectParts,
    deselectParts,
    expandParentAssemblies,
  } = UIController;
  const gltfIndexes: Array<number> = event.parts;

  if (event.type === 'DESELECT_PARTS') {
    selectionMode = SelectionMode.SUBTRACT;
  }

  switch (selectionMode) {
    case SelectionMode.REPLACE:
      setSelectedParts(gltfIndexes);
      expandParentAssemblies(gltfIndexes);
      break;
    case SelectionMode.ADD:
      selectParts(gltfIndexes);
      expandParentAssemblies(gltfIndexes);
      break;
    case SelectionMode.SUBTRACT:
      deselectParts(gltfIndexes);
      break;
  }
};

export const selectStep = ({ event }: EditorActionArgs) => {
  assertEvent(event, 'SELECT_STEP');
  UIController.selectStepById(event.stepId);
};

export const deselectParts = () => {
  UIController.deselectAllParts();
};

export const showTransformGizmo = () => {
  _.defer(() => UIController.showTransformGizmo());
};

export const hideTransformGizmo = () => {
  UIController.hideTransformGizmo();
};

export const showStepActions = () => {
  UIController.setShowStepActions(true);
};

export const hideStepActions = () => {
  UIController.setShowStepActions(false);
};

export const moveCameraGizmo = () => {
  const selectedStep = StepController.getSelectedStep();
  if (selectedStep) {
    UIController.setCameraGizmoTransform(
      _.cloneDeep(selectedStep.cameraSettings.transform)
    );
  }
};

export const preventCameraInteraction = () => {
  UIController.deactivateCameraControls();
};

export const allowCameraInteraction = () => {
  UIController.activateCameraControls();
};

export const showViewportControls = () => {
  UIController.setShowViewportControls(true);
};

export const hideViewportControls = () => {
  UIController.setShowViewportControls(false);
};

export const showToolbar = () => {
  UIController.setShowToolbar(true);
};

export const hideToolbar = () => {
  UIController.setShowToolbar(false);
};

export const showCameraGizmo = () => {
  UIController.showCameraGizmo();
};

export const hideCameraGizmo = () => {
  UIController.hideCameraGizmo();
};

export const showLineRenderer = () => {
  UIController.showLineRenderer();
};

export const hideLineRenderer = () => {
  UIController.hideLineRenderer();
};

export const selectPartsInSelectedStep = () => {
  const stepId = StepController.getSelectedStepId();
  if (stepId) UIController.selectPartsInStep(stepId);
};

export const moveCameraToSelectedStep = () => {
  const step = StepController.getSelectedStep();
  if (step) {
    CanvasController.setCameraTransform(step.cameraSettings.transform);
    CanvasController.setCameraZoom(step.cameraSettings.zoom);
    CanvasController.setCameraDistance(step.cameraSettings.distance);
  }
};

export const enableSurfaceCollision = () => {
  UIController.setSurfaceCollisionEnabled(true);
};

export const disableSurfaceCollision = () => {
  UIController.setSurfaceCollisionEnabled(false);
};
