import { UserDto } from '@assemblio/shared/dtos';
import { Mock_VersionInformation, Mock_VersionState, Mock_VersionAction } from '../types/utils.types';

const mockUser1: UserDto = {
  id: '1',
  email: 'john.doe@example.com',
  fullName: 'John Doe',
  firstName: 'John',
  lastName: 'Doe',
};
const mockUser2: UserDto = {
  id: '2',
  email: 'marco.manager@example.com',
  fullName: 'Marco Manager',
  firstName: 'Marco',
  lastName: 'Manager',
};
const mockUser3: UserDto = {
  id: '3',
  email: 'florian.strieg@example.com',
  fullName: 'Florian Strieg',
  firstName: 'Florian',
  lastName: 'Strieg',
};

export const mockVersions: Mock_VersionInformation[] = [
  {
    version: '1.0',
    type: 'Major',
    timestamp: new Date('2023-03-20'),
    state: Mock_VersionState.APPROVED,
    action: Mock_VersionAction.APPROVE,
    issuer: mockUser1,
  },
  {
    version: '1.1',
    type: 'Minor',
    timestamp: new Date('2023-03-19'),
    state: Mock_VersionState.REVIEW,
    action: Mock_VersionAction.SAVED,
    issuer: mockUser2,
  },
  {
    version: '1.2',
    type: 'Minor',
    timestamp: new Date('2023-03-18'),
    state: Mock_VersionState.CHANGES_NEEDED,
    action: Mock_VersionAction.REJECTED,
    comment: 'Changes needed to be made',
    issuer: mockUser1,
  },
  {
    version: '1.3',
    type: 'Major',
    timestamp: new Date('2023-03-17'),
    state: Mock_VersionState.DRAFT,
    action: Mock_VersionAction.CREATED,
    issuer: mockUser3,
  },
];
