import {
  VerticalAlignment,
  MarkerHead,
  AnchorType,
} from '@assemblio/type/annotation';

export type Anchor = {
  type: AnchorType;
  position: {
    x: number;
    y: number;
    z: number;
  } | null;
  annotationId: string | null;
};

export type PositionableAnnotation = {
  x: number;
  y: number;
};

export type SizeableAnnotation = PositionableAnnotation & {
  size: {
    width: number;
    height: number;
  };
};

export type TextAnnotation = {
  text: string;
  color: string;
  lineWidth: number;
};

export type ArrowAnnotation = PositionableAnnotation &
  TextAnnotation & {
    markerHead: MarkerHead;
    markerTail: MarkerHead;
    tx: number;
    ty: number;
    anchorHead: Anchor;
    anchorTail: Anchor;
  };

export type ShapeAnnotation = SizeableAnnotation &
  TextAnnotation & {
    background: string;
    lineColor: string;
    verticalTextAlignment: VerticalAlignment;
  };

export type ImageAnnotation = SizeableAnnotation;

export type ImageAnnotationFile = {
  id: string;
  originalName?: string;
};

export class AnnotationArrowDto {
  id: string;
  type: 'arrow';
  meta: ArrowAnnotation;

  constructor(id: string, meta: ArrowAnnotation) {
    this.id = id;
    this.type = 'arrow';
    this.meta = meta;
  }
}

export class AnnotationRectDto {
  id: string;
  type: 'rect';
  meta: ShapeAnnotation;

  constructor(id: string, meta: ShapeAnnotation) {
    this.id = id;
    this.type = 'rect';
    this.meta = meta;
  }
}

export class AnnotationEllipseDto {
  id: string;
  type: 'ellipse';
  meta: ShapeAnnotation;

  constructor(id: string, meta: ShapeAnnotation) {
    this.id = id;
    this.type = 'ellipse';
    this.meta = meta;
  }
}

export class AnnotationImageDto {
  id: string;
  type: 'image';
  meta: ImageAnnotation;
  file?: ImageAnnotationFile;

  constructor(id: string, meta: ImageAnnotation) {
    this.id = id;
    this.type = 'image';
    this.meta = meta;
  }
}

export class AddAnnotationToStepDto {
  annotationId!: string;
  stepId!: string;

  constructor(partId: string, stepId: string) {
    this.annotationId = partId;
    this.stepId = stepId;
  }
}

export type AnnotationResponseDto = AnnotationDto & {
  id: string;
  instruction: { id: string };
};

export type AnnotationMetaDto = ArrowAnnotation | ShapeAnnotation;

export type AnnotationDto =
  | AnnotationArrowDto
  | AnnotationRectDto
  | AnnotationEllipseDto
  | AnnotationImageDto;

export type AnnotationDtoWithId = AnnotationDto & { id: string };
