import { ModelController } from '@assemblio/frontend/stores';
import {
  ActionIcon,
  Box,
  Button,
  Checkbox,
  FileButton,
  Group,
  Modal,
  Text,
} from '@mantine/core';
import { useEffect, useRef, useState } from 'react';
import { IconX } from '@tabler/icons-react';
import {
  PartAssemblyWithName,
  createRenamingList,
  useCSVData,
} from './CSVData';
import { CSVList } from './CSVList';
import classes from './CSVModal.module.scss';
import {
  useBulkSetPartGroupName,
  useBulkSetPartName,
} from '@assemblio/frontend/data-access';

interface CSVModalProps {
  opened: boolean;
  setOpened: React.Dispatch<React.SetStateAction<boolean>>;
}

export const CSVModal = ({ opened, setOpened }: CSVModalProps) => {
  const [renamingList, setRenamingList] = useState<PartAssemblyWithName[]>([]);
  const [selectableValues, setSelectableValues] = useState<string[]>([]);
  const resetRef = useRef<() => void>(null);

  const {
    csvData,
    csvHeader,
    file,
    parsingErrors,
    hasHeader,
    setHasHeader,
    handleCSVImport,
    handleClearCSV,
  } = useCSVData();

  const [selectedValues, setSelectedValues] = useState<string[]>([]);
  const bulkSetPartGroupNameMutation = useBulkSetPartGroupName();
  const bulkSetPartNameMutation = useBulkSetPartName();

  useEffect(() => {
    if (csvData.length > 0) {
      const partsAndAssemblies = createRenamingList(csvData, csvHeader);

      const selectedValues: string[] = [];
      partsAndAssemblies.forEach((element) => {
        if (element.newName.length >= 1 && element.newName.length <= 255) {
          selectedValues.push(element.gltfIndex.toString());
        }
      });

      setRenamingList(partsAndAssemblies);
      setSelectableValues(selectedValues);
      setSelectedValues(selectedValues);
    }
  }, [csvData, csvHeader]);

  const handleSubmit = () => {
    if (renamingList.length > 0) {
      const partsOrAssemblies = renamingList
        .filter((element) =>
          selectedValues.includes(element.gltfIndex.toString())
        )
        .map((element) => ({
          gltfIndex: element.gltfIndex,
          name: element.newName,
        }));

      if (partsOrAssemblies.length > 0) {
        const { renamedParts, renamedAssemblies } =
          ModelController.setPartOrAssemblyNames(partsOrAssemblies);
        bulkSetPartNameMutation.mutate(renamedParts);
        bulkSetPartGroupNameMutation.mutate(renamedAssemblies);
        //TODO handle error cases
      }

      handleClose();
    }
  };

  const handleClearFile = () => {
    setRenamingList([]);
    setSelectableValues([]);
    setSelectedValues([]);
    handleClearCSV();
    resetRef.current?.();
  };

  const handleClose = () => {
    handleClearFile();
    setOpened(false);
  };

  const errors =
    parsingErrors.length > 0
      ? parsingErrors.map((error) => (
          <Box className={classes.errorBox}>
            <Text size="xs" color="red">
              {error.code} {error.row !== undefined && `in row ${error.row}`}
            </Text>
          </Box>
        ))
      : null;

  return (
    <Modal
      data-cy="modal-title"
      size="xl"
      radius={0}
      opened={opened}
      onClose={() => handleClose()}
      title={<Text>Import CSV file for renaming hierarchy elements</Text>}
    >
      <Group data-cy="file-input">
        <FileButton
          resetRef={resetRef}
          onChange={handleCSVImport}
          accept=".csv"
        >
          {(props) => (
            <Button size="xs" {...props} className={classes.fileButton}>
              Upload CSV File
            </Button>
          )}
        </FileButton>
        {file && (
          <>
            <Text size="xs" mb={14}>
              {file.name}
            </Text>
            <ActionIcon color="gray" onClick={handleClearFile} mb={18}>
              <IconX size={18} />
            </ActionIcon>
          </>
        )}
      </Group>
      <Checkbox
        data-cy="rename-csv-checkbox"
        size="xs"
        label="file contains header"
        checked={hasHeader}
        onChange={(event) => setHasHeader(event.currentTarget.checked)}
        className={classes.headerCheckbox}
      />

      {errors}
      {renamingList.length > 0 ? (
        <CSVList
          data={renamingList}
          header={csvHeader}
          selectedValues={selectedValues}
          setSelectedValues={setSelectedValues}
          selectableValues={selectableValues}
        />
      ) : file && !errors ? (
        <Text size={'14px'}>No relevant changes could be detected</Text>
      ) : null}
      <Group justify="right" gap="xs" className={classes.renameActionGroup}>
        <Button
          data-cy="ok-csv-modal-button"
          className={classes.renameActionButton}
          size="xs"
          onClick={() => handleSubmit()}
          disabled={selectedValues.length <= 0}
        >
          OK
        </Button>
        <Button
          size="xs"
          onClick={() => handleClose()}
          className={classes.renameActionButton}
        >
          Cancel
        </Button>
      </Group>
    </Modal>
  );
};
