import {
  CreateStepGroup,
  MoveStepGroup,
  PatchStepGroup,
} from './types/step-group.mutation.types';
import { axiosHandler } from '../utils/axios-utils';

const STEP_GROUP_URL = '/step-groups';

export const createStepGroup = async (data: CreateStepGroup) => {
  return axiosHandler('post', `${STEP_GROUP_URL}`, { data });
};

export const renameStepGroup = async ({ id, data }: PatchStepGroup) => {
  return axiosHandler('patch', `/step-groups/${id}/rename`, { data });
};

export const deleteStepGroup = async (id: string) => {
  return axiosHandler('delete', `/step-groups/${id}`);
};

export const moveStepGroup = async ({ id, data }: MoveStepGroup) => {
  return axiosHandler('patch', `${STEP_GROUP_URL}/${id}/move`, { data });
};
