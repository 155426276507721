import { useNavigate } from 'react-router-dom';
import { Container, Alert, Button } from '@mantine/core';
import { IconAlertCircle, IconArrowLeft } from '@tabler/icons-react';
import classes from './Unauthorized.module.scss';
import { Logo } from '../ImageMark';

export const Unauthorized = () => {
  const navigate = useNavigate();
  const goBack = () => navigate(-1);

  return (
    <div className={classes['wrapper']}>
      <Container size={420} my={120}>
        <Logo />
        <UnauthorizedAlert />
        <div className={classes['goBackButton']}>
          <Button
            type="submit"
            fullWidth
            onClick={goBack}
            leftSection={<IconArrowLeft />}
          >
            Go Back
          </Button>
        </div>
      </Container>
    </div>
  );
};

const UnauthorizedAlert = () => (
  <Alert
    mt={'xl'}
    mb={'sm'}
    style={{ marginBottom: '20px' }}
    icon={<IconAlertCircle size={16} />}
    title="Unauthorized!"
    color="red"
  >
    You do not have access to the requested page.
  </Alert>
);
