import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { createFolder, deleteFolder, moveNextProject, updateFolder } from './folder.requests';
import { FolderCreate, FolderDelete, FolderUpdate, NextProjectMove } from './types/folder.mutation.types';

export const useFolderCreate = () => {
  const queryClient = useQueryClient();

  return useMutation((folder: FolderCreate) => createFolder(folder), {
    onSuccess: (data, values) => {
      queryClient.invalidateQueries(['folder', values.folderData.parentFolderId]).then(() => {
        queryClient.refetchQueries({
          queryKey: ['folder', values.folderData.parentFolderId],
        });
      });
    },
    onError: (error) => {
      if (axios.isAxiosError(error)) {
        console.error('useFolderCreate', error?.response?.data.message);
      }
    },
  });
};

export const useFolderDelete = () => {
  const queryClient = useQueryClient();

  return useMutation((folder: FolderDelete) => deleteFolder(folder), {
    onSuccess: (data, values) => {
      queryClient.invalidateQueries(['folder', values.parentId]).then(() => {
        queryClient.refetchQueries({
          queryKey: ['folder', values.parentId],
        });
      });
    },
    onError: (error) => {
      if (axios.isAxiosError(error)) {
        console.error('useFolderDelete', error?.response?.data.message);
      }
    },
  });
};

export const useFolderUpdate = () => {
  const queryClient = useQueryClient();

  return useMutation((folder: FolderUpdate) => updateFolder(folder), {
    onSuccess: (_, values) => {
      queryClient.invalidateQueries(['folder', values.parentFolderId]);
    },
    onError: (error) => {
      if (axios.isAxiosError(error)) {
        console.error('useFolderUpdate', error?.response?.data.message);
      }
    },
  });
};

export const useNextProjectMove = () => {
  const queryClient = useQueryClient();

  return useMutation((options: NextProjectMove) => moveNextProject(options), {
    onSuccess: (data, values) => {
      queryClient.invalidateQueries(['folder']);
    },
    onError: (error) => {
      if (axios.isAxiosError(error)) {
        console.error('useProjectMove', error?.response?.data.message);
      }
    },
  });
};
