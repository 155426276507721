import { NextProjectDto } from '@assemblio/shared/dtos';
import { UseMutationResult, useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { useRef } from 'react';
import { Folder } from '../folders/types/folder.return.types';
import { ProgressCallback } from '../utils/progress-event';
import {
  changeProjectOwner,
  createEmptyProject,
  createProjectNext,
  deleteNextProject,
  deleteProject,
  updateNextProject,
} from './project.requests';
import {
  NextProjectChangeOwner,
  NextProjectCreate,
  NextProjectDelete,
  NextProjectUpdate,
} from './types/project.mutation.types';
import { CreateProjectDto } from '@assemblio/shared/next-types';

export const useProjectDelete = () => {
  const queryClient = useQueryClient();

  return useMutation((id: string) => deleteProject(id), {
    onSuccess: () => {
      queryClient.invalidateQueries(['projects']);
    },
  });
};

export const useNextProjectCreate = (
  events?: ProgressCallback
): [
  UseMutationResult<Readonly<NextProjectDto>, unknown, NextProjectCreate, unknown>,
  React.MutableRefObject<AbortController | null>
] => {
  const abortControllerRef = useRef<AbortController | null>(null);
  const queryClient = useQueryClient();

  return [
    useMutation(
      (project: NextProjectCreate) => {
        abortControllerRef.current = new AbortController();
        return createProjectNext(project, abortControllerRef.current?.signal, events);
      },
      {
        onSuccess: (_data, values) => {
          queryClient.invalidateQueries(
            values.projectData.projectId
              ? ['project', values.projectData.projectId]
              : ['folder', values.projectData.folderId]
          );
        },
        onError: (error) => {
          if (axios.isAxiosError(error)) {
            console.error('useProjectCreate', error?.response?.data.message);
          }
        },
      }
    ),
    abortControllerRef,
  ];
};

export const useCreateEmptyProject = () => {
  const queryClient = useQueryClient();

  return useMutation((project: CreateProjectDto) => createEmptyProject(project), {
    onSuccess: (data, values) => {
      queryClient.invalidateQueries(['folder', values.folderId]);
    },
    onError: (error) => {
      if (axios.isAxiosError(error)) {
        console.error('useCreateEmptyProject', error?.response?.data.message);
      }
    },
  });
};

export const useNextProjectDelete = () => {
  const queryClient = useQueryClient();

  return useMutation((project: NextProjectDelete) => deleteNextProject(project), {
    onSuccess: (data, values) => {
      if (values.parentFolderId) {
        queryClient.invalidateQueries(['folder', values.parentFolderId]);
      }
    },
    onError: (error) => {
      if (axios.isAxiosError(error)) {
        console.error('useProjectDelete', error?.response?.data.message);
      }
    },
  });
};

export const useNextProjectUpdate = () => {
  const queryClient = useQueryClient();

  return useMutation((update: NextProjectUpdate) => updateNextProject(update), {
    onSuccess: (_data, values) => {
      const invalidateFolder = values.projectData.folderId;
      queryClient.invalidateQueries(['folder', invalidateFolder]).then(() => {
        queryClient.refetchQueries({
          queryKey: ['folder', invalidateFolder],
        });
      });
    },
    onError: (error) => {
      if (axios.isAxiosError(error)) {
        console.error('useNextProjectUpdate', error?.response?.data.message);
      }
    },
  });
};

export const useNextProjectChangeOwner = () => {
  const queryClient = useQueryClient();
  return useMutation((options: NextProjectChangeOwner) => changeProjectOwner(options), {
    onMutate: async (updateValues) => {
      await queryClient.cancelQueries({
        queryKey: ['folder', updateValues.parentFolderId],
      });

      //Snapshot previous value
      const previousFolder: Folder | undefined = queryClient.getQueryData(['folder', updateValues.parentFolderId]);
      //update current queryData
      queryClient.setQueryData(['folder', updateValues.parentFolderId], {
        ...previousFolder,
        projects: previousFolder?.projects?.map((project) => {
          if (project.id === updateValues.projectId) {
            return {
              ...project,
              owner: updateValues.newOwner,
            };
          } else return project;
        }),
      });
      // Return a context with the previous and new todo
      return { previousFolder, updateValues };
    },
    onError: (error, updatedFolder, context) => {
      queryClient.setQueryData(['folder', updatedFolder.parentFolderId], context?.previousFolder);
      queryClient.invalidateQueries(['folder', updatedFolder.parentFolderId]).then(() => {
        queryClient.refetchQueries({
          queryKey: ['folder', updatedFolder.parentFolderId],
        });
      });
      if (axios.isAxiosError(error)) {
        console.error('useProjectChangeOwner', error?.response?.data.message);
      }
    },
  });
};
