import { GLTF, GLTFLoader } from 'three-stdlib';
import { axiosAPIKeyClient } from '../utils/axios-shared.utils';
import {
  AnnotationResponseDto,
  InstructionDto,
  InstructionTreeDto,
} from '@assemblio/shared/next-types';

export const fetchInstructionAPI = async (
  instructionId: string | undefined
): Promise<InstructionDto> => {
  return typeof instructionId === 'undefined'
    ? Promise.reject(new Error('Invalid id'))
    : axiosAPIKeyClient({
        method: 'get',
        url: `/instructions/${instructionId}`,
      }).then((response) => response.data);
};

export const fetchAnnotationsAPI = async (
  instructionId: string
): Promise<Array<AnnotationResponseDto>> => {
  return axiosAPIKeyClient({
    method: 'get',
    url: `/instructions/${instructionId}/annotations`,
  }).then((response) => response.data);
};

export const fetchInstructionPartsAPI = async (
  id: string | undefined
): Promise<InstructionTreeDto> => {
  return typeof id === 'undefined'
    ? Promise.reject(new Error('Invalid id'))
    : axiosAPIKeyClient({
        method: 'get',
        url: `/parts/instruction/${id}/tree`,
      }).then((response) => response.data);
};

export const downloadGLTFAPI = async (
  fileId: string | undefined
): Promise<GLTF> => {
  return typeof fileId === 'undefined'
    ? Promise.reject(new Error('Invalid id'))
    : axiosAPIKeyClient<ArrayBuffer>({
        method: 'get',
        url: `/instructions/files/${fileId}/gltf`,
        responseType: 'arraybuffer',
        headers: { 'Content-Type': 'application/octet-stream' },
      })
        .then((response) => response.data)
        .then((data) => {
          return new Promise((resolve, reject) => {
            const loader = new GLTFLoader();
            loader.parse(
              data,
              '',
              (gltf: GLTF) => resolve(gltf),
              (event) => reject(event.error)
            );
          });
        });
};

export const fetchStepGroupsAPI = async (query: {
  sequenceId?: string;
  reverseOrder: boolean;
}): Promise<any[]> => {
  return typeof query.sequenceId === 'undefined'
    ? Promise.reject(new Error('Invalid id'))
    : axiosAPIKeyClient({
        method: 'get',
        url: `/instructions/sequences/${query.sequenceId}/step-groups`,
        params: { reverse_order: query.reverseOrder },
      }).then((response) => {
        return response.data;
      });
};
