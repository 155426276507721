import { usePDFExport, useSequencesForExportQuery, useVideoExport } from '@assemblio/frontend/data-access';
import {
  Box,
  Button,
  Divider,
  Group,
  Loader,
  Modal,
  ScrollArea,
  Tabs,
  Text,
  TextInput,
  Tooltip,
  useMantineTheme,
} from '@mantine/core';
import classes from './ExportModal.module.scss';
import { useDocumentTitle } from '@assemblio/frontend/hooks';
import { useProjectStore } from '@assemblio/frontend/stores';
import { DocumentExportType, ExportSelection, Resolution } from '@assemblio/shared/next-types';
import { useForm } from '@mantine/form';
import { IconFileTypeDocx, IconFileTypePdf, IconVideo } from '@tabler/icons-react';
import { useCallback, useEffect, useState } from 'react';
import { SelectableSequence } from './SelectableSequence';
import { VideoExporterSettings } from './VideoExportSettings';
import { ExportOptions, ExportSequenceSelection, VideoFormatPreset } from './types/export-modal.types';
import { TextTransition, wait } from '../Utilities';
import produce from 'immer';
import { useHasFeatureFlag } from '../Utilities/useHasFeatureFlag.hook';
import { FeatureFlag } from '@assemblio/type/feature-flag';

type ExportModalProps = {
  instructionId: string;
  opened: boolean;
  close: () => void;
  name?: string;
};

// This needs correcting, because it makes no sense.
// Suggestion: change value to "wmp" | "recommended"?
const VIDEO_FORMAT_PRESETS: VideoFormatPreset[] = [
  {
    label: 'Recommended',
    value: { codec: 'avi', container: 'mp4' },
  },
  {
    label: 'Windows Media Player',
    value: { codec: 'mjpeg', container: 'avi' },
  },
];

export const ExportModal = ({ instructionId, opened, close, name }: ExportModalProps) => {
  const theme = useMantineTheme();
  const exportQuery = useSequencesForExportQuery({
    instructionId,
    modalOpened: opened,
  });
  const videoExportMutation = useVideoExport();
  const pdfExportMutation = usePDFExport();

  const isWordFeatureEnabled = useHasFeatureFlag(FeatureFlag.WordExport);

  const instructionName = name ?? useProjectStore.getState().name;
  useDocumentTitle('exporter', instructionName);

  const [created, setCreated] = useState<boolean>(false);
  const [selection, setSelection] = useState<ExportSequenceSelection | undefined>(undefined);
  const [selectedExportOption, setSelectedExportOption] = useState<ExportOptions>('PDF');
  const [resolution, setResolution] = useState<Resolution>('1280x720');
  const [format, setFormat] = useState<VideoFormatPreset>(VIDEO_FORMAT_PRESETS[0]);

  const removeInvalidCharacters = (value: string): string => value.replace(/[^\w\s-_$€£*@#%&`A-Za-zÀ-ÿ]/gi, '-');
  const isInvalidCharacters = (value: string): boolean => /[^\w\s-_$€£*@#%&`A-Za-zÀ-ÿ]/i.test(value);

  const exportForm = useForm({
    initialValues: {
      name: removeInvalidCharacters(instructionName),
    },
    onValuesChange: (value) => {
      if (isInvalidCharacters(value.name)) exportForm.setFieldValue('name', removeInvalidCharacters(value.name));
    },
    validate: {
      name: (value) => (value.trim().length > 0 ? null : 'File name is required'),
    },
  });

  const handleFormatSelection = (value: string) => {
    const presetToUse = VIDEO_FORMAT_PRESETS.find((preset) => preset.label === value);
    if (presetToUse) setFormat(presetToUse);
  };

  /**
   * Toggle Sequence in Selection
   * @param sequenceId
   */
  const handleSequenceSelection = useCallback((sequenceId: string) => {
    setSelection(
      produce<ExportSequenceSelection>((draft) => {
        const seq = draft.find((seq) => seq.sequenceId === sequenceId);
        if (seq) {
          const isSelected = !seq.selected;
          seq.selected = isSelected;
          seq.stepGroups = seq.stepGroups.map((sG) => {
            return { ...sG, selected: isSelected };
          });
        }
      })
    );
  }, []);

  /**
   * Toggle StepGroup in Selection
   * @param sequenceId
   * @param stepGroupId
   */
  const handleStepGroupSelection = useCallback((sequenceId: string, stepGroupId: string) => {
    setSelection(
      produce<ExportSequenceSelection>((draft) => {
        const seq = draft.find((seq) => seq.sequenceId === sequenceId);
        if (seq) {
          const sG = seq.stepGroups.find((sG) => sG.stepGroupId === stepGroupId);
          if (sG) {
            // Toggle the selected state of the step group
            sG.selected = !sG.selected;

            // Check if all step groups are not selected, and update the sequence's selected state accordingly
            const areAllStepGroupsDeselected = seq.stepGroups.every((sg) => !sg.selected);
            if (areAllStepGroupsDeselected) {
              seq.selected = false;
            }
          }
        }
      })
    );
  }, []);

  const handleSubmit = async (fileName: string) => {
    if (!selection) return;
    const transformedSelection = transformSelection(selection);

    if (['PDF', 'Word'].includes(selectedExportOption)) {
      const exportType = selectedExportOption === 'PDF' ? DocumentExportType.PDF : DocumentExportType.DOCX;

      pdfExportMutation.mutate(
        {
          id: instructionId,
          data: { instructionId, fileName, selection: transformedSelection, type: exportType },
        },
        {
          onSuccess: handleSubmissionSuccess,
        }
      );
    } else if (selectedExportOption === 'Video') {
      videoExportMutation.mutate(
        {
          id: instructionId,
          data: {
            instructionId,
            fileName,
            selection: transformedSelection,
            resolution,
            format: format.value,
          },
        },
        {
          onSuccess: handleSubmissionSuccess,
        }
      );
    }
  };

  const handleSubmissionSuccess = async () => {
    setCreated(true);
    await wait(2000);
    setCreated(false);
  };

  const handleClose = () => {
    setResolution('1280x720');
    setFormat(VIDEO_FORMAT_PRESETS[0]);
    setSelectedExportOption('PDF');
    setSelection(undefined);
    setCreated(false);
    exportForm.reset();
    close();
  };

  useEffect(() => {
    if (!opened) return;
    if (!selection && exportQuery.data)
      setSelection(
        exportQuery.data?.map((seq) => {
          return {
            sequenceId: seq.id,
            selected: true,
            stepGroups: seq.stepGroups.map((sG) => {
              return {
                stepGroupId: sG.id,
                selected: true,
              };
            }),
          };
        })
      );
  }, [exportQuery.data, selection, opened]);

  useEffect(() => {
    exportForm.setFieldValue('name', removeInvalidCharacters(instructionName));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instructionName]);

  let submitDisabled = false;

  if (selection) {
    const toBeSubmited = transformSelection(selection);
    submitDisabled = toBeSubmited.length <= 0;
  }

  if (exportQuery.error) {
    return <div>An error has occurred while loading the project</div>;
  } else {
    return (
      <Modal
        data-cy="export-modal"
        onClose={handleClose}
        mah={'60vh'}
        opened={opened}
        size={'sm'}
        closeOnClickOutside
        title={<Text>{`Export ${instructionName} Instruction`}</Text>}
      >
        <form onSubmit={exportForm.onSubmit((values) => handleSubmit(values.name))}>
          <Box className={classes.wrapper}>
            <Text size={'sm'} mb={`calc(${theme.spacing.xs} / 2)`}>
              Included Sequences
            </Text>
            <ScrollArea.Autosize mah={'40vh'}>
              {!selection ? (
                <Loader />
              ) : (
                exportQuery.data?.map((seq) => (
                  <SelectableSequence
                    key={seq.id}
                    name={seq.name}
                    selection={selection}
                    stepGroups={seq.stepGroups}
                    sequenceId={seq.id}
                    onSequenceSelection={handleSequenceSelection}
                    onStepGroupSelection={handleStepGroupSelection}
                  />
                ))
              )}
            </ScrollArea.Autosize>
          </Box>
          <Divider my={'xs'} />
          <Text>Settings</Text>
          <Tabs
            defaultValue={selectedExportOption}
            value={selectedExportOption}
            onChange={(value) => setSelectedExportOption(value as ExportOptions)}
          >
            <Tabs.List mb={'xs'}>
              <Tabs.Tab value="PDF" leftSection={<IconFileTypePdf size="1rem" />}>
                PDF
              </Tabs.Tab>

              <Tooltip
                multiline
                w={210}
                label={<Text size="xs">Please contact us for more information about this feature</Text>}
                disabled={isWordFeatureEnabled}
              >
                <Tabs.Tab value="Word" disabled={!isWordFeatureEnabled} leftSection={<IconFileTypeDocx size="1rem" />}>
                  Word
                </Tabs.Tab>
              </Tooltip>

              <Tabs.Tab value={'Video'} leftSection={<IconVideo size="1rem" />}>
                Video
              </Tabs.Tab>
            </Tabs.List>
            <TextInput
              data-cy="name-export-modal"
              w={'100%'}
              label="Name"
              mb={'xs'}
              {...exportForm.getInputProps('name')}
            />
            <Tabs.Panel value="Video">
              <VideoExporterSettings
                presets={VIDEO_FORMAT_PRESETS}
                resolution={resolution}
                format={format}
                setResolution={setResolution}
                onFormatSelection={handleFormatSelection}
              />
            </Tabs.Panel>
            <Tabs.Panel value="PDF">
              {/* PDF Settings once there are any */}
              <Group></Group>
            </Tabs.Panel>
            {isWordFeatureEnabled && (
              <Tabs.Panel value="Word">
                {/* Word Settings once there are any */}
                <Group></Group>
              </Tabs.Panel>
            )}
          </Tabs>

          <Button
            type="submit"
            mt={'md'}
            fullWidth
            disabled={submitDisabled}
            loading={pdfExportMutation.isLoading || videoExportMutation.isLoading}
          >
            <TextTransition from={`Export ${selectedExportOption}`} to="Created" on={created} />
          </Button>
        </form>
      </Modal>
    );
  }
};

const transformSelection = (selection: ExportSequenceSelection): ExportSelection => {
  return selection.reduce((res, cur) => {
    if (cur.selected) {
      const toExport = {
        sequenceId: cur.sequenceId,
        stepGroupIds: cur.stepGroups.filter((sG) => sG.selected).map((sG) => sG.stepGroupId),
      };
      res.push(toExport);
    }
    return res;
  }, [] as ExportSelection);
};
