import { Box3 } from 'three';
import { ModelController, ModelVisibilityController, StepController, UIController } from '../controller';
import { useModelStore, useSequenceStore, useUIStore } from '../stores';
import { ModelInformation } from '../stores/ModelStore';

export const updateSelectionBounds = () => {
  const selectedParts = useUIStore.getState().selectedPartSet;
  const bounds = new Box3();
  selectedParts.forEach((gltfIndex) => {
    const model = ModelController.getModelByGltfIndex(gltfIndex);
    if (model) {
      bounds.expandByObject(model);
    }
  });
  UIController.setSelectionBounds(bounds);
};

export const highlightParts = () => {
  UIController.highlightSelectedParts();
};

export const unhighlightParts = () => {
  UIController.unhighlightSelectedParts();
};

export const showPartsInSelectedStep = () => {
  const selectedStep = StepController.getSelectedStep();
  if (selectedStep) {
    selectedStep.data &&
      selectedStep.data.parts.forEach((part) => {
        ModelVisibilityController.setPartVisible(part.partGltfIndex, true);
      });
  }
};

export const showPartsInSelectedAndPreviousSteps = () => {
  const disassemblyOrderSteps = StepController.getSelectedAndFollowingStepsInDisassemblyOrder();
  const assemblyOrderSteps = StepController.getSelectedAndFollowingStepsInAssemblyOrder();

  if (disassemblyOrderSteps) {
    disassemblyOrderSteps.forEach(
      (step) =>
        step.data &&
        step.data.parts.forEach((part) => {
          ModelVisibilityController.setPartVisible(part.partGltfIndex, true);
        })
    );
  }
  // Also show parts in alignment steps
  if (assemblyOrderSteps) {
    const usedParts = new Set<number>();
    assemblyOrderSteps.forEach((step, index) => {
      if (index === assemblyOrderSteps.length - 1) return;

      step.data.parts.forEach((part) => {
        if (step.type === 'alignment') {
          if (!usedParts.has(part.partGltfIndex)) {
            ModelVisibilityController.setPartVisible(part.partGltfIndex, true);
          }
        } else {
          usedParts.add(part.partGltfIndex);
        }
      });
    });
  }
};

export const showSelectedParts = () => {
  const selectedParts = useUIStore.getState().selectedPartSet;
  selectedParts.forEach((gltfIndex) => {
    ModelVisibilityController.setPartVisible(gltfIndex, true);
  });
};

export const setTransparencyOff = () => {
  ModelVisibilityController.setTransparencyOffCached();
};

export const setPartsTransparent = () => {
  ModelVisibilityController.setPartsInSequenceTransparent();
};

export const restoreTransparency = () => {
  ModelVisibilityController.restoreTransparencyFromCache();
};

export const hidePartsInSteps = () => {
  useSequenceStore.getState().stepGroups.forEach((stepGroup) => {
    stepGroup.steps.forEach((step) => {
      if (step.type === 'assembly') {
        step.data &&
          step.data.parts.forEach((part) => {
            ModelVisibilityController.setPartVisible(part.partGltfIndex, false);
          });
      }
    });
  });
};

export const hideOtherPartsInSteps = () => {
  const selectedParts = useUIStore.getState().selectedPartSet;
  useSequenceStore.getState().stepGroups.forEach((stepGroup) => {
    stepGroup.steps.forEach((step) => {
      if (step.type === 'assembly') {
        step.data &&
          step.data.parts.forEach((part) => {
            if (!selectedParts.has(part.partGltfIndex)) {
              ModelVisibilityController.setPartVisible(part.partGltfIndex, false);
            }
          });
      }
    });
  });
};

export const hidePartsInSelectedStepAndFollowing = () => {
  const steps = StepController.getSelectedAndPreviousStepsInDisassemblyOrder();

  if (steps) {
    steps.forEach((step) => {
      if (step.type === 'assembly') {
        step.data &&
          step.data.parts.forEach((part) => {
            ModelVisibilityController.setPartVisible(part.partGltfIndex, false);
          });
      }
    });
  } else {
    useSequenceStore.getState().stepGroups.forEach((stepGroup) => {
      stepGroup.steps.forEach((step) => {
        if (step.type === 'assembly') {
          step.data &&
            step.data.parts.forEach((part) => {
              ModelVisibilityController.setPartVisible(part.partGltfIndex, false);
            });
        }
      });
    });
  }
};

export const movePartsInPreviousStepsToAssembledPosition = () => {
  const steps = StepController.getSelectedAndFollowingStepsInDisassemblyOrder();
  if (steps) {
    steps.forEach((step, index) => {
      if (index === 0) return;
      step.data.parts.forEach((part) => {
        ModelController.movePartToStepState(part.partGltfIndex);
      });
    });
  }
};

export const movePartsInSelectedStepToDisassembledPosition = () => {
  StepController.movePartsInSelectedStepToDisassembledPosition();
};

export const movePartsToStepStartPosition = () => {
  const selectedStep = StepController.getSelectedStep();
  if (selectedStep && selectedStep.data) {
    selectedStep.data.parts.forEach((part) => {
      ModelController.movePartToStepTransform(part.partGltfIndex, selectedStep);
    });
  }
};

export const movePartsToSelectedSegment = () => {
  const { selectedStep, selectedPathSegmentMap } = useUIStore.getState();
  if (selectedStep) {
    const index = selectedPathSegmentMap.get(selectedStep.id);
    const step = StepController.getStep(selectedStep.id);
    if (index !== undefined && step) {
      ModelController.movePartsToStepSegment(index, step.data);
    }
  }
};

export const setPartsToStartPosition = () => {
  const modelInformationMap = useModelStore.getState().modelInformationMap;
  modelInformationMap.forEach((_value: ModelInformation, key: number) => {
    if (StepController.hasStep(key)) {
      ModelController.movePartByProgress(key, 'disassembled');
    }
  });
};

export const movePartsToDisassembledPosition = () => {
  useSequenceStore.getState().stepGroups.forEach((stepGroup) => {
    stepGroup.steps.forEach((step) => {
      step.data &&
        step.data.parts.forEach((part) => {
          ModelController.movePartByProgress(part.partGltfIndex, 'disassembled');
        });
    });
  });
};
