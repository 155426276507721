import { Box, Center, Text, useMantineTheme } from '@mantine/core';
import { useObjectUrl } from './hooks/ObjectUrl';
import { IconFaceIdError } from '@tabler/icons-react';
import { useEffect, useRef } from 'react';

interface Props {
  src: string;
  alt: string;
  width?: string;
  height?: string;
  onLoad?: React.ReactEventHandler<HTMLImageElement>;
  onResize?: (size: DOMRect, target: HTMLImageElement) => void;
}

export const AuthImg = ({
  src,
  alt,
  width,
  height,
  onLoad,
  onResize,
}: Props) => {
  const objectUrl = useObjectUrl(src);
  const theme = useMantineTheme();
  const imageRef = useRef<HTMLImageElement>(null);

  const resizeObserver = useRef(
    new ResizeObserver((entries) => {
      if (onResize && entries.length) {
        onResize(entries[0].contentRect, entries[0].target as HTMLImageElement);
      }
    })
  );

  useEffect(() => {
    const reference = imageRef.current;
    const observer = resizeObserver.current;
    if (reference) {
      observer.observe(reference);
      return () => observer.unobserve(reference);
    }
    return;
  });

  return objectUrl ? (
    <img
      ref={imageRef}
      src={objectUrl}
      alt={alt}
      width={width}
      height={height}
      onLoad={onLoad}
    />
  ) : (
    <Box style={{ background: theme.colors.dark[5] }}>
      <Center>
        <IconFaceIdError size={100} />
        <Text>Image Not Found</Text>
      </Center>
    </Box>
  );
};
