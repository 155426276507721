import { Easing } from '@tweenjs/tween.js';
import _ from 'lodash';
import { Emitter, Unsubscribe, createNanoEvents } from 'nanoevents';
import { v4 as uuid } from 'uuid';
import {
  ClipData,
  ClipType,
  IntervalData,
  SequenceData,
  SequenceEvents,
  SequenceType,
  TartisEventEmitter,
  TartisEvents,
  TartisState,
  TransformType,
} from './Tartis.types';

/* Requirements:
  1. Have separate animation clips that can be played, scrubbed
     and repeated on demand.
  2. Clips must contain a set of transforms that don't have to
      be the same length and can be staggered in their start time
  2. A configurable sequence of clips that are played on demand
  3. Observable state transitions
  4. Global scrubbing and repeating across clips
  5. It should be possible to deactivate clips
  */

export class Tartis implements TartisEventEmitter {
  private _state: TartisState = 'stopped';
  private _sequence: SequenceType;
  private _lastFrameTime: number | undefined = undefined;
  private _elapsed = 0;
  private _emitter: Emitter<TartisEvents>;
  private _loop: { clipId?: string; active: boolean };

  private registerSequenceEvents() {
    this._sequence.on('clipStarted', (clipId: string, clip: SequenceData) => {
      if (
        !this._loop.active ||
        (this._loop.active && clipId === this._loop.clipId)
      ) {
        this._emitter.emit('clipStarted', clipId, clip);
      }
    });
    this._sequence.on('clipFinished', (clipId: string, clip: SequenceData) => {
      if (this._loop.active && clipId === this._loop.clipId) {
        this.goToClip(this._loop.clipId);
      }

      this._emitter.emit('clipFinished', clipId, clip);
      // Clips are active from startTime to startTime + duration - 1
      // think of it like indices vs length of arrays.
      if (!this._loop.active && this._elapsed >= this.duration - 1) {
        this._emitter.emit('finish');
        this.transitionToState('stopped');
      }
    });
  }

  enableClips(clipIds: Array<string>, enabled: boolean) {
    this._sequence.setClipsEnabled(clipIds, enabled);
  }

  orderClips(clipIds: Array<string>) {
    this._sequence.reorder(clipIds);
  }

  getClips() {
    return this._sequence.clips;
  }

  getClipById(id: string) {
    return this._sequence.getClipById(id);
  }

  private transitionToState(state: TartisState) {
    this._state = state;
    this._emitter.emit('stateChange', state);
  }

  finished() {
    return this._elapsed === this.duration;
  }

  constructor() {
    this._emitter = createNanoEvents();
    this._sequence = new Sequence();
    this.registerSequenceEvents();
    this._loop = {
      clipId: undefined,
      active: false,
    };
  }

  on<E extends keyof TartisEvents>(
    event: E,
    callback: TartisEvents[E]
  ): Unsubscribe {
    return this._emitter.on(event, callback);
  }

  reset() {
    this.transitionToState('stopped');
    this._sequence = new Sequence();
    this._lastFrameTime = undefined;
    this._elapsed = 0;
    this._emitter.emit('reset');
    this.registerSequenceEvents();
  }

  public get duration(): number {
    return this._sequence.duration;
  }

  public get progress(): number {
    return this._elapsed / this.duration;
  }

  public advance(ms: number) {
    const previousTime = this._elapsed;
    this._elapsed += ms;
    this._sequence.updateTimeInterval(previousTime, this._elapsed);
    this._emitter.emit('update', this._elapsed, this.duration);
  }

  public seek(alpha: number) {
    const duration = this.duration;
    const targetTime = alpha * duration;
    this._sequence.updateTimeInterval(this._elapsed, targetTime);
    this._elapsed = targetTime;
    this._emitter.emit('update', this._elapsed, duration);
  }

  findClip(
    predicate: (clip: ClipType<unknown>) => boolean
  ): SequenceData | undefined {
    return this._sequence.findClip(predicate);
  }

  public goToClipEnd(clipId?: string) {
    const clipIds = clipId ? [clipId] : this.getActiveClips();

    if (!clipIds.length) return; // Nothing to do here
    const longestClip = clipIds.reduce((maxId, clipId) => {
      const maxClip = this._sequence.getClipById(maxId);
      const currentClip = this._sequence.getClipById(clipId);
      if (maxClip && currentClip) {
        const maxTime = this._sequence.getTimeForClip(maxClip);
        const currentTime = this._sequence.getTimeForClip(currentClip);
        return maxTime + maxClip.duration > currentTime + currentClip.duration
          ? maxId
          : clipId;
      }
      return maxId;
    }, clipIds.at(0) as string);
    const clip = this._sequence.getClipById(longestClip);
    if (!clip) return;
    const start = this._elapsed;
    this._elapsed = this._sequence.getTimeForClip(clip) + clip.duration - 1;
    this._sequence.updateTimeInterval(start, this._elapsed);
  }

  public goToTime(ms: number) {
    this._sequence.updateTimeInterval(this._elapsed, ms);
    this._elapsed = ms;
  }

  public goToClip(clipId: string, holdEvents?: boolean) {
    const clip = this._sequence.getClipById(clipId);
    if (!clip) return;
    const clipTime = this._sequence.getTimeForClip(clip);
    this._sequence.updateTimeInterval(this._elapsed, clipTime, holdEvents);
    this._elapsed = clipTime;
  }

  private animate(timestamp: number) {
    if (this._lastFrameTime === undefined) {
      this._lastFrameTime = timestamp;
    }
    const delta = timestamp - this._lastFrameTime;
    this.advance(delta);
    if (this._state === 'playing') {
      requestAnimationFrame(this.animate.bind(this));
      this._lastFrameTime = timestamp;
    } else {
      this._lastFrameTime = undefined;
    }
  }

  public play(clipId?: string) {
    if (clipId) {
      this.goToClip(clipId, true);
    }
    this.transitionToState('playing');
    this._emitter.emit('update', this._elapsed, this.duration);

    requestAnimationFrame(this.animate.bind(this));
  }

  public stop() {
    this.transitionToState('stopped');
    this._elapsed = 0;
    this._lastFrameTime = undefined;
    this._emitter.emit('update', 0, this.duration);
  }

  public pause() {
    this.transitionToState('paused');
    this._lastFrameTime = undefined;
  }

  findNextNonTransitionalClip(clip: SequenceData): SequenceData | undefined {
    const sequenceIndex = this._sequence.clips.findIndex(
      (sequenceClip) => sequenceClip.id === clip.id
    );
    for (let i = sequenceIndex + 1; i < this._sequence.length; i++) {
      const nextClip = this._sequence.getClipByIndex(i);
      if (nextClip && !nextClip.transitional && nextClip.enabled) {
        return nextClip;
      }
    }
    return undefined;
  }

  findPreviousNonTransitionalClip(
    clip: SequenceData
  ): SequenceData | undefined {
    const sequenceIndex = this._sequence.clips.findIndex(
      (sequenceClip) => sequenceClip.id === clip.id
    );
    for (let i = sequenceIndex - 1; i >= 0; i--) {
      const nextClip = this._sequence.getClipByIndex(i);
      if (nextClip && !nextClip.transitional && nextClip.enabled) {
        return nextClip;
      }
    }
    return undefined;
  }

  public next() {
    const { clip } = this._sequence.getClipAtTime(this._elapsed);
    const nextClip = clip ? this.findNextNonTransitionalClip(clip) : undefined;
    if (nextClip) {
      if (this._loop.active) {
        this._loop.clipId = nextClip.id;
      }
      this.goToTime(this._sequence.getTimeForClip(nextClip));
    } else {
      if (clip) {
        this.goToTime(this._sequence.getTimeForClip(clip) + clip.duration);
      }
      this._emitter.emit('finish');
      this.transitionToState('stopped');
    }
  }

  public previous() {
    const { clip } = this._sequence.getClipAtTime(this._elapsed);
    const nextClip = clip
      ? this.findPreviousNonTransitionalClip(clip)
      : undefined;
    if (nextClip) {
      if (this._loop.active) {
        this._loop.clipId = nextClip.id;
      }
      this.goToTime(this._sequence.getTimeForClip(nextClip));
    }
  }

  public add(clip: Clip<unknown>) {
    this._sequence.add(clip);
  }

  public getActiveClips(): Array<string> {
    return this._sequence.getActiveClips();
  }

  public getCurrentClipId(): string | undefined {
    return this._sequence.getClipAtTime(this._elapsed).clip?.id;
  }

  public get state() {
    return this._state;
  }

  public get length() {
    return this._sequence.length;
  }

  public set loop(shouldLoop: boolean) {
    if (shouldLoop) {
      const { clip } = this._sequence.getClipAtTime(this._elapsed);
      if (clip) {
        if (clip.transitional) {
          const nextNonTranstionalClip = this.findNextNonTransitionalClip(clip);
          this._loop.clipId = nextNonTranstionalClip?.id;
          this._loop.active = true;
        } else {
          this._loop.clipId = clip.id;
          this._loop.active = true;
        }
      }
    } else {
      this._loop.clipId = undefined;
      this._loop.active = false;
    }
  }
}

export class Sequence implements SequenceType {
  clips: Array<SequenceData>;
  currentClips: Array<SequenceData>;
  private emitter: Emitter<SequenceEvents>;

  constructor() {
    this.clips = new Array<SequenceData>();
    this.emitter = createNanoEvents();
    this.currentClips = new Array<SequenceData>();
  }

  on<E extends keyof SequenceEvents>(
    event: E,
    callback: SequenceEvents[E]
  ): Unsubscribe {
    return this.emitter.on(event, callback);
  }

  getClipById(clipId: string): SequenceData | undefined {
    return this.clips.find((clip) => clip.id === clipId);
  }

  getClipByIndex(index: number): SequenceData | undefined {
    return this.clips.at(index);
  }

  getActiveClips(): Array<string> {
    return this.currentClips.map((clip) => clip.id);
  }

  getTimeForClip(clip: SequenceData) {
    const clipIndex = this.getIndexByClipId(clip.id);
    let duration = 0;
    for (let i = 0; i < clipIndex; i++) {
      const currentClip = this.clips.at(i);
      duration += currentClip && currentClip.enabled ? currentClip.duration : 0;
    }
    return duration;
  }

  getClipAtTime(time: number) {
    let clipTime = 0;
    const clip = this.clips.find((clip) => {
      if (clip.enabled && time >= clipTime && time < clipTime + clip.duration) {
        return true;
      }
      clipTime += clip.enabled ? clip.duration : 0;
      return false;
    });
    return { clip, time: time - clipTime };
  }

  notifyClipChanges(intervalData: Array<IntervalData>) {
    intervalData.forEach((data) => {
      if (data.startedInInterval) {
        this.emitter.emit('clipStarted', data.clip.id, data.clip);
      }
      if (data.endedInInterval) {
        this.emitter.emit('clipFinished', data.clip.id, data.clip);
      }
    });
  }

  updateTimeInterval(startTime: number, endTime: number, holdEvents?: boolean) {
    const affectedClips = this.getClipsForTimeInterval(startTime, endTime);
    affectedClips.forEach((intervalData) => {
      if (intervalData.clip.enabled) {
        intervalData.clip.updateTimeInterval(
          startTime - intervalData.time,
          endTime - intervalData.time
        );
      } else {
        if (startTime <= endTime) {
          intervalData.clip.updateTimeInterval(
            0,
            intervalData.clip.duration - 1
          );
        } else {
          intervalData.clip.updateTimeInterval(
            intervalData.clip.duration - 1,
            0
          );
        }
      }
    });
    if (!holdEvents) {
      this.notifyClipChanges(affectedClips);
    }
    this.currentClips = affectedClips.map((affectedClip) => affectedClip.clip);
  }

  getClipsBetweenIndices(
    startIndex: number,
    endIndex: number
  ): Array<SequenceData> {
    if (startIndex > endIndex) {
      [startIndex, endIndex] = [endIndex, startIndex];
    }
    return this.clips.slice(startIndex, endIndex);
  }

  getClipsForTimeInterval(
    startTime: number,
    endTime: number
  ): Array<IntervalData> {
    let clipTime = 0;
    const forward = startTime < endTime;
    const filteredClips = this.clips.reduce<Array<IntervalData>>(
      (affectedClipIds, clip) => {
        const st = forward ? startTime : endTime,
          et = forward ? endTime : startTime,
          ct = clipTime,
          ctd = clipTime + clip.duration - 1;
        if (ct <= et && ctd >= st) {
          affectedClipIds.push({
            clip,
            time: clipTime,
            startedInInterval: forward ? ct >= st : ctd < et,
            endedInInterval: forward ? ctd < et : ct > st,
          });
        }
        if (clip.enabled) {
          clipTime += clip.duration;
        }
        return affectedClipIds;
      },
      new Array<IntervalData>()
    );
    if (!forward) {
      filteredClips.reverse();
    }
    return filteredClips;
  }

  getIndexByClipId(clipId: string) {
    return this.clips.findIndex((clip) => clip.id === clipId);
  }

  findClip(
    predicate: (clip: ClipType<unknown>) => boolean
  ): SequenceData | undefined {
    return this.clips.find((sequenceData) => {
      return predicate(sequenceData);
    });
  }

  setClipsEnabled(clipIds: Array<string>, enabled: boolean) {
    clipIds.forEach((clipId) => {
      const clip = this.clips.find((clip) => clip.id === clipId);
      if (clip) {
        clip.enabled = enabled;
      }
    });
  }

  reorder(clipIds: Array<string>) {
    this.clips = clipIds
      .map((clipId) => {
        return this.clips.find((clip) => clip.id === clipId);
      })
      .filter((clip) => clip !== undefined) as Array<SequenceData>;
    //order ? (this.order = [...order]) : (this.order = [...this.additionOrder]);
  }

  get length(): number {
    return this.clips.length;
  }

  add(clip: Clip<unknown>) {
    this.clips.push(clip);
  }

  get duration(): number {
    return this.clips.reduce<number>((current, clip) => {
      return current + (clip.enabled ? clip.duration : 0);
    }, 0);
  }
}

export class Clip<T> implements ClipType<T> {
  id: string;
  data?: T;
  transitional: boolean;
  enabled: boolean;
  transforms: Array<ClipData>;

  constructor(data?: T) {
    this.id = uuid();
    this.data = data;
    this.transitional = false;
    this.enabled = true;
    this.transforms = new Array<ClipData>();
  }

  updateTimeInterval(startTime: number, endTime: number) {
    const targetTime = endTime;
    const affectedTransforms = this.getTransformsForTimeInterval(
      startTime,
      endTime
    );
    affectedTransforms.forEach((item) => {
      const alpha = (targetTime - item.time) / item.duration;
      item.transform.update(alpha);
    });
  }

  getTransformsForTimeInterval(startTime: number, endTime: number) {
    const st = startTime < endTime ? startTime : endTime,
      et = endTime >= startTime ? endTime : startTime;
    const filtered = this.transforms.filter((clipData) => {
      const ctd = clipData.time + clipData.duration,
        ct = clipData.time;
      return ct <= et && ctd >= st;
    });
    if (endTime < startTime) {
      filtered.reverse();
    }
    return filtered;
  }

  add<T, V>(transform: Transform<T, V>, duration = 2000, time?: number) {
    if (time === undefined) {
      time = this.duration;
    }
    this.transforms.push({
      time,
      duration,
      transform,
      enabled: true,
    });
  }

  addSimultaneous<T, V>(
    transforms: Array<Transform<T, V>>,
    duration = 2000,
    time?: number
  ) {
    if (time === undefined) {
      time = this.duration;
    }
    transforms.forEach((transform) => {
      this.add(transform, duration, time);
    });
  }

  addSequential<T, V>(
    transforms: Array<Transform<T, V>>,
    duration = 2000,
    time?: number
  ) {
    transforms.forEach((transform) => {
      const startTime = time === undefined ? this.duration : time;
      this.add(transform, duration, startTime + duration);
    });
  }

  get duration(): number {
    return this.transforms.reduce<number>((duration, slice) => {
      const sum = slice.time + slice.duration;
      return sum > duration ? sum : duration;
    }, 0);
  }
}

/* One per Attribute (Quaternion, Vector, Alpha),
 time is alpha in range 0 <= alpha < 1 */
export class Transform<T, V> implements TransformType<T, V> {
  target: T;
  values: Array<V>;
  interpolation: (alpha: number, target: T, from: V, to: V) => void;

  constructor(
    target: T,
    values: Array<V>,
    interpolation: (alpha: number, target: T, from: V, to: V) => void
  ) {
    this.target = target;
    this.values = values;
    this.interpolation = interpolation;
  }

  _calculateIndexAndRelativeAlpha(alpha: number): {
    index: number;
    alpha: number;
  } {
    alpha = Math.max(0, Math.min(alpha, 1));
    const index = Math.floor(alpha * (this.values.length - 1));
    return {
      index: index,
      alpha: (this.values.length - 1) * alpha - index,
    };
  }

  update(alpha: number) {
    const { index, alpha: relativeAlpha } =
      this._calculateIndexAndRelativeAlpha(alpha);
    const easedAlpha = Easing.Cubic.InOut(relativeAlpha);
    this.interpolation(
      easedAlpha,
      this.target,
      this.values[index],
      this.values[index + 1 >= this.values.length ? index : index + 1]
    );
  }
}
