import { OrthographicCamera, Projection, StepCameraSettingsDto } from '@assemblio/shared/next-types';
import { Transform, Vector } from '@assemblio/type/3d';
import { QueryClient } from '@tanstack/react-query';
import { Box3 } from 'three';
import { ActorRefFrom, createActor } from 'xstate';
import { create } from 'zustand';
import { devtools, subscribeWithSelector } from 'zustand/middleware';
import { StepIndex } from '../indexes/StepIndex';
import { editorMachine, viewerMachine } from '../machines';
import { ConfigService } from '../services';
import { AspectRatio } from './types/ui-store.types';
import { PatchState } from './types/undo-redo.types';

export interface UI extends PatchState {
  selectedPartSet: Set<number>;
  highlightedStepSet: Set<string>;
  highlightedAnnotation?: string;
  view: 'editor' | 'viewer' | 'renderer';
  selectedStep?: {
    id: string;
    index: StepIndex;
  };
  expandedStepGroups: Set<string>;
  queryClient?: QueryClient;
  selectionBounds: Box3;
  expandedAssemblies: Set<number>;
  showStepActions: boolean;
  isDragging: boolean;
  selectedPathSegmentMap: Map<string, number>;
  cameraTransformCache?: Transform;
  cameraZoomCache?: number;
  defaultCameraSettings: OrthographicCamera;
  cameraGizmoTransform?: Transform;
  showCameraGizmo: boolean;
  showLineRenderer: boolean;
  showSidebarTextRenderer: boolean;
  cameraControlsActive: boolean;
  enableCollisionDetection: boolean;
  surfaceCollisionDetected: boolean;
  isAnimating: boolean;
  machine?: ActorRefFrom<typeof editorMachine>;
  viewportControlsActive: boolean;
  toolbarActive: boolean;
  transformGizmo: {
    show: boolean;
    position: Vector;
    action: 'rotate' | 'translate';
    refresh: boolean;
    snappingAngle: number;
  };
  sidebarWidth: {
    right: number;
    left: number;
  };
  previewRatio: AspectRatio;
  selectionActive: boolean;
  partContext?: number;
  assemblyContext?: number;
  cameraClipboard?: StepCameraSettingsDto;
  annotationAssetsLoaded: boolean;
  hierarchyFilter: {
    showExcluded: boolean;
    showDisassembled: boolean;
  };
  reset: () => void;
}

const init = () => ({
  patchState: new Map(),
  selectedParts: [],
  selectedPartSet: new Set<number>(),
  expandedStepGroups: new Set<string>(),
  highlightedStepSet: new Set<string>(),
  selectedStep: undefined,
  selectionBounds: new Box3(),
  expandedAssemblies: new Set<number>(),
  isDragging: false,
  selectedPathSegmentMap: new Map<string, number>(),
  surfaceCollisionDetected: false,
  selectionActive: true,
  modelContext: undefined,
  assemblyContext: undefined,
  annotationAssetsLoaded: false,
  cameraSettingsClipboard: undefined,
});

export const getActiveMachine = () => {
  const application = ConfigService.getApplicationType();
  const actor = application === 'editor' ? createActor(editorMachine) : createActor(viewerMachine);
  return actor;
};

export const useUIStore = create<UI>()(
  subscribeWithSelector(
    devtools(
      (set) =>
        ({
          ...init(),
          currentVersion: 0,
          view: ConfigService.getApplicationType(),
          showStepActions: false,
          isDragging: false,
          enableCollisionDetection: true,
          defaultCameraSettings: {
            near: 0.001,
            far: 100,
            projection: Projection.ORTHOGRAPHIC,
            zoom: 1,
            distance: 1,
            transform: {
              position: { x: 1.52, y: 1.52, z: 1.54 },
              rotation: { x: -0.28, y: 0.36, z: 0.11, w: 0.89 },
            },
          },
          transformGizmo: {
            show: false,
            position: { x: 0, y: 0, z: 0 },
            action: 'translate',
            refresh: false,
            snappingAngle: 5,
          },
          cameraGizmoTransform: undefined,
          sidebarWidth: {
            right: 300,
            left: 300,
          },
          showCameraGizmo: false,
          machine: getActiveMachine(),
          showLineRenderer: false,
          showSidebarTextRenderer: true,
          cameraControlsActive: true,
          isAnimating: false,
          viewportControlsActive: true,
          toolbarActive: false,
          previewRatio: 'auto',
          selectionActive: true,
          hierarchyFilter: {
            showExcluded: false,
            showDisassembled: true,
          },
          reset: () => {
            set(init());
          },
        }) as UI,
      {
        name: 'UI Store',
        stateSanitizer: <UI>(state: UI) => {
          return {
            ...state,
            machine: undefined,
            selectionBounds: undefined,
            patchState: undefined,
            queryClient: undefined,
            cameraClipboard: undefined,
          };
        },
      }
    )
  )
);
