import { createStep } from '@assemblio/frontend/data-access';
import {
  MachineController,
  StepController,
  useCanvasStore,
} from '@assemblio/frontend/stores';
import { notifications } from '@mantine/notifications';
import { OrthographicCamera } from 'three';

export const useCreateEmptyStep = () => {
  const createEmptyStep = (
    stepGroupId: string,
    camera?: OrthographicCamera | null
  ) => {
    if (!camera) {
      camera = useCanvasStore.getState().camera as OrthographicCamera;
    }

    if (camera) {
      const newStep = StepController.createEmptyStep(camera, stepGroupId);
      const addedStep = StepController.addStep(newStep);

      if (addedStep) {
        // This is a work around
        // We need to think about saving the part id in the step.parts directly
        const stepDto = StepController.stepToDto(addedStep);

        createStep(stepDto).catch((error) => {
          // Todo Add User feedback
          StepController.removeStep(addedStep.id);
          notifications.show({
            id: 'create-empty-step-error',
            message: 'Could not create empty step',
            color: 'red',
          });
        });
      }

      MachineController.selectStep(newStep);
    }
  };

  return { createEmptyStep };
};
