import { useExcludePart, useIncludePart } from '@assemblio/frontend/data-access';
import { ModelController, useSequenceStore } from '@assemblio/frontend/stores';
import { Button } from '@mantine/core';
import { notifications } from '@mantine/notifications';

interface AssemblyExIncludeButtonProps {
  assemblyContext: number;
}

export const AssemblyExIncludeButton = ({ assemblyContext }: AssemblyExIncludeButtonProps) => {
  const isAssemblyExcluded = ModelController.isAssemblyExcluded(assemblyContext);
  const partExcludeMutation = useExcludePart();
  const partIncludeMutation = useIncludePart();

  const setAssemblyExcludedProperty = (exclude: boolean) => {
    const { status, excludedIndices } = ModelController.setAssemblyExcluded(assemblyContext, exclude);
    const currentSequenceId = useSequenceStore.getState().selectedSequenceId;

    if (status !== 'nothing') {
      const catchFunction = () => {
        notifications.show({
          id: 'exclude-assembly',
          message: "Couldn't change excluded state of parts",
          color: 'red',
        });

        ModelController.setPartsExcluded(excludedIndices, !exclude);
      };

      if (exclude) {
        partExcludeMutation.mutate(
          { id: currentSequenceId, data: { gltfIndices: excludedIndices } },
          {
            onSuccess: () => showSuccessNotification(exclude, status),
            onError: () => catchFunction(),
          }
        );
      } else {
        partIncludeMutation.mutate(
          { id: currentSequenceId, data: { gltfIndices: excludedIndices } },
          {
            onSuccess: () => showSuccessNotification(exclude, status),
            onError: () => catchFunction(),
          }
        );
      }
    } else {
      notifications.show({
        id: 'exclude-assembly',
        message: "Couldn't change excluded state of parts. They may be already used in a step",
        color: 'red',
      });
    }
  };

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      <Button
        variant="default"
        onClick={() => {
          setAssemblyExcludedProperty(false);
        }}
        style={{ marginBottom: '5px' }}
        disabled={isAssemblyExcluded === 'nothing'}
      >
        Include
      </Button>
      <Button
        variant="default"
        onClick={() => {
          setAssemblyExcludedProperty(true);
        }}
        disabled={isAssemblyExcluded === 'complete'}
      >
        Exclude
      </Button>
    </>
  );
};

const showSuccessNotification = (exclude: boolean, status: ModelController.AssemblyExclusionResultStatus) => {
  notifications.show({
    id: 'exclude-assembly',
    message: `${exclude ? 'Excluded' : 'Included'} ${status} Assembly`,
    color: status === 'complete' ? 'green' : 'yellow',
  });
};
