import { FeatureFlag } from '@assemblio/type/feature-flag';
import { useFeatureFlagsQuery } from '@assemblio/frontend/data-access';

export const useHasFeatureFlag = (feature: FeatureFlag) => {
  const { data: featureFlags } = useFeatureFlagsQuery();

  if (featureFlags === undefined) return false;

  return featureFlags.features.includes(feature);
};
