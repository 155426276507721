import React from 'react';
import classes from './ExplorerCard.module.scss';

type HeaderProps = {
  children: React.ReactNode;
};

export const Header = ({ children, ...props }: HeaderProps) => (
  <div className={classes.explorerCard__header} {...props}>
    {children}
  </div>
);
Header.displayName = 'Header';
