import { useState } from 'react';
import { useTenantUsersQuery } from '@assemblio/frontend/data-access';
import { InstructionState } from '@assemblio/type/instruction';
import { FilterOption } from '../../../types';
import { IconUsers } from '@assemblio/design-system';
import { IconFileFilled } from '@tabler/icons-react';

interface Return {
  filterProps: FilterOption[];
}

const FILTERABLE_INSTRUCTION_STATES: InstructionState[] = [
  InstructionState.APPROVED,
  InstructionState.DRAFT,
  InstructionState.IN_REVIEW,
  InstructionState.REQUEST_CHANGE,
];

export const useProductFilters = (): Return => {
  const [enableOwnerQuery, setEnableOwnerQuery] = useState(false);

  //TODO: Add filters for folderId, projectId once the dedicated endpoints are ready.
  // folderId and projectId filters should only be available when the user is not in the assembly-plans section.

  const { data: userData, isLoading: userQueryIsLoading } = useTenantUsersQuery(
    {
      exclude: 'viewer',
    },
    enableOwnerQuery
  );

  const getUserData = () => {
    if (userQueryIsLoading) return undefined;
    return userData?.items.map((user) => {
      return {
        label: user.fullName,
        value: user.id,
      };
    });
  };

  return {
    filterProps: [
      {
        label: 'Owner',
        value: 'owner',
        options: getUserData(),
        Icon: IconUsers,
        onToggleOption: (active: boolean) => {
          setEnableOwnerQuery(active);
        },
      },
      {
        label: 'State',
        value: 'state',
        Icon: IconFileFilled,
        options: Object.entries(FILTERABLE_INSTRUCTION_STATES).map(([_, value]) => {
          return {
            label: value,
            value: value,
          };
        }),
        onToggleOption: () => null,
      },
    ],
  };
};
