import { AnimationController, UIController } from '../controller';
import { useAnimationStore, useUIStore } from '../stores';

export const playAnimation = () => {
  const selectedStep = useUIStore.getState().selectedStep;
  const loop = useAnimationStore.getState().loop;
  AnimationController.play(loop, selectedStep?.id);
};

export const pauseAnimation = () => {
  AnimationController.pause();
};

export const stopAnimation = () => {
  AnimationController.stop();
};

export const resumeAnimation = () => {
  AnimationController.resume();
};

export const nextClip = () => {
  AnimationController.next();
};

export const previousClip = () => {
  AnimationController.previous();
};

export const skipForward = () => {
  AnimationController.next();
};

export const skipBackward = () => {
  AnimationController.previous();
};

export const skipToEndOfClip = () => {
  AnimationController.skipToEndOfClip();
};

export const createAnimations = () => {
  AnimationController.createAnimations();
};

export const unhighlightAllSteps = () => {
  UIController.unhighlightAllSteps();
};

export const cacheCameraTransform = () => {
  UIController.cacheCameraTransform();
};

export const restoreCameraTransform = () => {
  UIController.restoreCameraTransform();
};

export const activateIsAnimating = () => {
  AnimationController.setIsAnimating(true);
};

export const deactivateIsAnimating = () => {
  AnimationController.setIsAnimating(false);
};
