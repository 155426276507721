import React from 'react';
import { Flex, FlexProps } from '@mantine/core';
import classes from './ExplorerCard.module.scss';

interface BodyProps extends FlexProps {
  children: React.ReactNode;
}

export const Body = ({ children }: BodyProps) => (
  <Flex
    direction={'column'}
    justify={'space-between'}
    className={classes.explorerCard__content}
  >
    {children}
  </Flex>
);
Body.displayName = 'Body';
