import { AnnotationDtoWithId, AnnotationMetaDto, AnnotationResponseDto } from '@assemblio/shared/next-types';
import produce, { produceWithPatches } from 'immer';
import _ from 'lodash';
import { StepController, UndoRedoController } from '.';
import {
  AnnotationMode,
  AnnotationState,
  AnnotationStore,
  SequenceStore,
  useAnnotationStore,
  useSequenceStore,
  useUIStore,
} from '../stores';
import { UIController } from './UIController';

export namespace AnnotationController {
  export const init = (annotations: Array<AnnotationResponseDto>) => {
    annotations.forEach((annotation) => {
      useAnnotationStore.setState(
        produce<AnnotationStore>((state) => {
          state.annotationMap.set(annotation.id, {
            visible: false,
            mode: 'display',
            data: annotation,
            highlight: false,
          });
        })
      );
    });
  };

  export const getAnnotationById = (annotationId: string) => {
    return useAnnotationStore.getState().annotationMap.get(annotationId);
  };

  export const isAnyAnnotationSelected = (): boolean => {
    const annotationMap = useAnnotationStore.getState().annotationMap;

    return [...annotationMap.values()].some((annotation) => annotation.mode === 'editing');
  };

  export const getEditingAnnotation = (): AnnotationState | undefined => {
    const annotationMap = useAnnotationStore.getState().annotationMap;

    return [...annotationMap.values()].find((annotation) => annotation.mode === 'editing');
  };

  export const addAnnotation = (data: AnnotationDtoWithId) => {
    useAnnotationStore.setState(
      produce<AnnotationStore>((state) => {
        state.annotationMap.set(data.id, {
          visible: true,
          mode: 'display',
          highlight: false,
          data,
        });
      })
    );
  };

  export const removeAnnotation = (annotationId: string) => {
    useAnnotationStore.setState(
      produce<AnnotationStore>((state) => {
        state.annotationMap.delete(annotationId);
      })
    );
  };

  const setMode = (annotationId: string, mode: AnnotationMode) => {
    useAnnotationStore.setState(
      produce<AnnotationStore>((state) => {
        const annotationState = state.annotationMap.get(annotationId);
        if (annotationState) {
          annotationState.mode = mode;
        }
      })
    );
  };

  export const setHighlight = (annotationId: string, highlight: boolean) => {
    useAnnotationStore.setState(
      produce<AnnotationStore>((state) => {
        const annotationState = state.annotationMap.get(annotationId);
        if (annotationState) {
          annotationState.highlight = highlight;
        }
      })
    );
    UIController.setHighlightedAnnotation(highlight ? annotationId : undefined);
  };

  export const setVisibility = (annotationId: string, visible: boolean) => {
    useAnnotationStore.setState(
      produce<AnnotationStore>((state) => {
        const annotationState = state.annotationMap.get(annotationId);
        if (annotationState) {
          annotationState.visible = visible;
        }
      })
    );
  };

  export const hideAnnotations = (except?: Array<string>) => {
    useAnnotationStore.setState(
      produce<AnnotationStore>((state) => {
        state.annotationMap.forEach((annotationState) => {
          if (!except || !except.includes(annotationState.data.id)) {
            annotationState.visible = false;
            annotationState.highlight = false;
          }
        });
      })
    );
  };

  export const unhighlightAllAnnotations = () => {
    useAnnotationStore.setState(
      produce<AnnotationStore>((state) => {
        state.annotationMap.forEach((annotationState) => {
          annotationState.highlight = false;
        });
      })
    );
  };

  export const editAnnotation = (annotationId: string) => {
    stopEditing();
    setVisibility(annotationId, true);
    setMode(annotationId, 'editing');
    useAnnotationStore.setState(
      produce<AnnotationStore>((state) => {
        state.editedAnnotation = annotationId;
      })
    );
  };

  export const stopEditing = () => {
    const editedAnnotation = useAnnotationStore.getState().editedAnnotation;
    if (editedAnnotation) {
      setMode(editedAnnotation, 'display');
      useAnnotationStore.setState(
        produce<AnnotationStore>((state) => {
          state.editedAnnotation = undefined;
        })
      );
      const selectedStep = useUIStore.getState().selectedStep;
      if (selectedStep && isAnnotationInStep(editedAnnotation, selectedStep.id)) {
        setVisibility(editedAnnotation, true);
      } else {
        setVisibility(editedAnnotation, false);
      }
    }
  };

  export const isEditing = () => {
    return useAnnotationStore.getState().editedAnnotation !== undefined;
  };

  export const setHovered = (id?: string) => {
    useAnnotationStore.setState(
      produce<AnnotationStore>((state) => {
        state.hoveredAnnotation = id;
      })
    );
  };

  export const updateAnnotation = (annotationId: string, data: Partial<AnnotationMetaDto>): boolean => {
    let wasUpdated = false;
    useAnnotationStore.setState(
      produce<AnnotationStore>((state: AnnotationStore) => {
        const annotation = state.annotationMap.get(annotationId);
        if (annotation) {
          _.merge(annotation.data, { meta: data });
          wasUpdated = true;
        }
      })
    );
    return wasUpdated;
  };

  export const showAnnotationsInStep = (stepId: string, show = true) => {
    const stepAnnotationMap = useAnnotationStore.getState().stepAnnotationMap;
    const annotations = stepAnnotationMap.get(stepId);
    annotations?.forEach((annotationId) => {
      setVisibility(annotationId, show);
    });
  };

  export const getAnnotationIdsForStep = (stepId: string) => {
    const stepAnnotationMap = useAnnotationStore.getState().stepAnnotationMap;
    const annotations = stepAnnotationMap.get(stepId);
    if (annotations) {
      return Array.from(annotations);
    }
    return [];
  };

  export const getAnnotationsForStep = (stepId: string) => {
    return getAnnotationIdsForStep(stepId).map((annotationId) => {
      getAnnotationById(annotationId);
    });
  };

  export const hideAnnotationNotInSteps = (stepIds: Array<string>) => {
    const annotationIds = stepIds.flatMap((stepId) => getAnnotationIdsForStep(stepId));
    hideAnnotations(annotationIds);
  };

  export const associateStepAndAnnotation = (annotationId: string, stepId: string) => {
    useAnnotationStore.setState(
      produce<AnnotationStore>((state: AnnotationStore) => {
        if (!state.stepAnnotationMap.has(stepId)) {
          state.stepAnnotationMap.set(stepId, new Set<string>());
        }
        state.stepAnnotationMap.get(stepId)?.add(annotationId);
        if (!state.annotationStepMap.has(annotationId)) {
          state.annotationStepMap.set(annotationId, new Set<string>());
        }
        state.annotationStepMap.get(annotationId)?.add(stepId);
      })
    );
  };

  export const disassociateStepAndAnnotation = (annotationId: string, stepId: string) => {
    useAnnotationStore.setState(
      produce<AnnotationStore>((state: AnnotationStore) => {
        state.stepAnnotationMap.get(stepId)?.delete(annotationId);
        state.annotationStepMap.get(annotationId)?.delete(stepId);
      })
    );
  };

  export const disassociateAnnotationFromAllSteps = (annotationId: string): string[] => {
    let stepIdsArray: string[] = [];
    useAnnotationStore.setState(
      produce<AnnotationStore>((state: AnnotationStore) => {
        const stepIds = state.annotationStepMap.get(annotationId) || new Set<string>();
        stepIds.forEach((stepId) => {
          state.stepAnnotationMap.get(stepId)?.delete(annotationId);
          state.annotationStepMap.get(annotationId)?.delete(stepId);
        });
        stepIdsArray = Array.from(stepIds);
      })
    );

    return stepIdsArray;
  };

  /*
   * In how many steps is a given annotation
   */
  export const getAnnotationStepCount = (annotationId: string) => {
    return useAnnotationStore.getState().annotationStepMap.get(annotationId)?.size;
  };

  export const reset = () => {
    useAnnotationStore.getState().reset();
  };

  const isAnnotationInStep = (annotationId: string, stepId: string) => {
    return useAnnotationStore.getState().annotationStepMap.get(annotationId)?.has(stepId) === true;
  };

  export const addAnnotationToSelectedStep = (annotationId: string) => {
    const stepUuid = StepController.getSelectedStepId();
    if (stepUuid) {
      addAnnotationToStep(stepUuid, annotationId);
    }
  };

  export const addAnnotationToStep = (stepId: string, annotationId: string) => {
    const { stepGroupIndex, stepIndex } = StepController.getStepDisassemblyOrderById(stepId);
    let stepName = '';
    const [nextState, patches, inversePatches] = produceWithPatches(
      useSequenceStore.getState(),
      (state: SequenceStore) => {
        const step = state.stepGroups[stepGroupIndex].steps[stepIndex];
        step.annotations.push(annotationId);
        stepName = step.name;
      }
    );
    AnnotationController.associateStepAndAnnotation(annotationId, stepId);
    useSequenceStore.setState(nextState);
    UndoRedoController.createPatchAndVersion(
      useSequenceStore,
      `Added annotation to step:${stepName}`,
      { type: 'no-action-needed' },
      nextState,
      patches,
      inversePatches
    );
  };

  export const removeAnnotationFromStep = (stepId: string, annotationId: string) => {
    const { stepGroupIndex, stepIndex } = StepController.getStepDisassemblyOrderById(stepId);
    let stepName = '';
    const [nextState, patches, inversePatches] = produceWithPatches(
      useSequenceStore.getState(),
      (state: SequenceStore) => {
        const step = state.stepGroups[stepGroupIndex].steps[stepIndex];
        step.annotations.splice(step.annotations.indexOf(annotationId), 1);
        stepName = step.name;
      }
    );
    AnnotationController.disassociateStepAndAnnotation(annotationId, stepId);
    useSequenceStore.setState(nextState);
    UndoRedoController.createPatchAndVersion(
      useSequenceStore,
      `Added annotation to step:${stepName}`,
      { type: 'no-action-needed' },
      nextState,
      patches,
      inversePatches
    );
  };

  export const removeAnnotationFromSteps = (stepIds: string[], annotationId: string) => {
    if (stepIds.length > 0) {
      useSequenceStore.setState(
        produce<SequenceStore>((state: SequenceStore) => {
          stepIds.forEach((stepId) => {
            const { stepGroupIndex, stepIndex } = StepController.getStepDisassemblyOrderById(stepId);
            const step = state.stepGroups[stepGroupIndex].steps[stepIndex];
            step.annotations.splice(step.annotations.indexOf(annotationId), 1);
          });
        })
      );
    }
  };
}
