import { axiosSharedClient } from '@assemblio/frontend/data-access';
import { useUIStore } from '@assemblio/frontend/stores';
import { useEffect, useState } from 'react';

export const useObjectUrl = (src: string) => {
  const [objectUrl, setObjectUrl] = useState<string | null>(null);

  useEffect(() => {
    let dataUrl: string | undefined;
    const view = useUIStore.getState().view;

    const fetchImage = async (src: string) => {
      axiosSharedClient(view, {
        url: src,
        responseType: 'blob',
      })
        .then((response) => response.data)
        .then((data) => {
          dataUrl = URL.createObjectURL(data);
          setObjectUrl(dataUrl);
        })
        .catch((error) => {
          console.error('Image not found');
        });
    };

    fetchImage(src);

    return () => {
      if (dataUrl) {
        URL.revokeObjectURL(dataUrl);
      }
    };
  }, [src]);

  return objectUrl;
};
