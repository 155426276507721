import { Text, Group, ThemeIcon } from '@mantine/core';
import { IconExclamationCircle } from '@tabler/icons-react';

interface RadioItemWarningProps {
  reason: string | undefined;
}

export const RadioItemWarning = ({ reason }: RadioItemWarningProps) => {
  return (
    <Group gap={0} w={'40%'}>
      <ThemeIcon variant={'transparent'} size={'xs'}>
        <IconExclamationCircle />
      </ThemeIcon>
      <Text c={'dimmed'} size={'xs'}>
        &nbsp;{reason}
      </Text>
    </Group>
  );
};
