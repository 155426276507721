export enum InstructionState {
  UPLOADING = 'Uploading',
  INITIALIZING = 'Initializing',
  INITIALIZING_FAILED = 'Initializing Failed',
  PROCESSING = 'Processing',
  PROCESSING_FAILED = 'Processing Failed',
  DRAFT = 'Draft',
  IN_REVIEW = 'In Review',
  APPROVED = 'Approved',
  REQUEST_CHANGE = 'Request Change',
}
