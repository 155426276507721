import { Step } from '@assemblio/shared/next-types';
import { ThreeTransform } from '@assemblio/type/3d';
import { Camera } from '@react-three/fiber';
import _ from 'lodash';
import { asArray } from '../helper/array.transform';
import { SelectionMode, useSettingsStore, useUIStore } from '../stores';
import { ProjectController } from './ProjectController';
import { UIController } from './UIController';
import { Matrix4 } from 'three';

export namespace MachineController {
  export const selectTransition = (partOrAssemblyGltfIndex: number, assembly = false) => {
    const selectedParts = useUIStore.getState().selectedPartSet;
    const selectionMode = useSettingsStore.getState().selectionMode;
    const ids = [partOrAssemblyGltfIndex];
    let parts = assembly ? [] : [partOrAssemblyGltfIndex];
    if (assembly) {
      parts = parts.concat(_.flatMap(ids, (assembly) => ProjectController.getAssemblyPartIndex(assembly)));
    }

    if (selectionMode === SelectionMode.ADD) {
      const includesAll = parts.every((part) => selectedParts.has(part));
      if (includesAll) {
        deselectParts(parts);
      } else {
        selectParts(parts);
      }
    } else if (selectionMode === SelectionMode.REPLACE) {
      selectParts(parts);
    }
  };

  export const getActiveMachine = () => {
    return useUIStore.getState().machine;
  };

  export const selectStep = (step: Step) => {
    const machine = getActiveMachine();
    machine?.send({ type: 'SELECT_STEP', stepId: step.id });
  };

  export const changeStepParts = (step: Step) => {
    const machine = getActiveMachine();
    machine?.send({ type: 'CHANGE_STEP_PARTS' });
  };

  export const deselect = () => {
    const machine = getActiveMachine();
    machine?.send({ type: 'DESELECT' });
  };

  export const deleteStep = () => {
    const machine = getActiveMachine();
    machine?.send({ type: 'DELETE_STEP' });
  };

  const selectParts = (gltfIndexes: number | number[]) => {
    const machine = getActiveMachine();
    machine?.send({
      type: 'SELECT_PARTS',
      parts: asArray(gltfIndexes).filter((gltfIndex) => UIController.isSelectable(gltfIndex)),
    });
  };

  export const deselectParts = (partIds: number | number[]) => {
    const machine = getActiveMachine();
    machine?.send({
      type: 'DESELECT_PARTS',
      parts: asArray(partIds),
    });
  };

  export const appendStep = (transform: ThreeTransform, camera: Camera) => {
    const machine = getActiveMachine();
    machine?.send({ type: 'APPEND_STEP', transform, camera });
  };

  export const createStep = (offsets: Map<number, Matrix4>, transforms: Array<ThreeTransform>, camera: Camera) => {
    const machine = getActiveMachine();
    machine?.send({ type: 'CREATE_STEP', offsets, transforms, camera });
  };

  export const playAnimation = () => {
    const machine = getActiveMachine();
    machine?.send({ type: 'PLAY' });
  };

  export const advanceAnimation = () => {
    const machine = getActiveMachine();
    machine?.send({ type: 'ADVANCE' });
  };

  export const stopAnimation = () => {
    const machine = getActiveMachine();
    machine?.send({ type: 'STOP' });
  };

  export const pauseAnimation = () => {
    const machine = getActiveMachine();
    machine?.send({ type: 'PAUSE' });
  };

  export const skipForward = () => {
    const machine = getActiveMachine();
    machine?.send({ type: 'SKIP_FORWARD' });
  };

  export const skipBackward = () => {
    const machine = getActiveMachine();
    machine?.send({ type: 'SKIP_BACKWARD' });
  };

  export const playOrPauseAnimation = () => {
    const machine = getActiveMachine();
    const isPlaying = _.isEqual(machine?.getSnapshot().value, {
      animating: 'playing',
    });

    if (!isPlaying) {
      playAnimation();
    } else {
      pauseAnimation();
    }
  };

  export const reset = () => {
    const machine = getActiveMachine();
    machine?.send({ type: 'RESET' });
  };
}
