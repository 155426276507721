import { useCallback, useState } from 'react';
import { ConfirmationDialog } from './ConfirmationDialog';

export const useConfirmationDialog = (
  prompt: string,
  onConfirm: () => void
) => {
  const [opened, setOpened] = useState(false);

  const handleConfirm = useCallback(
    (confirmed: boolean) => {
      if (confirmed) {
        onConfirm();
      }
      setOpened(false);
    },
    [onConfirm]
  );

  const RenderConfirmationDialog = () => (
    <ConfirmationDialog
      prompt={prompt}
      opened={opened}
      onClose={handleConfirm}
    />
  );

  return {
    openDialog: () => setOpened(true),
    RenderConfirmationDialog,
  };
};
