import { AnnotationController } from '@assemblio/frontend/stores';
import _ from 'lodash';
import { RefObject, useEffect } from 'react';
import { Vector3 } from 'three';
import {
  cndcToNdc,
  cndcToSvg,
  documentToSvg,
  ndcToDocument,
  ndsToDocumentSize,
} from '../CoordinateSystems';
import { useScreenPosition } from '../hooks';
import { Anchor, SizeableAnnotation } from '@assemblio/shared/next-types';

const getAnchorEffect = (
  anchor: Anchor,
  target: RefObject<SVGGraphicsElement>,
  defaultPosition: { x: number; y: number },
  onChange: ({ x, y }: { x: number; y: number }) => void
) => {
  switch (anchor.type) {
    case 'annotation':
      return () => {
        if (
          anchor.annotationId &&
          target.current &&
          target.current.ownerSVGElement
        ) {
          const rect = target.current.ownerSVGElement.getBoundingClientRect();
          const targetAnnotationState = AnnotationController.getAnnotationById(
            anchor.annotationId
          );
          if (!targetAnnotationState || !targetAnnotationState.data) return;
          const targetAnnotation = targetAnnotationState.data
            .meta as SizeableAnnotation;
          const targetPosition = cndcToSvg(
            defaultPosition,
            target.current.ownerSVGElement
          );
          const documentPosition = ndcToDocument(
            cndcToNdc({ x: targetAnnotation.x, y: targetAnnotation.y }),
            target.current.ownerSVGElement
          );
          const svgPosition = documentToSvg(
            {
              x: documentPosition.x,
              y: documentPosition.y,
            },
            target.current.ownerSVGElement
          );
          const documentSize = ndsToDocumentSize(
            {
              x: targetAnnotation.size.width,
              y: targetAnnotation.size.height,
            },
            target.current.ownerSVGElement
          );

          const svgSize = {
            x: documentSize.x / (rect.height / 2),
            y: documentSize.y / (rect.height / 2),
          };
          let pos = { x: 0, y: 0 };
          if (svgPosition.x > targetPosition.x) {
            pos = {
              x: svgPosition.x,
              y: svgPosition.y + svgSize.y / 2,
            };
          } else {
            if (svgPosition.x + svgSize.x < targetPosition.x) {
              pos = {
                x: svgPosition.x + svgSize.x,
                y: svgPosition.y + svgSize.y / 2,
              };
            } else {
              if (svgPosition.y > targetPosition.y) {
                pos = {
                  x: svgPosition.x + svgSize.x / 2,
                  y: svgPosition.y,
                };
              } else {
                pos = {
                  x: svgPosition.x + svgSize.x / 2,
                  y: svgPosition.y + svgSize.y,
                };
              }
            }
          }
          onChange(pos);
        }
      };
    case '3d':
      return _.noop;
    default:
      return () => {
        if (target.current && target.current.ownerSVGElement) {
          const position = cndcToSvg(
            defaultPosition,
            target.current.ownerSVGElement
          );
          onChange(position);
        }
      };
  }
};

export const useAnchor = (
  anchor: Anchor,
  target: RefObject<SVGGraphicsElement>,
  defaultPosition: { x: number; y: number },
  onChange: ({ x, y }: { x: number; y: number }) => void
) => {
  useEffect(() => getAnchorEffect(anchor, target, defaultPosition, onChange)());
  useScreenPosition(
    anchor.position
      ? new Vector3(anchor.position.x, anchor.position.y, anchor.position.z)
      : null,
    target.current &&
      target.current.ownerSVGElement &&
      target.current.ownerSVGElement.parentElement
      ? target.current.ownerSVGElement.parentElement.getBoundingClientRect()
      : { left: 0, right: 0, width: 0, height: 0, top: 0, bottom: 0 },
    (screen) => {
      if (target.current && target.current.ownerSVGElement) {
        const pos = documentToSvg(
          { x: screen.x, y: screen.y },
          target.current.ownerSVGElement
        );
        onChange(pos);
      }
    }
  );
};
