export enum VideoExportTypes {
  MP4 = 'mp4',
  AVI = 'avi',
}

export enum CodecEnum {
  AVI = 'avi',
  MJPEG = 'mjpeg',
}

export enum ResolutionEnum {
  '1920x1080' = '1920x1080', 
  '1366x768' = '1366x768',
  '1280x720' = '1280x720',
  '1024x768' = '1024x768',
  '800x600' = '800x600',
  '320x256' = '320x256',
}

export enum DocumentVersion {
  V1 = 'V1',
  V2 = 'V2',
}
