import {
  PartInformation,
  UIController,
  useModelStore,
  useUIStore,
} from '@assemblio/frontend/stores';
import { Part } from '@assemblio/type/input';
import { useEffect, useState } from 'react';

const useLeafSubscription = (
  part: Part
): {
  selected: boolean;
  partInformation?: PartInformation;
  elementId: string;
} => {
  const [selected, setSelected] = useState(
    UIController.isPartSelected(part.gltfIndex)
  );
  const [partInformation, setPartInformation] = useState<
    PartInformation | undefined
  >(
    () =>
      useModelStore
        .getState()
        .modelInformationMap.get(part.gltfIndex) as PartInformation
  );

  useEffect(() => {
    const unsubPartInformation = useModelStore.subscribe(
      (state) => {
        return state.modelInformationMap.get(part.gltfIndex) as PartInformation;
      },
      (partInformation: PartInformation | undefined) => {
        setPartInformation(partInformation);
      }
    );

    const unsubSelected = useUIStore.subscribe(
      (state) => state.selectedPartSet,
      () => {
        setSelected(UIController.isPartSelected(part.gltfIndex));
      }
    );

    return () => {
      unsubSelected();
      unsubPartInformation();
    };
  }, [part]);

  return {
    selected,
    partInformation,
    elementId: part.id,
  };
};

export default useLeafSubscription;
