import 'react-resizable/css/styles.css';
import { RectAnnotation } from './RectAnnotation';
import { ShapeAnnotation } from '@assemblio/shared/next-types';

interface Props {
  annotationId: string;
  annotation: ShapeAnnotation;
}

export const EllipseAnnotation = ({ annotation, annotationId }: Props) => {
  return (
    <RectAnnotation
      borderRadius="100%"
      annotation={annotation}
      annotationId={annotationId}
    />
  );
};
