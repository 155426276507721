import { Box, Checkbox, Divider, Group, ScrollArea, Text } from '@mantine/core';
import { IconArrowNarrowRight } from '@tabler/icons-react';
import classes from './CSVModal.module.scss';
import { useCheckboxState } from './CheckboxState';

type PartAssemblyWithName = {
  gltfIndex: number;
  currentName: string;
  newName: string;
};

interface CSVListProps {
  data: PartAssemblyWithName[];
  header: string[] | undefined;
  selectedValues: string[];
  setSelectedValues: React.Dispatch<React.SetStateAction<string[]>>;
  selectableValues: string[];
}

export const CSVList = ({
  data,
  header,
  selectedValues,
  setSelectedValues,
  selectableValues,
}: CSVListProps) => {
  const { controlChecked, toggleAllCheckBox, handleCheckboxChange } =
    useCheckboxState(
      selectableValues,
      selectedValues.length,
      setSelectedValues
    );

  if (data.length <= 0) return null;

  const headerDisplay = (
    <Group>
      <Checkbox
        data-cy="checkobox"
        size="xs"
        onChange={() => toggleAllCheckBox()}
        checked={controlChecked}
        indeterminate={
          selectedValues.length !== 0 &&
          selectedValues.length < selectableValues.length
        }
        disabled={selectableValues.length <= 0}
      />
      <Text
        size={'12px'}
        className={classes.listTextLeft}
        data-cy="current-name"
      >
        {header ? header[0] : 'Current name'}
      </Text>
      <span style={{ whiteSpace: 'pre' }} data-cy="icon-arrow-narrow-right">
        <IconArrowNarrowRight size={18} strokeWidth={2} />
      </span>
      <Text size={'12px'} data-cy="new-name">
        {header ? header[1] : 'New name'}
      </Text>
    </Group>
  );

  const list = data.map((element, index) => {
    const invalid = element.newName.length < 1 || element.newName.length > 255;
    return (
      <Group mb={'5px'} key={`csv-list-item-${index}`}>
        <Checkbox
          value={element.gltfIndex.toString()}
          disabled={invalid}
          data-cy="checkbox"
        />
        <Text
          size={'xs'}
          className={classes.listTextLeft}
          data-cy="project-current-name"
        >
          {element.currentName}
        </Text>
        <span data-cy="icon-arrow-narrow-right">
          <IconArrowNarrowRight size={18} strokeWidth={1} />
        </span>

        {invalid ? (
          <Text c="red" size={'xs'} data-cy="condition-for-new-name">
            Names must have at least 1 character and be shorter then 255
            characters
          </Text>
        ) : (
          <Text size={'xs'} data-cy="project-new-name">
            {element.newName}
          </Text>
        )}
      </Group>
    );
  });
  return (
    <Box className={classes.wrapper}>
      {headerDisplay}
      <Divider className={classes.divider} />
      <Checkbox.Group
        size="xs"
        value={selectedValues}
        onChange={handleCheckboxChange}
      >
        <ScrollArea.Autosize type="auto" mah={450} offsetScrollbars>
          {list}
        </ScrollArea.Autosize>
      </Checkbox.Group>
    </Box>
  );
};
