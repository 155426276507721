import { useMutation } from '@tanstack/react-query';
import {   SequencePartReference } from './types/sequences.mutation.types';
import {   excludePartFromSequence, includePartInSequence } from './sequence.requests';
import { notifications } from '@mantine/notifications';

export const useIncludePart = () => {
  return useMutation((partReference: SequencePartReference) => includePartInSequence(partReference), {
    onError: (_error) => {
      notifications.show({
        id: 'hide-group',
        message: 'Part could not be included',
        color: 'red',
      });
    },
  });
};

export const useExcludePart = () => {
  return useMutation((partReference: SequencePartReference) => excludePartFromSequence(partReference), {
    onError: (_error) => {
      notifications.show({
        id: 'hide-group',
        message: 'Part could not be excluded',
        color: 'red',
      });
    },
  });
};
