import { UIController, useUIStore } from '@assemblio/frontend/stores';
import { AssemblyContextMenu } from './AssemblyContextMenu';
import { PartContextMenu } from './PartContextMenu';

export const ContextMenuHandler = () => {
  const partContext = useUIStore((state) => state.partContext);
  const assemblyContext = useUIStore((state) => state.assemblyContext);

  return (
    <>
      <PartContextMenu opened={partContext !== undefined} onClose={() => UIController.setPartContext(undefined)} />
      <AssemblyContextMenu
        opened={assemblyContext !== undefined}
        onClose={() => UIController.setAssemblyContext(undefined)}
      />
    </>
  );
};
