import { useAnnotationCreate } from '@assemblio/frontend/data-access';
import {
  AnnotationController,
  ClipboardData,
  CopyPasteController,
  StepController,
  useAnnotationStore,
} from '@assemblio/frontend/stores';
import { AnnotationDtoWithId } from '@assemblio/shared/next-types';
import { showNotification } from '@mantine/notifications';
import { useEffect } from 'react';

const handleKeyDown = (
  event: KeyboardEvent,
  onPaste: (
    clipboardData: ClipboardData,
    newAnnotation: AnnotationDtoWithId,
    targetStepId: string
  ) => unknown
) => {
  const currentStepId = StepController.getSelectedStepId();

  if (!event.ctrlKey || event.repeat) return;

  if (
    document.activeElement &&
    (document.activeElement.tagName === 'INPUT' ||
      document.activeElement.tagName === 'TEXTAREA')
  ) {
    return;
  }

  const keyActions: { [key: string]: (() => void) | undefined } = {
    c: () => {
      if (!AnnotationController.isAnyAnnotationSelected()) return;

      // prevent copy of annotation when textRange is selected - clear annotation clipboard instead
      const selection = window.getSelection();
      if (selection?.type === 'Range') {
        CopyPasteController.clearClipboard();
        return;
      }

      const result = CopyPasteController.copyAnnotation();
      if (result.copied) {
        //clear browser clipboard if annotation was copied
        navigator.clipboard.writeText('');
        showNotification({
          id: 'copy-annotation-sucess',
          message: 'Copied Annotation to clipboard',
          color: 'green',
        });
      } else {
        showNotification({
          id: `copy-annotation-error`,
          message: result.errorMessage,
          color: 'red',
        });
      }
    },
    v: () => {
      const clipboard = useAnnotationStore.getState().clipboard;

      if (currentStepId && clipboard) {
        const annotationState =
          CopyPasteController.pasteAnnotation(currentStepId);
        if (annotationState?.data) {
          onPaste(clipboard, annotationState.data, currentStepId);
        }
      }
    },
  };

  const action = keyActions[event.key];
  if (action) {
    action();
  }
};

export const useCopyPasteAnnotations = () => {
  const createAnnotationMutation = useAnnotationCreate();
  const onPaste = (
    pastedAnnotation: ClipboardData,
    newAnnotation: AnnotationDtoWithId,
    targetStepId: string
  ) => {
    if (newAnnotation.id && pastedAnnotation.instructionId) {
      createAnnotationMutation.mutate(
        {
          id: pastedAnnotation.instructionId,
          data: {
            stepId: targetStepId,
            annotation: {
              ...newAnnotation,
            },
          },
        },
        {
          onSuccess: () => {
            showNotification({
              id: `pasted-annotation-success`,
              message: 'Added annotation to step',
              color: 'green',
            });
          },
          onError: () => {
            showNotification({
              id: `pasted-annotation-error`,
              message: 'Could not add annotation to step',
              color: 'red',
            });
            const stepIds =
              AnnotationController.disassociateAnnotationFromAllSteps(
                newAnnotation.id
              );
            AnnotationController.removeAnnotationFromSteps(
              stepIds,
              newAnnotation.id
            );
            AnnotationController.removeAnnotation(newAnnotation.id);
          },
        }
      );
    }
  };

  useEffect(() => {
    const keydownListener = (e: KeyboardEvent) => handleKeyDown(e, onPaste);
    document.addEventListener('keydown', keydownListener);
    return () => {
      document.removeEventListener('keydown', keydownListener);
    };
  }, []);
};
