import { useUIStore } from '@assemblio/frontend/stores';
import { useDocumentTitle as useDocumentTitleMantine } from '@mantine/hooks';

type DocumentLocation = 'selection' | 'composer' | 'exporter';

export const useDocumentTitle = (
  location: DocumentLocation,
  projectName?: string
) => {
  const { view } = useUIStore();

  let documentTitle = 'Assemblio';

  switch (location) {
    case 'selection':
      documentTitle =
        view === 'editor'
          ? 'Assembly Composer'
          : view === 'viewer'
          ? 'KIM'
          : view === 'renderer'
          ? 'Assembly Renderer'
          : 'Assemblio';
      break;
    case 'composer':
    case 'exporter': {
      documentTitle = projectName
        ? `${projectName} - Assembly Composer`
        : 'Assembly Composer';
      break;
    }
    default:
      break;
  }

  useDocumentTitleMantine(documentTitle);
};
