import { useEffect } from 'react';

const stopWheelZoom = (event: WheelEvent) => {
  if (event.ctrlKey === true) {
    event.preventDefault();
  }
};
const stopKeyZoom = (event: KeyboardEvent) => {
  if (
    event.ctrlKey &&
    [48, 61, 96, 107, 109, 187, 189].indexOf(event.keyCode) > -1
  ) {
    event.preventDefault();
  }
};

export const usePreventZoom = (): void => {
  useEffect(() => {
    document.addEventListener('keydown', stopKeyZoom);
    document.addEventListener('wheel', stopWheelZoom, { passive: false });
    return () => {
      document.removeEventListener('keydown', stopKeyZoom);
      document.removeEventListener('wheel', stopWheelZoom, false);
    };
  }, []);
};
