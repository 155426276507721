export enum JobType {
  UNDEFINED = 'undefined',
  GLTF = 'gltf',
  GLTF_PARSING = 'gltf-parsing',
  ANALYSIS_COMPARISON = 'analysis-comparison',
  EXPORT_VIDEO = 'export-video',
  EXPORT_PDF = 'pdf',
  EXPORT_DOCX = 'docx',
  EXPORT_ZIP = 'zip',
  EXPORT_MEDIA = 'media',
  EXPORT_COVER = 'export-cover',
  EXPORT_VIDEO_ARTEFACT = 'export-video-artefact',
  EXPORT_DOCUMENT_ARTEFACT = 'export-document-artefact',
  LEGACY_VIDEO = 'video',
  LEGACY_STEP_FILE_ANALYSIS = 'step-file-analysis',
}

export enum ExportJobType {
  EXPORT_VIDEO_ARTEFACT = 'export-video-artefact',
  EXPORT_DOCUMENT_ARTEFACT = 'export-document-artefact',
  EXPORT_VIDEO = 'export-video',
  EXPORT_PDF = 'pdf',
  EXPORT_DOCX = 'docx',
}

export enum JobQueues {
  CAD_FILE_PROCESSING = 'cad-file-processing',
  EXPORT_VIDEO = 'export-video',
  EXPORT_DOCUMENT = 'export-document',
  GLTF_PARSING = 'initialization',
  ANALYSIS_COMPARISON = 'analysis-comparison',
  MEDIA_EXPORT = 'media-export',
}
