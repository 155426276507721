 import { Flex, Text, Tooltip, useMantineTheme } from '@mantine/core';
import { IconDeviceSdCard, IconEdit } from '@tabler/icons-react';
import { getFormattedDateDifference } from '../../DateFormatter';
import { ArtefactDto } from '@assemblio/shared/dtos';

interface ArtefactCardSubtitleProps {
  artefact: ArtefactDto;
}
export const ArtefactCardSubtitle = ({
  artefact,
}: ArtefactCardSubtitleProps) => {
  const theme = useMantineTheme();
  const formattedSize = formatBytes(artefact.size);

  return (
    <Flex h={'1rem'} w={'65%'} maw={'250px'}>
      <Flex mr={10}>
        <Tooltip label={'Created'} openDelay={500}>
          <IconEdit color={theme.colors.gray[7]} size={'1rem'} />
        </Tooltip>
        <Text size={'xs'} c={'dimmed'} ml={5}>
          {getFormattedDateDifference(artefact.createdAt)}
        </Text>
      </Flex>
      <Flex>
        <IconDeviceSdCard color={theme.colors.gray[7]} size={'1rem'} />
        <Text size={'xs'} c={'dimmed'} ml={5}>
          {formattedSize}
        </Text>
      </Flex>
    </Flex>
  );
};

export const formatBytes = (bytes: number, decimals = 1) => {
  if (bytes === 0) return '0 Byte';

  const k = 1000;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};
