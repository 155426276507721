import {
  AnnotationController,
  ImperativeModelController,
  MachineController,
  ModelController,
  ProjectController,
  SequenceController,
  UIController,
  UndoRedoController,
  useUIStore,
} from '@assemblio/frontend/stores';
import { useNavigate } from 'react-router-dom';

export const useAppTransition = () => {
  const navigate = useNavigate();
  const target = useUIStore((state) => state.view) === 'viewer' ? '/assembler' : '/disassembler';

  const appTransition = (projectId: string, instructionId: string | null) => {
    if (instructionId) {
      // TODO: Move the resets to a controller AIO 83
      UndoRedoController.clearVersionHistory();
      ModelController.reset();
      ImperativeModelController.reset();
      AnnotationController.reset();
      //AnimationController.reset();
      UIController.reset();
      ProjectController.reset();
      SequenceController.reset();
      MachineController.reset();
      ProjectController.setProjectId(projectId);

      navigate(`${target}/${instructionId}`);
    }
  };

  return appTransition;
};
