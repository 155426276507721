import {
  useAddAnnotationToStep,
  useAnnotationCreate,
  useRemoveAnnotationFromStep,
} from '@assemblio/frontend/data-access';
import {
  AnnotationController,
  useAnnotationStore,
  useProjectStore,
} from '@assemblio/frontend/stores';
import {
  AnnotationDto,
  AnnotationDtoWithId,
  Step,
} from '@assemblio/shared/next-types';
import { AnnotationType } from '@assemblio/type/annotation';
import {
  ActionIcon,
  Group,
  Text,
  Tooltip,
  useMantineTheme,
} from '@mantine/core';
import {
  IconArrowRight,
  IconCircle,
  IconCopy,
  IconIcons,
  IconMinus,
  IconPhoto,
  IconPlus,
  IconRectangle,
} from '@tabler/icons-react';
import cx from 'clsx';
import _ from 'lodash';
import { MouseEvent } from 'react';
import { v4 as uuidv4 } from 'uuid';
import classes from './Hierarchy.module.scss';

export interface Props {
  selectedStep?: Step;
  annotation: AnnotationDtoWithId;
}
const AnnotationIconMap: { [key in AnnotationType]: JSX.Element } = {
  icon: <IconIcons />,
  image: <IconPhoto />,
  arrow: <IconArrowRight />,
  ellipse: <IconCircle />,
  rect: <IconRectangle />,
  unknown: <IconIcons />,
};

export const AnnotationTypeNames: { [key in AnnotationType]: string } = {
  icon: 'Icon',
  image: 'Image',
  arrow: 'Arrow',
  ellipse: 'Ellipse',
  rect: 'Rectangle',
  unknown: 'Unknown',
};

export const AnnotationControls = ({ selectedStep, annotation }: Props) => {
  const addAnnotationToStepMutation = useAddAnnotationToStep();
  const removeAnnotationFromStepMutation = useRemoveAnnotationFromStep();
  const annotationCreateMutation = useAnnotationCreate();

  const { mode, highlight } = useAnnotationStore((state) => {
    const annotationState = state.annotationMap.get(annotation.id);
    return (
      annotationState || { visible: true, mode: 'editing', highlight: false }
    );
  });
  const theme = useMantineTheme();
  // const { classes, theme } = useStyles({
  //   hover: highlight,
  //   selected: mode === 'editing',
  // });

  const iconColor = mode === 'editing' ? 'white' : theme.colors.gray[3];
  const stepSet = useAnnotationStore((state) =>
    state.annotationStepMap.get(annotation.id)
  );

  const removeAnnotationFromStep = (event: MouseEvent) => {
    event.stopPropagation();
    if (selectedStep) {
      AnnotationController.removeAnnotationFromStep(
        selectedStep.id,
        annotation.id
      );
      removeAnnotationFromStepMutation.mutate(
        {
          stepId: selectedStep.id,
          annotationId: annotation.id,
        },
        {
          onError: (_, data) => {
            // We were being optimistic, add annotation back if error ocurred
            AnnotationController.addAnnotationToStep(
              data.stepId,
              data.annotationId
            );
          },
        }
      );
      AnnotationController.setVisibility(annotation.id, false);
    }
  };

  const addAnnotationToStep = (event: MouseEvent) => {
    event.stopPropagation();
    if (selectedStep) {
      AnnotationController.addAnnotationToStep(selectedStep.id, annotation.id);
      addAnnotationToStepMutation.mutate(
        {
          stepId: selectedStep.id,
          annotationId: annotation.id,
        },
        {
          onError: (_, data) => {
            // We were being optimistic, remove annotation again if error ocurred
            AnnotationController.removeAnnotationFromStep(
              data.stepId,
              data.annotationId
            );
          },
        }
      );
      AnnotationController.setVisibility(annotation.id, true);
    }
  };

  const duplicateAnnotation = (event: MouseEvent) => {
    event.stopPropagation();
    if (selectedStep) {
      const instructionId = useProjectStore.getState().instructionId;

      const newAnnotation = { ...annotation };
      newAnnotation.id = uuidv4();

      AnnotationController.addAnnotation(newAnnotation);
      AnnotationController.addAnnotationToStep(
        selectedStep.id,
        newAnnotation.id
      );

      annotationCreateMutation.mutate({
        id: instructionId,
        data: {
          stepId: selectedStep.id,
          annotation: _.omit(newAnnotation, ['instruction']) as AnnotationDto,
        },
      });
    }
  };

  const addOrRemoveIcon = selectedStep ? (
    stepSet && stepSet.has(selectedStep.id) ? (
      <Tooltip label="Remove annotation from selected step.">
        <ActionIcon
          color={'gray'}
          variant="filled"
          size={'sm'}
          onClick={removeAnnotationFromStep}
        >
          <IconMinus color={iconColor} size={'1.125rem'} />
        </ActionIcon>
      </Tooltip>
    ) : (
      <Tooltip label="Add annotation to selected step.">
        <ActionIcon
          color={'gray'}
          variant="filled"
          size={'sm'}
          onClick={addAnnotationToStep}
        >
          <IconPlus color={iconColor} size={'1.125rem'} />
        </ActionIcon>
      </Tooltip>
    )
  ) : undefined;

  const duplicateIcon = (
    <Tooltip label="Duplicate annotation in to selected step.">
      <ActionIcon
        color="gray"
        variant="filled"
        size={'sm'}
        onClick={duplicateAnnotation}
      >
        <IconCopy color={iconColor} size={'1.125rem'} />
      </ActionIcon>
    </Tooltip>
  );

  return (
    <Group
      key={`annotation-overview-${annotation.id}`}
      px={'xs'}
      py={2}
      className={cx(classes.annotation, {
        [classes['annotation--selected']]: mode === 'editing',
      })}
      onMouseOver={() => {
        AnnotationController.setHighlight(annotation.id, true);
      }}
      onMouseOut={() => {
        AnnotationController.setHighlight(annotation.id, false);
      }}
      onClick={() => {
        AnnotationController.stopEditing();
        AnnotationController.editAnnotation(annotation.id);
        AnnotationController.setHighlight(annotation.id, false);
      }}
    >
      {AnnotationIconMap[annotation.type]}
      <Text fz={'sm'}>{AnnotationTypeNames[annotation.type]}</Text>
      <Group ml={'auto'} gap={'xs'}>
        {selectedStep && duplicateIcon}
        {addOrRemoveIcon}
      </Group>
    </Group>
  );
};
