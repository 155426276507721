import { StepController, useModelStore, useStepIndex } from '@assemblio/frontend/stores';
import { Assembly, AssemblyMetaData, Part } from '@assemblio/type/input';
import { Suspense, useCallback, useMemo } from 'react';
import { HierarchyFilters, SearchTerm } from '../types/hierarchy.types';
import { HierarchyBranch } from './HierarchyBranch';
import { HierarchyLeaf } from './HierarchyLeaf';
import { useForceHierarchyFilterUpdate } from './useForceHierarchyFilterUpdate';

interface Props {
  assemblyMetaData: AssemblyMetaData;
  rootAssembly: Assembly;
  filter: HierarchyFilters & SearchTerm;
}

interface Tree {
  data: Part | Assembly;
  isLeaf: boolean;
  children: Array<Tree>;
}

type FilterResult = 'STOP' | 'INCLUDE' | 'EXCLUDE';

export const HierarchyTree = ({ assemblyMetaData, rootAssembly, filter }: Props) => {
  const modelInformation = useModelStore((state) => state.modelInformationMap);
  const modelStepMap = useStepIndex((state) => state.modelStepMap);

  const stepTypeEventTrigger = useForceHierarchyFilterUpdate();

  const filterMethod = useCallback(
    (element: Tree): FilterResult => {
      const isExcluded = modelInformation.get(element.data.gltfIndex)?.excluded;

      if (!filter.showExcluded && isExcluded) {
        return 'STOP';
      }

      const matchesSearch = element.data.name.toLowerCase().includes(filter.searchTerm.trim().toLowerCase());
      if (!matchesSearch) return 'EXCLUDE';

      const isDisassembled = element.isLeaf
        ? StepController.isPartDisassembled(element.data.gltfIndex)
        : StepController.isAssemblyDisassembled(element.data.gltfIndex);

      if (filter.showDisassembled || !isDisassembled) {
        if (filter.showExcluded || !isExcluded) {
          return 'INCLUDE';
        }
      }

      return 'EXCLUDE';
    },
    [filter, modelInformation, modelStepMap]
  );

  const tree = useMemo(() => {
    const traverse = (rootAssembly?: Assembly): Tree | undefined => {
      if (rootAssembly) {
        const children = rootAssembly.assemblies
          .map((assemblyIndex) => {
            return traverse(assemblyMetaData.assemblies.at(assemblyIndex));
          })
          .concat(
            rootAssembly.parts.map((partIndex) => {
              const part = assemblyMetaData.parts.at(partIndex);
              if (part) {
                return {
                  data: part,
                  isLeaf: true,
                  children: new Array<Tree>(),
                };
              }
              return undefined;
            })
          )
          .filter((child) => child !== undefined) as Array<Tree>;
        return {
          data: rootAssembly,
          isLeaf: false,
          children,
        };
      }
      return;
    };
    return traverse(rootAssembly);
  }, [assemblyMetaData, rootAssembly]);

  const filteredTree = useMemo(() => {
    const filterTree = (tree: Tree): Tree | undefined => {
      const children = tree.children
        .map((child) => filterTree(child))
        .filter((element) => element !== undefined) as Array<Tree>;
      const filterResult = filterMethod(tree);

      if (filterResult === 'STOP') return undefined;

      // Subparts partially excluded and disassembled leads to the case that the
      // Assembly is still marked as 'Include'. Therefore we exclude it here after all children have been filtered.
      if (!tree.isLeaf && children.length === 0) return undefined;
      if (filterResult === 'INCLUDE' || children.length > 0) {
        return { ...tree, children };
      }
      return undefined;
    };
    return tree && filterTree(tree);
  }, [tree, filterMethod, stepTypeEventTrigger]);

  const treeElement = useMemo(() => {
    if (filteredTree) {
      if (filteredTree.isLeaf) {
        return <HierarchyLeaf data={filteredTree.data} children={filteredTree.children} isLeaf={filteredTree.isLeaf} />;
      } else {
        return (
          <HierarchyBranch data={filteredTree.data} children={filteredTree.children} isLeaf={filteredTree.isLeaf} />
        );
      }
    }
    return;
  }, [filteredTree]);

  return <Suspense>{treeElement}</Suspense>;
};
