import { ActiveUserDataTenant, AppRole } from '@assemblio/type/user';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

export interface AuthStore {
  userId: string;
  email?: string;
  accessToken?: string;
  appRole?: AppRole;
  organizationId: string;
  tenant: ActiveUserDataTenant;
  isSessionActive: boolean;
  setSession: (payload: AuthState) => void;
  setSessionActive: (active: boolean) => void;
  clearSession: () => void;
}

export interface AuthState {
  userId: string;
  email?: string;
  accessToken?: string;
  appRole?: AppRole;
  organizationId: string;
  tenant: ActiveUserDataTenant;
}

export const useAuthStore = create<AuthStore>()(
  devtools(
    (set) => ({
      userId: '',
      email: '',
      accessToken: '',
      appRole: undefined,
      organizationId: '',
      tenant: {} as ActiveUserDataTenant,
      isSessionActive: true,
      setSession: (payload: AuthState) =>
        set(
          (state) => ({
            userId: payload.userId,
            email: payload.email ? payload.email : state.email,
            accessToken: payload.accessToken,
            appRole: payload.appRole,
            organizationId: payload.organizationId,
            tenant: payload.tenant,
          }),
          false,
          { type: 'Set Auth Session', payload }
        ),
      setSessionActive: (active) =>
        set(
          (state) => ({
            ...state,
            isSessionActive: active,
          }),
          false,
          { type: 'Set Session Active', active }
        ),
      clearSession: () =>
        set(
          {
            userId: '',
            email: '',
            accessToken: '',
            appRole: undefined,
            organizationId: '',
            tenant: {} as ActiveUserDataTenant,
          },
          false,
          { type: 'Clear Auth Session' }
        ),
    }),
    { name: 'Authstore' }
  )
);

// Put AuthStore into window for Cypress testing
if (process.env['NODE_ENV'] === 'development' && (window as any).Cypress) {
  (window as any).useAuthStore = useAuthStore;
}
