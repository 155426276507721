import {
  UIController,
  useSequenceStore,
  useUIStore,
} from '@assemblio/frontend/stores';
import { StepGroup } from '@assemblio/shared/next-types';
import { group } from 'console';

export const useStepGroupDisplayState = (
  stepGroup: StepGroup
): {
  selected: boolean;
  annotationHighlight: boolean;
  isInteractable: boolean;
  collapsed: boolean;
  toggle: () => void;
} => {
  const collapsed = useUIStore((state) => {
    return !state.expandedStepGroups.has(stepGroup.id);
  });
  const annotationHighlight = useUIStore((state) => {
    return collapsed
      ? Boolean(
          stepGroup.steps.find((step) => {
            return state.highlightedAnnotation !== undefined
              ? step.annotations.includes(state.highlightedAnnotation)
              : false;
          })
        )
      : false;
  });

  const selected = useSequenceStore(
    (state) =>
      state.selectedStepGroup !== undefined &&
      state.selectedStepGroup.id === stepGroup.id
  );

  const isInteractable = useUIStore((state) => !state.isAnimating);

  const toggle = () => {
    if (collapsed) {
      UIController.expandStepGroup(stepGroup.id);
    } else {
      UIController.collapseStepGroup(stepGroup.id);
    }
  };

  return {
    selected,
    annotationHighlight,
    isInteractable,
    collapsed,
    toggle,
  };
};

