import { NextProjectDto, ProjectContentDto } from '@assemblio/shared/dtos';
import { AxiosProgressEvent } from 'axios';
import { axiosHandler } from '../utils/axios-utils';
import { ProgressCallback, handleProgressCallback } from '../utils/progress-event';
import {
  NextProjectChangeOwner,
  NextProjectCreate,
  NextProjectDelete,
  NextProjectUpdate,
} from './types/project.mutation.types';
import { NextProject } from './types/project.return.types';
import { CreateProjectDto, ProjectDto } from '@assemblio/shared/next-types';
import { FetchProject } from '@assemblio/frontend/data-access';

export const deleteProject = async (id: string) => {
  return axiosHandler('delete', `/projects/${id}`);
};

export const createProjectNext = async (
  project: NextProjectCreate,
  signal: AbortSignal | undefined,
  events?: ProgressCallback
): Promise<NextProject> => {
  const formData = new FormData();
  formData.append('file', project.file);
  formData.append('name', project.projectData.name);

  if (project.projectData.projectId) {
    formData.append('projectId', project.projectData.projectId);
  } else if (project.projectData.folderId) {
    formData.append('folderId', project.projectData.folderId);
  }

  return axiosHandler('post', '/instructions', {
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' },
    onUploadProgress: (progressEvent: AxiosProgressEvent) => handleProgressCallback(progressEvent, events),
    signal: signal,
  });
};

export const deleteNextProject = async (project: NextProjectDelete) => {
  return axiosHandler('delete', `/projects/${project.projectId}`);
};

export const updateNextProject = async (project: NextProjectUpdate) => {
  return axiosHandler('put', `/projects/${project.projectId}`, {
    data: project.projectData,
  });
};

export const changeProjectOwner = async (options: NextProjectChangeOwner) => {
  return axiosHandler('patch', `projects/${options.projectId}/owner/${options.newOwner.id}`);
};

export const fetchNextProject = async (id: string | undefined): Promise<NextProjectDto> => {
  return typeof id === 'undefined' ? Promise.reject(new Error('Invalid Id')) : axiosHandler('get', `/projects/${id}`);
};

export const fetchProjectContent = async (data: FetchProject): Promise<ProjectContentDto> => {
  return typeof data.projectId === 'undefined'
    ? Promise.reject(new Error('Invalid Id'))
    : axiosHandler('get', `/v1/projects/${data.projectId}`, { params: data.queryData });
};

export const createEmptyProject = async (project: CreateProjectDto): Promise<ProjectDto> => {
  return axiosHandler('post', '/projects', { data: project });
};
