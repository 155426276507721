import { useQuery } from '@tanstack/react-query';
import {
  fetchComparableInstructions,
  fetchInstructionSyncProfile,
  fetchSyncProfiles,
} from './instruction.sync.requests';
import { InstructionSyncProfile } from '../types/instruction.request.types';

export const useComparableInstructionsQuery = (instructionId: string | undefined) => {
  return useQuery(['comparable-instructions', instructionId], () => fetchComparableInstructions(instructionId));
};

export const useSyncProfilesQuery = (instructionId: string | undefined) => {
  return useQuery(['product-sync-profiles', instructionId], () => fetchSyncProfiles(instructionId));
};

export const useInstructionSyncProfileQuery = (data: InstructionSyncProfile) => {
  return useQuery(['instruction-sync-profile', [data.targetId, data.sourceId]], () =>
    fetchInstructionSyncProfile(data)
  );
};
