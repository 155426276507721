import { notifications } from '@mantine/notifications';
import { useMutation } from '@tanstack/react-query';
import {
  createStepGroup,
  deleteStepGroup,
  moveStepGroup,
  renameStepGroup,
} from './step-group.requests';
import {
  CreateStepGroup,
  MoveStepGroup,
  PatchStepGroup,
} from './types/step-group.mutation.types';

export const useStepGroupCreate = () => {
  return useMutation((create: CreateStepGroup) => createStepGroup(create), {
    onError: (_error) => {
      notifications.show({
        id: 'create-step-group',
        message: 'StepGroup could not be created',
        color: 'red',
      });
    },
  });
};

export const useStepGroupRename = () => {
  return useMutation((patch: PatchStepGroup) => renameStepGroup(patch), {
    onError: (_error) => {
      notifications.show({
        id: 'rename-step-group',
        message: 'StepGroup could not be modified',
        color: 'red',
      });
    },
  });
};

export const useStepGroupDelete = () => {
  return useMutation((stepGroupId: string) => deleteStepGroup(stepGroupId), {
    onError: (_error) => {
      notifications.show({
        id: 'delete-step',
        message: 'Step could not be deleted',
        color: 'red',
      });
    },
  });
};

export const useStepGroupMove = () => {
  return useMutation((patch: MoveStepGroup) => moveStepGroup(patch), {
    onError: (_error) => {
      notifications.show({
        id: 'move-step-group',
        message: 'StepGroup could not be moved',
        color: 'red',
      });
    },
  });
};
