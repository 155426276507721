import { Ids } from '../types/common.types';
import { axiosHandler } from '../utils/axios-utils';
import {
  PartsColor,
  PartGroupRename,
  PartRename,
  PartColor,
  PartOrGroupNames,
} from './types/parts.mutation.types';

export const renamePart = async ({ id, data }: PartRename) => {
  return axiosHandler('patch', `/parts/${id}/rename`, { data });
};

export const resetPartsName = async (data: Ids) => {
  return axiosHandler('patch', `/parts/reset-names`, { data });
};

export const bulkSetPartName = async (data: PartOrGroupNames[]) => {
  return axiosHandler('patch', `/parts/name-bulk`, { data });
};

export const bulkSetPartGroupName = async (data: PartOrGroupNames[]) => {
  return axiosHandler('patch', `/parts/group/name-bulk`, { data });
};

export const renamePartGroup = async ({ id, data }: PartGroupRename) => {
  return axiosHandler('patch', `/parts/group/${id}/rename`, { data });
};

export const resetPartGroupsName = async (data: Ids) => {
  return axiosHandler('patch', `/parts/group/reset-names`, { data });
};

export const setPartColor = async (data: PartsColor) => {
  return axiosHandler('patch', `/parts/color`, { data });
};

export const bulkSetPartColor = async (data: PartColor[]) => {
  return axiosHandler('patch', `/parts/color-bulk`, { data });
};

export const resetPartColor = async (data: Ids) => {
  return axiosHandler('patch', `/parts/color-reset`, { data });
};
