import { useState } from 'react';

export const useCheckboxState = (
  selectableValues: string[],
  selectedValuesCount: number,
  setSelectedValues: React.Dispatch<React.SetStateAction<string[]>>
) => {
  const [controlChecked, setControlChecked] = useState(
    selectableValues.length > 0
  );

  const toggleAllCheckBox = () => {
    if (selectedValuesCount < selectableValues.length) {
      setSelectedValues(selectableValues);
      setControlChecked(true);
    } else {
      setSelectedValues([]);
      setControlChecked(false);
    }
  };

  const handleCheckboxChange = (values: string[]) => {
    if (values.length === 0) setControlChecked(false);
    if (values.length === selectableValues.length) setControlChecked(true);
    setSelectedValues(values);
  };

  return { controlChecked, toggleAllCheckBox, handleCheckboxChange };
};
