import { CameraControlsAPI } from '@assemblio/frontend/types';
import { Camera } from '@react-three/fiber';
import { Scene, WebGLRenderer } from 'three';
import { EffectComposer, OutlinePass } from 'three-stdlib';
import { create } from 'zustand';

interface CanvasStore {
  scene?: Scene;
  camera?: Camera;
  renderer?: WebGLRenderer;
  cameraControls?: CameraControlsAPI;
  composer?: EffectComposer;
  outline?: OutlinePass;
}

export const useCanvasStore = create<CanvasStore>(
  () =>
    ({
      scene: undefined,
      camera: undefined,
      renderer: undefined,
      cameraControls: undefined,
      composer: undefined,
      outline: undefined,
    } as CanvasStore)
);
