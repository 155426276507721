import { Camera, Projection } from '@assemblio/shared/next-types';
import { Vector, Quaternion } from '@assemblio/type/3d';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import {
  Grid,
  Table,
  DrawMode,
  TransformMode,
  SelectionMode,
} from './types/setting.types';

export interface Settings {
  common: {
    mode: string;
    theme: any;
  };
  viewport: {
    color: string;
    drawMode: DrawMode;
    grid: Grid;
    table: Table;
    camera: Camera;
  };
  loader: any;
  exporter: any;
  selectionMode: SelectionMode;
  transformMode: TransformMode;
}

export const useSettingsStore = create<Settings>()(
  devtools(
    () =>
      ({
        common: {
          mode: 'dark',
          theme: {},
        },
        viewport: {
          color: '#251E29',
          drawMode: DrawMode.SOLID,
          grid: {
            enabled: true,
            color: '#000000',
            thickness: 1,
          },
          table: {
            enabled: false,
            color: '#ffffff',
          },
          camera: {
            id: '',
            near: 0.1,
            projection: Projection.ORTHOGRAPHIC,
            far: 2000,
            fov: 60,
            zoom: 1.0,
            distance: 5,
            transform: {
              position: { x: 0, y: 0, z: 5 } as Vector,
              rotation: { x: 0, y: 0, z: 0, w: 0 } as Quaternion,
            },
          },
        },
        loader: {},
        exporter: {},
        selectionMode: SelectionMode.REPLACE,
        transformMode: TransformMode.GLOBAL,
      } as Settings),
    { name: 'Settings Store' }
  )
);
