import {
  useModelStore,
  useSettingsStore,
  useUIStore,
} from '@assemblio/frontend/stores';
import { shallow } from 'zustand/shallow';

export const Surface = () => {
  const yOffset = useModelStore((state) => state.yOffset);
  const detectionEnabled = useUIStore(
    (state) => state.enableCollisionDetection
  );
  const { isTableEnabled, tableColor } = useSettingsStore(
    (state) => ({
      isTableEnabled: state.viewport.table.enabled,
      tableColor: state.viewport.table.color,
    }),
    shallow
  );
  if (!detectionEnabled) return null;
  return (
    <mesh
      receiveShadow
      layers={31}
      rotation={[Math.PI / -2, 0, 0]}
      position={[0, yOffset, 0]}
    >
      <planeGeometry args={[2, 2, 10, 10]} />
      <shadowMaterial opacity={0.5} />
      {isTableEnabled && <meshStandardMaterial color={tableColor} />}
    </mesh>
  );
};
