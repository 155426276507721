import { ModelController, useUIStore } from '@assemblio/frontend/stores';
import { Button, Divider, Text } from '@mantine/core';
import { ContextMenu, ContextMenuProps } from './ContextMenu';
import { AssemblyExIncludeButton } from './AssemblyExIncludeButton';

export const AssemblyContextMenu = (props: ContextMenuProps) => {
  const assemblyContext = useUIStore((state) => state.assemblyContext);
  const modelName =
    assemblyContext !== undefined ? ModelController.getPartNameOverrideByGltfIndex(assemblyContext) : '';

  return (
    <ContextMenu opened={props.opened} onClose={props.onClose}>
      <Text size="sm">{modelName}</Text>
      <>
        <Divider my="sm" />
        <Button.Group orientation="vertical">
          {assemblyContext !== undefined && <AssemblyExIncludeButton assemblyContext={assemblyContext} />}
        </Button.Group>
      </>
    </ContextMenu>
  );
};
