import { ImageDto } from '@assemblio/shared/dtos';
import {
  AnnotationDto,
  AnnotationMetaDto,
  AnnotationResponseDto,
} from '@assemblio/shared/next-types';
import { AxiosProgressEvent } from 'axios';
import { axiosHandler } from '../utils/axios-utils';
import {
  handleProgressCallback,
  ProgressCallback,
} from '../utils/progress-event';
import { ImagesUpload } from './types/annotation.mutation.types';

const endpoints = {
  arrow: 'arrow',
  rect: 'shape/rect',
  ellipse: 'shape/ellipse',
};

export const createAnnotation = async (
  instructionId: string,
  stepId: string,
  annotation: AnnotationDto
) => {
  if (annotation.type === 'image') return;

  return axiosHandler<any>(
    'post',
    `annotations/instruction/${instructionId}/step/${stepId}/${
      endpoints[annotation.type]
    }`,
    {
      data: annotation,
    }
  );
};

export const createImageAnnotation = async (
  instructionId: string,
  stepId: string,
  annotation: AnnotationDto,
  file: File
) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('annotation', JSON.stringify(annotation));

  return axiosHandler<any>(
    'post',
    `annotations/instruction/${instructionId}/step/${stepId}/image`,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data: formData,
    }
  );
};

export const updateAnnotation = async (
  annotationId: string,
  annotationMeta: Partial<AnnotationMetaDto>
) => {
  return axiosHandler('patch', `annotations/${annotationId}`, {
    data: annotationMeta,
  });
};

export const addAnnotationToStep = async (
  annotationId: string,
  stepId: string
) => {
  return axiosHandler('post', `steps/${stepId}/annotations/${annotationId}`);
};

export const removeAnnotationFromStep = async (
  annotationId: string,
  stepId: string
) => {
  return axiosHandler('delete', `steps/${stepId}/annotations/${annotationId}`);
};

export const uploadAnnotationImage = async (
  imagesUpload: ImagesUpload,
  signal: AbortSignal | undefined,
  events?: ProgressCallback
): Promise<{ images: ImageDto[] }> => {
  const formData = new FormData();

  imagesUpload.files.forEach((file) => {
    formData.append('files', file);
  });

  return axiosHandler('post', `/annotations/${imagesUpload.projectId}/images`, {
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' },
    onUploadProgress: (progressEvent: AxiosProgressEvent) =>
      handleProgressCallback(progressEvent, events),
    signal: signal,
  });
};

export const fetchAnnotations = async (
  instructionId: string
): Promise<Array<AnnotationResponseDto>> => {
  return axiosHandler('get', `/instructions/${instructionId}/annotations`);
};

export const deleteAnnotation = async (annotationId: string) => {
  return axiosHandler('delete', `/annotations/${annotationId}`);
};

export const deleteImageAnnotation = async (annotationId: string) => {
  return axiosHandler('delete', `/annotations/image/${annotationId}`);
};
