import { createRef } from 'react';
import { Grid, Paper, ScrollArea } from '@mantine/core';
import classes from './ProjectExplorer.module.scss';
import { ExplorerGridSkeleton } from './ExplorerGridSkeleton';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { ErrorScreen } from './ErrorScreen';
import { CSSTransitionProps } from 'react-transition-group/CSSTransition';
import { ExplorerItem } from './types';
import { ProjectCard } from './Cards/ProjectCard/ProjectCard';
import { FolderCard } from './Cards/FolderCard/FolderCard';
import { InstructionCard } from './Cards/InstructionCard/InstructionCard';

interface GridViewProps {
  items: ExplorerItem[];
  inInstruction: boolean;
  error: boolean;
  loading: boolean;
}

const sharedTransitionProps: CSSTransitionProps = {
  timeout: Number(classes.animation_duration),
  classNames: {
    enter: classes['item-enter'],
    enterActive: classes['item-enter-active'],
    exit: classes['item-exit'],
    exitActive: classes['item-exit-active'],
  },
};

export const GridView = ({
  items,
  inInstruction,
  error,
  loading,
}: GridViewProps) => {
  // Breakpoints for contentful cards
  const innerGridColSpan = inInstruction
    ? {
        //half-width Grid
        //4 in a row
        xl: 15,
        //3 in a row
        lg: 20,
        md: 20,
        //2 in a row
        sm: 30,
        //1 in a row
        xs: 60,
        base: 30,
      }
    : {
        //fullWidth Grid
        //6 in a row
        xl: 10,
        //5 in a row
        lg: 12,
        //4 in a row
        md: 15,
        //3 in a row
        sm: 20,
        //2 in a row
        xs: 30,
        base: 30,
      };

  return (
    <Paper radius={'lg'} className={classes.mainGrid__explorerPanel}>
      {!error ? (
        <ScrollArea
          h={'100%'}
          p={'lg'}
          classNames={{
            scrollbar: classes.customScrollbar__bar,
            corner: classes.customScrollbar__corner,
          }}
          type="scroll"
        >
          <Grid
            gutter={'lg'}
            columns={60}
            className={classes.projectSimpleGrid}
          >
            {loading ? (
              <ExplorerGridSkeleton colSpans={innerGridColSpan} />
            ) : (
              <TransitionGroup component={null}>
                {items.map((item) => {
                  const nodeRef = createRef<HTMLDivElement>();
                  return (
                    <CSSTransition
                      key={item.id}
                      nodeRef={nodeRef}
                      {...sharedTransitionProps}
                    >
                      <Grid.Col
                        ref={nodeRef}
                        data-cy={`each-${item.type}-card`}
                        key={`card-${item.id}`}
                        h={'23vh'}
                        mih={'250px'}
                        span={innerGridColSpan}
                      >
                        {renderCardItem(item)}
                      </Grid.Col>
                    </CSSTransition>
                  );
                })}
              </TransitionGroup>
            )}
          </Grid>
        </ScrollArea>
      ) : (
        <ErrorScreen />
      )}
    </Paper>
  );
};

const renderCardItem = (item: ExplorerItem) => {
  switch (item.type) {
    case 'project':
      return <ProjectCard {...item.props} {...item.handlers} />;
    case 'folder':
      return <FolderCard {...item.props} {...item.handlers} />;
    case 'instruction':
      return <InstructionCard {...item.props} {...item.handlers} />;
  }
};
