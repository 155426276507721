import { Input } from '@assemblio/type/input';
import { EdgesGeometry, LineBasicMaterial, LineSegments, Mesh, MeshStandardMaterial, Object3D } from 'three';
import { GLTF } from 'three-stdlib';
import { ModelController } from '../controller';
import { ProjectController } from '../controller/ProjectController';

export const modelInit = (gltf: GLTF, input: Input) => {
  setIdsOnModels(gltf.scene, gltf);
  associateProjectIndicesWithGltfIndices(input);
  assignCustomMaterialsToModels(gltf.scene);
  createLineMesh(gltf.scene);
};

export const associateProjectIndicesWithGltfIndices = (input: Input) => {
  input.assemblies.forEach((assembly, index) => {
    ProjectController.associateAssemblyIndexWithGltfIndex(index, assembly.gltfIndex);
  });

  input.parts.forEach((part, index) => {
    ProjectController.associatePartIndexWithGltfIndex(index, part.gltfIndex);
  });
};

export const setIdsOnModels = (node: Object3D, gltf: GLTF, parent?: number) => {
  const association = gltf.parser.associations.get(node);
  if (association && association.nodes !== undefined) {
    node.userData['id'] = association.nodes;
    if (parent !== undefined) {
      ProjectController.setPartParentOnIndex(association.nodes, parent);
    }
    parent = association.nodes;
    ModelController.setModelGltfIndex(association.nodes, node);
  }

  if (parent === undefined) parent = -1;

  node.children.forEach((child) => {
    setIdsOnModels(child, gltf, parent);
  });
};

export const assignCustomMaterialsToModels = (node: Object3D) => {
  if (node.type === 'Mesh') {
    const mesh = node as Mesh;
    mesh.castShadow = true;
    mesh.geometry.computeBoundingSphere();
    if (!Array.isArray(mesh.material)) {
      const material = mesh.material.clone() as MeshStandardMaterial;
      material.metalness = 0.7;
      material.depthWrite = true;
      material.transparent = true;
      material.opacity = 1;
      mesh.material = material;
    }
  }
  node.children.forEach((child) => {
    assignCustomMaterialsToModels(child);
  });
};

export const createLineMesh = (node: Object3D) => {
  if (node.type === 'Mesh') {
    const mesh = node as Mesh;
    const thresholdAngle = 44; // the angle between faces determines what is recognized as an edge
    const edges = new EdgesGeometry(mesh.geometry, thresholdAngle);
    const line = new LineSegments(
      edges,
      new LineBasicMaterial({
        color: 0x000000,
        linewidth: 1,
        transparent: true,
        opacity: 1,
        depthWrite: true,
      })
    );
    // Move line to any layer other than 0
    // Parts are on layer 0 which means that only those are selectable
    line.layers.set(31);
    mesh.add(line);
  }
  node.children.forEach((child) => {
    createLineMesh(child);
  });
};
