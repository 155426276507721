export enum JobState {
  Initialized = 'initialized',
  Running = 'running',
  Completed = 'completed',
  Aborted = 'aborted',
  Failed = 'failed',
}

export enum JobMessageType {
  Status = 'status',
  Result = 'result',
  Error = 'error',
}

export enum JobMessagePayloadType {
  Object = 'object',
  Progress = 'progress',
  Prose = 'prose',
  State = 'state',
  File = 'file',
}
