import {
  AssemblyInformation,
  ModelController,
  ModelVisibilityController,
  PartInformation,
  ProjectController,
  UIController,
  useModelStore,
  useUIStore,
} from '@assemblio/frontend/stores';
import { Assembly } from '@assemblio/type/input';
import { useEffect, useState } from 'react';

const useBranchSubscription = (
  rootAssembly: Assembly
): {
  selected: boolean;
  expanded: boolean;
  transparent: boolean;
  visible: boolean;
  name: string;
  excluded: boolean;
  elementId: string;
} => {
  const [selected, setSelected] = useState(ModelController.isAssemblySelected(rootAssembly));
  const [expanded, setExpanded] = useState(UIController.isAssemblyExpanded(rootAssembly.gltfIndex));
  const [transparent, setTransparent] = useState(
    ModelVisibilityController.isAssemblyTransparent(rootAssembly.gltfIndex)
  );
  const [visible, setVisible] = useState(ModelVisibilityController.isAssemblyVisible(rootAssembly.gltfIndex));
  const assemblyInformation = useModelStore(
    (state) => state.modelInformationMap.get(rootAssembly.gltfIndex) as AssemblyInformation
  );

  useEffect(() => {
    const unsubVisible = useModelStore.subscribe(
      (state) => {
        // This needs to be refactored, use equalityFn to compare prev and new state
        return rootAssembly.parts.map(
          (part) =>
            (state.modelInformationMap.get(ProjectController.getPartByProjectIndex(part).gltfIndex) as PartInformation)
              ?.visible
        );
      },
      () => {
        setVisible(ModelVisibilityController.isAssemblyVisible(rootAssembly.gltfIndex));
      }
    );

    const unsubTransparent = useModelStore.subscribe(
      (state) => {
        return rootAssembly.parts.map(
          (part) =>
            (state.modelInformationMap.get(ProjectController.getPartByProjectIndex(part).gltfIndex) as PartInformation)
              ?.transparent
        );
      },
      () => {
        setTransparent(ModelVisibilityController.isAssemblyTransparent(rootAssembly.gltfIndex));
      }
    );

    const unsubExpand = useUIStore.subscribe(
      (state) => state.expandedAssemblies,
      () => {
        const isExpanded = UIController.isAssemblyExpanded(rootAssembly.gltfIndex);
        setExpanded(isExpanded);
      }
    );

    const unsubSelected = useUIStore.subscribe(
      (state) => state.selectedPartSet,
      () => {
        setSelected(ModelController.isAssemblySelected(rootAssembly));
      }
    );

    return () => {
      unsubVisible();
      unsubExpand();
      unsubSelected();
      unsubTransparent();
    };
  }, [rootAssembly]);

  return {
    selected,
    expanded,
    visible,
    transparent,
    excluded: assemblyInformation && assemblyInformation.excluded,
    name: assemblyInformation ? assemblyInformation.name : rootAssembly.name,
    elementId: rootAssembly.id,
  };
};

export default useBranchSubscription;
