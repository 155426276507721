import { Step } from '@assemblio/shared/next-types';
import { StepIndex } from '../../indexes/StepIndex';
import { useSequenceStore, useUIStore } from '../../stores';
import { StepController } from '..';

export const getSegment = (stepId: string, segmentIndex: number) => {
  return StepController.getStep(stepId)?.data.path.at(segmentIndex);
};

export const getStepByIndex = (stepGroupIndex: number, stepIndex: number): Step | undefined => {
  return useSequenceStore.getState().stepGroups[stepGroupIndex].steps.at(stepIndex);
};

export const getStepDisassemblyOrderById = (stepId: string): StepIndex => {
  return StepIndex.getStepIndex(stepId);
};

export const getStepAssemblyOrderById = (step: Step): number => {
  const index = getStepDisassemblyOrderById(step.id);

  const length = useSequenceStore.getState().stepGroups[index.stepGroupIndex].steps.length;
  return length - index.stepIndex - 1;
};

export const getPrevStepByIndex = (sourceStepIndex: StepIndex, includeGroups: boolean): Step | null => {
  const { stepGroupIndex, stepIndex } = sourceStepIndex;
  const stepGroups = useSequenceStore.getState().stepGroups;

  // Check if there's a prev step in the current group.
  if (stepIndex - 1 >= 0) {
    return stepGroups[stepGroupIndex].steps[stepIndex - 1];
  } else if (includeGroups) {
    // If not, find the prev group with steps and select its last step.
    for (let prevGroupIndex = stepGroupIndex - 1; prevGroupIndex >= 0; prevGroupIndex--) {
      if (stepGroups[prevGroupIndex].steps.length > 0) {
        return stepGroups[prevGroupIndex].steps[stepGroups[prevGroupIndex].steps.length - 1];
      }
    }
  }

  // Return null if no prev step was found
  return null;
};

export const getNextStepByIndex = (sourceStepIndex: StepIndex, includeGroups: boolean): Step | null => {
  const { stepGroupIndex, stepIndex } = sourceStepIndex;
  const stepGroups = useSequenceStore.getState().stepGroups;

  // Check if there's a next step in the current group.
  if (stepIndex + 1 < stepGroups[stepGroupIndex].steps.length) {
    return stepGroups[stepGroupIndex].steps[stepIndex + 1];
  } else if (includeGroups) {
    // If not, find the next group with steps and select its first step.
    for (let nextGroupIndex = stepGroupIndex + 1; nextGroupIndex < stepGroups.length; nextGroupIndex++) {
      if (stepGroups[nextGroupIndex].steps.length > 0) {
        return stepGroups[nextGroupIndex].steps[0];
      }
    }
  }

  // Return null if no next step was found
  return null;
};

export const getStepsByGltfIndex = (gltfIndex: number): Step[] => {
  return StepIndex.getStepsByGltfIndex(gltfIndex);
};

export const getStepsByGltfIndexInDisassemblyOrder = (gltfIndex: number): Step[] => {
  return StepIndex.getStepsByGltfIndexInDisassemblyOrder(gltfIndex);
};

export const getAllStepsFlat = (): Step[] => {
  return useSequenceStore.getState().stepGroups.flatMap((stepGroup) => stepGroup.steps);
};

export const getSelectedAndFollowingStepsInDisassemblyOrder = (): Step[] | undefined => {
  const stepId = useUIStore.getState().selectedStep?.id;

  if (stepId) {
    const { stepGroupIndex, stepIndex } = getStepDisassemblyOrderById(stepId);
    const stepGroups = useSequenceStore.getState().stepGroups;

    // Get the initial steps from the current group
    const steps = stepGroups[stepGroupIndex].steps.slice(stepIndex);

    // Iterate over subsequent groups and collect their steps
    for (let nextGroupIndex = stepGroupIndex + 1; nextGroupIndex < stepGroups.length; nextGroupIndex++) {
      steps.push(...stepGroups[nextGroupIndex].steps);
    }

    return steps;
  }
  return;
};

export const getSelectedAndPreviousStepsInDisassemblyOrder = (): Step[] | undefined => {
  const stepId = useUIStore.getState().selectedStep?.id;

  if (stepId) {
    const { stepGroupIndex, stepIndex } = getStepDisassemblyOrderById(stepId);
    const stepGroups = useSequenceStore.getState().stepGroups;

    // Get the initial steps from the current group
    const steps = stepGroups[stepGroupIndex].steps.slice(0, stepIndex + 1);

    // Iterate over previous groups in the array and prepend their steps
    for (let prevGroupIndex = stepGroupIndex - 1; prevGroupIndex >= 0; prevGroupIndex--) {
      steps.unshift(...stepGroups[prevGroupIndex].steps);
    }

    return steps;
  }
  return;
};

export const getSelectedAndFollowingStepsInAssemblyOrder = (): Step[] | undefined => {
  const steps = getSelectedAndPreviousStepsInDisassemblyOrder();
  return steps ? [...steps].reverse() : undefined;
};

export const getFirstStepForGltfIndexInDisassemblyOrder = (gltfIndex: number): Step | undefined => {
  return getStepsByGltfIndexInDisassemblyOrder(gltfIndex).at(0);
};

export const getLastStepForGltfIndexInAssemblyOrder = getFirstStepForGltfIndexInDisassemblyOrder;

export const getLastStepForGltfIndexInDisassemblyOrder = (gltfIndex: number): Step | undefined => {
  const steps = getStepsByGltfIndexInDisassemblyOrder(gltfIndex);
  return steps[steps.length - 1];
};
export const getFirstStepForGltfIndexInAssemblyOrder = getLastStepForGltfIndexInDisassemblyOrder;

const findNextStepForGltfIndex = (gltfIndex: number, stepId: string, stepList: Step[]) => {
  const index = stepList.findIndex((step) => step.id === stepId);
  if (index >= 0 && index < stepList.length) {
    return stepList.slice(index + 1).find((step) => step.data.parts.find((part) => part.partGltfIndex === gltfIndex));
  }
  return undefined;
};

export const getPreviousStepForGltfIndexInDisassemblyOrder = (gltfIndex: number, stepId: string): Step | undefined => {
  const steps = getAllStepsFlat().reverse();
  return findNextStepForGltfIndex(gltfIndex, stepId, steps);
};

export const getNextStepForGltfIndexInAssemblyOrder = getPreviousStepForGltfIndexInDisassemblyOrder;

export const getNextStepForGltfIndexInDisassemblyOrder = (gltfIndex: number, stepId: string): Step | undefined => {
  const steps = getAllStepsFlat();
  return findNextStepForGltfIndex(gltfIndex, stepId, steps);
};

export const getPreviousStepForGltfIndexInAssemblyOrder = getNextStepForGltfIndexInDisassemblyOrder;
