import { useMutation, useQueryClient } from '@tanstack/react-query';
import { UpdateBrandingDTO } from '@assemblio/shared/dtos';
import { updateBrandingOptions, uploadBrandingFile } from './branding.requests';
import { UploadBrandingFile } from './types/branding.requests.types';

export const useUpdateBrandingMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (branding: UpdateBrandingDTO) => updateBrandingOptions(branding),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['branding']).then(() => {
          queryClient.refetchQueries(['branding']);
        });
      },
    }
  );
};

export const useUploadBrandingFileMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (upload: UploadBrandingFile) => uploadBrandingFile(upload),
    {
      onSuccess: (data, variables, context) => {
        queryClient
          .invalidateQueries(['brandingFile', variables.type])
          .then(() => {
            queryClient.refetchQueries(['brandingFile', variables.type]);
          });
      },
    }
  );
};
