const exportPDFCanceled = {
  id: 'export-pdf-canceled',
  title: 'Warning: Export canceled',
  message:
    'This project does not contain any valid steps. Please switch to the editor to create assembly steps.',
};

const projectIsBeingInitialized = {
  title: 'Project is being initialized',
  message: 'Please wait until the initialization is complete.',
  autoClose: 5000,
  loading: true,
  color: 'red',
};

const instructionIsBeingInitialized = {
  title: 'Instruction is being initialized',
  message: 'Please wait until the initialization is complete.',
  autoClose: 5000,
  loading: true,
  color: 'red',
};

const instructionIsBeingProcessed = {
  title: 'Instruction is being processed',
  message: 'Please wait until the instruction finished processing.',
  autoClose: 5000,
  loading: true,
  color: 'red',
};

export const ErrorNotification = {
  exportPDFCanceled,
  projectIsBeingInitialized,
  instructionIsBeingInitialized,
  instructionIsBeingProcessed,
};
