import { NextInstruction } from '../../types/project-structure.types';
import { Box, Button, SegmentedControl, SegmentedControlItem, Text } from '@mantine/core';
import { useState } from 'react';
import { capitalizeSentence } from '../../DateFormatter';
import { useInstructionStateChange } from '@assemblio/frontend/data-access';
import { notifications } from '@mantine/notifications';
import { InstructionState } from '@assemblio/type/instruction';

interface StateSelectionProps {
  instruction: NextInstruction;
  setDirty: React.Dispatch<React.SetStateAction<boolean>>;
  withTitle?: boolean;
}

const SELECTABLE_STATES: InstructionState[] = [InstructionState.DRAFT, InstructionState.APPROVED];

export const StateSelection = ({ instruction, setDirty, withTitle = true }: StateSelectionProps) => {
  const [state, setState] = useState<InstructionState>(instruction.state);

  const instructionStateMutation = useInstructionStateChange();

  const stateSelectionData: SegmentedControlItem[] = SELECTABLE_STATES.map((state) => {
    return {
      label: capitalizeSentence(state.replace('_', ' ').toLocaleLowerCase()),
      value: state,
    };
  });

  const handleSubmit = () => {
    instructionStateMutation.mutate(
      {
        instructionId: instruction.id,
        state: state,
      },
      {
        onSuccess: () => {
          notifications.show({
            id: 'update-instruction-state-success',
            message: 'Instruction state has been updated',
            color: 'green',
          });
        },
        onError: () => {
          notifications.show({
            id: 'update-instruction-state-error',
            message: 'Instruction state could not be changed',
            color: 'red',
          });
        },
      }
    );
    setDirty(true);
  };

  return (
    <Box>
      {withTitle && <Text size={'sm'}>State</Text>}
      <Box style={{ display: 'flex', alignItems: 'center' }}>
        <SegmentedControl
          mt={'sm'}
          data={stateSelectionData}
          onChange={(value) => setState(value as InstructionState)}
          value={state}
        />
        <Button
          disabled={instructionStateMutation.isLoading}
          style={{ height: '36px', marginLeft: 'auto' }}
          size="xs"
          type="submit"
          onClick={(event) => {
            event.stopPropagation();
            handleSubmit();
          }}
          data-cy="instruction-state-apply-button"
        >
          Apply
        </Button>
      </Box>
    </Box>
  );
};
