import { StepCameraSettingsDto, StepType, StepData, Step } from '@assemblio/shared/next-types';
import { Transform } from '@assemblio/type/3d';
import produce, { produceWithPatches } from 'immer';
import _ from 'lodash';
import { OrthographicCamera } from 'three';
import { quaternionToData, vector3ToData } from '../../helper';
import { useCanvasStore } from '../../stores/CanvasStore';
import { Sequence, SequenceStore, useSequenceStore } from '../../stores/SequenceStore';
import { useUIStore } from '../../stores/UIStore';
import { UndoRedoController } from '../UndoRedoController';
import { getNextStepByIndex, getPrevStepByIndex, getStepDisassemblyOrderById } from './StepAccessController';
import { TextDto } from '@assemblio/shared/dtos';
import { StepIndex } from '../../indexes/StepIndex';

export const setStepData = (stepId: string, data: StepData) => {
  const { stepGroupIndex, stepIndex } = getStepDisassemblyOrderById(stepId);

  useSequenceStore.setState(
    produce<Sequence>((state: Sequence) => {
      state.stepGroups[stepGroupIndex].steps[stepIndex].data = data;
    })
  );
};

export const setStepText = (stepId: string, text: TextDto | null) => {
  const { stepGroupIndex, stepIndex } = getStepDisassemblyOrderById(stepId);
  useSequenceStore.setState(
    produce<SequenceStore>((state) => {
      state.stepGroups[stepGroupIndex].steps[stepIndex].text = text;
    })
  );
};

export const renameStep = (stepId: string, name: string): void => {
  const { stepGroupIndex, stepIndex } = getStepDisassemblyOrderById(stepId);

  let lastName = '';
  const [nextState, patches, inversePatches] = produceWithPatches(
    useSequenceStore.getState(),
    (draft: SequenceStore) => {
      lastName = draft.stepGroups[stepGroupIndex].steps[stepIndex].name;
      draft.stepGroups[stepGroupIndex].steps[stepIndex].name = name;
    }
  );

  useSequenceStore.setState(nextState);
  UndoRedoController.createPatchAndVersion(
    useSequenceStore,
    `Renamed step: "${lastName}" to "${name}"`,
    { type: 'no-action-needed' },
    nextState,
    patches,
    inversePatches
  );
};

export const setMTM = (stepId: string, mtm: number): void => {
  const { stepGroupIndex, stepIndex } = getStepDisassemblyOrderById(stepId);

  useSequenceStore.setState(
    produce<SequenceStore>((state) => {
      state.stepGroups[stepGroupIndex].steps[stepIndex].mtm = mtm;
    })
  );
};

export const setAnimationSpeed = (stepId: string, animationSpeed: number): void => {
  const { stepGroupIndex, stepIndex } = getStepDisassemblyOrderById(stepId);

  useSequenceStore.setState(
    produce<SequenceStore>((state) => {
      state.stepGroups[stepGroupIndex].steps[stepIndex].animationSpeed = animationSpeed;
    })
  );
};

export const setPlayWithAbove = (stepId: string, playWithAbove: boolean): boolean => {
  const { stepGroupIndex, stepIndex } = getStepDisassemblyOrderById(stepId);

  // Check if we can set the playWithAbove flag to true
  if (playWithAbove && !canPlayWithAboveBeSet(stepId)) return false;

  const [nextState, patches, inversePatches] = produceWithPatches(
    useSequenceStore.getState(),
    (state: SequenceStore) => {
      state.stepGroups[stepGroupIndex].steps[stepIndex].playWithAbove = playWithAbove;
    }
  );

  useSequenceStore.setState(nextState);
  UndoRedoController.createPatchAndVersion(
    useSequenceStore,
    `PlayWithAbove on ${nextState.stepGroups[stepGroupIndex].steps[stepIndex].name}`,
    { type: 'no-action-needed' },
    nextState,
    patches,
    inversePatches
  );

  return true;
};

export const setType = (stepId: string, type: StepType): void => {
  const { stepGroupIndex, stepIndex } = getStepDisassemblyOrderById(stepId);
  const [nextState, patches, inversePatches] = produceWithPatches(
    useSequenceStore.getState(),
    (state: SequenceStore) => {
      state.stepGroups[stepGroupIndex].steps[stepIndex].type = type;
    }
  );

  useSequenceStore.setState(nextState);
  UndoRedoController.createPatchAndVersion(
    useSequenceStore,
    `Set type on ${nextState.stepGroups[stepGroupIndex].steps[stepIndex].name}`,
    { type: 'no-action-needed' },
    nextState,
    patches,
    inversePatches
  );
};

export const setStepCameraSettings = (stepId: string, cameraSettings: StepCameraSettingsDto) => {
  const { stepGroupIndex, stepIndex } = getStepDisassemblyOrderById(stepId);
  let stepName = '';
  const [nextState, patches, inversePatches] = produceWithPatches(
    useSequenceStore.getState(),
    (state: SequenceStore) => {
      const step = state.stepGroups[stepGroupIndex].steps[stepIndex];
      step.cameraSettings = _.cloneDeep(cameraSettings);
      stepName = step.name;
    }
  );
  useSequenceStore.setState(nextState);
  UndoRedoController.createPatchAndVersion(
    useSequenceStore,
    `Changed step:${stepName} camera position`,
    { type: 'no-action-needed' },
    nextState,
    patches,
    inversePatches
  );

  return useSequenceStore.getState().stepGroups[stepGroupIndex].steps[stepIndex].cameraSettings;
};

/**
Updates the camera transform of a given step in the project sequence.
@param stepId - The ID of the step whose camera transform will be updated.
@param camera - The new camera transform to apply.
@returns {OrthographicCamera} The cameraSettings of the current View
*/
export const setStepCameraTransform = (stepId: string, cameraTransform: Transform, distance: number, zoom?: number) => {
  const { stepGroupIndex, stepIndex } = getStepDisassemblyOrderById(stepId);
  const storeCamera = useCanvasStore.getState().camera as OrthographicCamera;
  let stepName = '';
  const [nextState, patches, inversePatches] = produceWithPatches(
    useSequenceStore.getState(),
    (state: SequenceStore) => {
      const step = state.stepGroups[stepGroupIndex].steps[stepIndex];
      step.cameraSettings.near = storeCamera.near;
      step.cameraSettings.far = storeCamera.far;
      step.cameraSettings.zoom = zoom !== undefined ? zoom : storeCamera.zoom;
      step.cameraSettings.transform = cameraTransform;
      step.cameraSettings.distance = distance;
      stepName = step.name;
    }
  );
  useSequenceStore.setState(nextState);
  UndoRedoController.createPatchAndVersion(
    useSequenceStore,
    `Changed step:${stepName} camera position`,
    { type: 'no-action-needed' },
    nextState,
    patches,
    inversePatches
  );

  return useSequenceStore.getState().stepGroups[stepGroupIndex].steps[stepIndex].cameraSettings;
};

export const setStepCamera = () => {
  const selectedStep = useUIStore.getState().selectedStep;
  const camera = useCanvasStore.getState().camera as OrthographicCamera;

  if (camera && selectedStep && selectedStep.id) {
    const position = vector3ToData(camera.position);
    const rotation = quaternionToData(camera.quaternion);

    setStepCameraTransform(
      selectedStep.id,
      {
        position,
        rotation,
      },
      camera.distance
    );
  }
};

export const getStepTexts = (stepId: string): TextDto[] => {
  const stepTexts: TextDto[] = [];

  const step: Step | null = StepIndex.getStep(stepId);
  let stepIndex = StepIndex.getStepIndex(stepId);

  // Add the current step's text if it exists
  if (step && step.text) {
    stepTexts.push(step.text);
  }

  // Traverse backward to add steps with playWithAbove set to true
  if (step.playWithAbove) {
    let prevStep = getPrevStepByIndex(stepIndex, false);
    while (prevStep) {
      if (prevStep.text) {
        stepTexts.push(prevStep.text); // Add to the beginning of the array
      }
      if (!prevStep.playWithAbove) {
        break;
      }
      stepIndex = StepIndex.getStepIndex(prevStep.id);
      prevStep = getPrevStepByIndex(stepIndex, false);
    }
  }

  // Traverse forward to add steps with playWithAbove set to true
  stepIndex = StepIndex.getStepIndex(stepId);
  let nextStep = getNextStepByIndex(stepIndex, true);
  while (nextStep && nextStep.playWithAbove) {
    if (nextStep.text) {
      stepTexts.unshift(nextStep.text);
    }
    stepIndex = StepIndex.getStepIndex(nextStep.id);
    nextStep = getNextStepByIndex(stepIndex, true);
  }

  return stepTexts;
};

// Checks if no part that was used in the current Step and the Steps before (if playWithAbove is set)
// is used in the following steps. Returns true if playWithAbove was set.
const canPlayWithAboveBeSet = (stepId: string): boolean => {
  const { stepGroupIndex, stepIndex } = getStepDisassemblyOrderById(stepId);

  // Bail early, First Step in a group should never have play with above
  if (stepIndex === 0) return false;

  const stepList = useSequenceStore.getState().stepGroups[stepGroupIndex].steps;
  let foundSamePartAbove = false;
  const currentStep = stepList[stepIndex];
  let includedParts = currentStep.data.parts.map((part) => part.partGltfIndex);
  for (let i = stepIndex + 1; i < stepList.length; i++) {
    const step = stepList[i];
    if (step.playWithAbove) {
      const stepParts = step.data.parts.map((part) => part.partGltfIndex);
      includedParts = includedParts.concat(stepParts);
    } else break;
  }
  for (let i = stepIndex - 1; i >= 0; i--) {
    const step = stepList[i];
    if (step.data && step.data.parts.some((part) => includedParts.includes(part.partGltfIndex))) {
      foundSamePartAbove = true;
      break;
    }
    if (!step.playWithAbove) break;
  }
  return !foundSamePartAbove;
};
