import { UserDto } from '@assemblio/shared/dtos';

export enum Mock_VersionState {
  APPROVED = 'Approved',
  REVIEW = 'In Review',
  CHANGES_NEEDED = 'Changes needed',
  DRAFT = 'Draft',
}

export enum Mock_VersionAction {
  APPROVE = 'Approved',
  SAVED = 'Saved',
  REJECTED = 'Rejected',
  CREATED = 'Created',
}

export interface Mock_VersionInformation {
  version: string;
  type: 'Minor' | 'Major';
  timestamp: Date;
  state: Mock_VersionState;
  action: Mock_VersionAction;
  comment?: string;
  issuer: UserDto;
}
