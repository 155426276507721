import React, { ReactElement } from 'react';
import { Box, BoxProps, ElementProps, LoadingOverlay } from '@mantine/core';
import classes from './ExplorerCard.module.scss';
import cx from 'clsx';
import { Header } from './Header';
import { Footer } from './Footer';
import { Body } from './Body';
interface ExplorerCardProps
  extends BoxProps,
    ElementProps<'a', keyof BoxProps> {
  children?: ReactElement[];
  selected?: boolean;
  loading?: boolean;
  highlighted?: boolean;
}

const getChildrenByDisplayName = (
  children: ReactElement[] | undefined,
  displayName: string
) => {
  return React.Children.map(children, (child) =>
    // Use type assertion to tell TypeScript that child.type is an element type
    React.isValidElement(child) &&
    (child.type as { displayName?: string }).displayName === displayName
      ? child
      : null
  );
};

const ExplorerCard = ({
  children,
  selected = false,
  loading = false,
  highlighted = false,
  ...props
}: ExplorerCardProps) => {
  const header = getChildrenByDisplayName(children, 'Header');
  const body = getChildrenByDisplayName(children, 'Body');
  const footer = getChildrenByDisplayName(children, 'Footer');
  return (
    <Box
      data-selected={selected}
      className={cx(classes.explorerCard, {
        [classes['explorerCard--selected']]: selected,
        [classes['explorerCard--highlighted']]: highlighted,
      })}
      {...props}
      component={'a'}
    >
      <LoadingOverlay
        overlayProps={{
          blur: 2,
          radius: 'var(--mantine-radius-md)',
        }}
        visible={loading}
      />
      {header}
      {body}
      {footer}
    </Box>
  );
};

ExplorerCard.Body = Body;
ExplorerCard.Footer = Footer;
ExplorerCard.Header = Header;

export default ExplorerCard;
