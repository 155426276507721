export interface StepEventDetail {
  stepId: string;
  type: string;
}

interface StepEvents {
  updateStepType: (event: CustomEvent<StepEventDetail>) => void;
}

export class StepEventEmitter {
  private target: EventTarget;

  constructor() {
    this.target = new EventTarget();
  }

  addEventListener<E extends keyof StepEvents>(event: E, callback: (event: CustomEvent<StepEventDetail>) => void) {
    this.target.addEventListener(event, callback as EventListener);
  }

  removeEventListener<E extends keyof StepEvents>(event: E, callback: (event: CustomEvent<StepEventDetail>) => void) {
    this.target.removeEventListener(event, callback as EventListener);
  }

  dispatchEvent<E extends keyof StepEvents>(event: E, detail: StepEventDetail) {
    this.target.dispatchEvent(new CustomEvent(event, { detail }));
  }
}

export const stepEvents = new StepEventEmitter();
