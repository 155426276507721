export const IconDraft = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.94195 14.9998H2.61493C1.72276 14.9998 0.999512 14.2765 0.999512 13.3844V2.61493C0.999512 1.72276 1.72276 0.999512 2.61493 0.999512H13.3844C14.2765 0.999512 14.9998 1.72276 14.9998 2.61493V8.94145H9.61504C9.2433 8.94145 8.94195 9.2428 8.94195 9.61454V14.9998ZM10.2881 14.3267L14.3272 10.2876H10.2881V14.3267Z"
        fill="currentColor"
      />
    </svg>
  );
};
