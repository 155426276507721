import {
  MachineController,
  ModelController,
  ModelVisibilityController,
  ProjectController,
  StepController,
  UIController,
  useUIStore,
} from '@assemblio/frontend/stores';
import { ThreeEvent } from '@react-three/fiber';
import { useEffect } from 'react';
import { Event, Object3D } from 'three';
import { GLTF } from 'three-stdlib';

interface Props {
  gltf: GLTF;
}

const findClosestGltfIndex = (obj: Object3D | null): number | undefined => {
  if (!obj) {
    return;
  }
  const id = obj.userData['id'];
  if (id !== undefined && (ProjectController.getPartByGltfIndex(id) || ProjectController.getAssemblyByGltfIndex(id))) {
    return id;
  } else {
    return findClosestGltfIndex(obj.parent);
  }
};

export const ImperativeModel = (props: Props) => {
  const onClick = (e: ThreeEvent<Event>) => {
    const selectionActive = useUIStore.getState().selectionActive;
    if (!selectionActive) {
      e.stopPropagation();
      return;
    }
    const gltfIndex = findClosestGltfIndex(e.object);

    if (
      gltfIndex !== undefined &&
      ModelVisibilityController.isPartVisible(gltfIndex) &&
      !ModelController.isPartExcluded(gltfIndex) &&
      selectionActive
    ) {
      e.stopPropagation();
      //Replace with selectable Set in UI Store.
      if (!StepController.getSelectedStep()?.data.parts.some((part) => part.partGltfIndex === gltfIndex)) {
        MachineController.selectTransition(gltfIndex);
      }
    }
  };

  const collisionDetected = useUIStore((state) => state.surfaceCollisionDetected);

  const onContextMenu = (e: ThreeEvent<Event>) => {
    const selectionActive = useUIStore.getState().selectionActive;
    const gltfIndex = findClosestGltfIndex(e.object);

    if (
      gltfIndex !== undefined &&
      ModelVisibilityController.isPartVisible(gltfIndex) &&
      !ModelController.isPartExcluded(gltfIndex) &&
      selectionActive
    ) {
      e.stopPropagation();
      UIController.setPartContext(gltfIndex);
    }
  };

  useEffect(() => {
    if (collisionDetected) {
      UIController.markCollisionOnSelectedParts();
    } else {
      ModelController.setSelectedPartsToUserDefinedColors();
      UIController.highlightSelectedParts();
    }
  }, [collisionDetected]);

  return (
    <primitive
      object={props.gltf.scene}
      onClick={(e: ThreeEvent<Event>) => {
        onClick(e);
      }}
      onContextMenu={onContextMenu}
    />
  );
};
