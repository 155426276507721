import { FolderContentDto, ProjectFoldersDto } from '@assemblio/shared/dtos';
import { axiosHandler } from '../utils/axios-utils';
import { FolderCreate, FolderDelete, FolderUpdate, NextProjectMove } from './types/folder.mutation.types';
import { Folder } from './types/folder.return.types';
import { FolderAndProjectSearchDto } from '@assemblio/shared/next-types';
import { FetchFolder } from './types/folder.query.types';

export const moveNextProject = async (options: NextProjectMove) => {
  return axiosHandler('patch', `/projects/${options.projectId}/folder/${options.targetFolderId}`);
};

export const createFolder = async (folder: FolderCreate): Promise<Folder> => {
  return axiosHandler('post', '/folders', { data: folder.folderData });
};

export const deleteFolder = async (folder: FolderDelete) => {
  return axiosHandler('delete', `/folders/${folder.folderId}`);
};

export const updateFolder = async (folder: FolderUpdate) => {
  return axiosHandler('patch', `/folders/${folder.folderId}`, {
    data: folder.folderData,
  });
};

export const fetchRootFolder = async (): Promise<ProjectFoldersDto> => {
  return axiosHandler('get', `/folders/root`);
};

export const fetchV1RootFolder = async (): Promise<ProjectFoldersDto> => {
  return axiosHandler('get', `v1/folders/root`);
};

export const fetchFolder = async (id: string | undefined): Promise<ProjectFoldersDto> => {
  return typeof id === 'undefined' ? Promise.reject(new Error('Invalid id')) : axiosHandler('get', `folders/${id}`);
};

export const fetchV1Folder = async (data: FetchFolder): Promise<FolderContentDto> => {
  return typeof data.folderId === 'undefined'
    ? Promise.reject(new Error('Invalid id'))
    : axiosHandler('get', `v1/folders/${data.folderId}`, { params: data.queryData });
};

export const searchFoldersProjects = async (searchName: string): Promise<FolderAndProjectSearchDto | null> => {
  return axiosHandler('get', `/folders/search?searchName=${encodeURIComponent(searchName)}`);
};
