import {
  forwardRef,
  MouseEvent as ReactMouseEvent,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { documentToNdc, documentToSvg, ndcToCndc } from '../CoordinateSystems';
export interface DragData {
  x: number;
  y: number;
  localX: number;
  localY: number;
  initialX: number;
  initialY: number;
  initialLocalX: number;
  initialLocalY: number;
  cndcX: number;
  cndcY: number;
  offsetLocalX: number;
  offsetLocalY: number;
}
export type DragHandler = (data: DragData) => { x: number; y: number } | void;
export interface DragInterface {
  setPosition(x: number, y: number): void;
}

export interface Props {
  children: JSX.Element;
  x: number;
  y: number;
  static?: boolean;
  onDrag?: DragHandler;
  onDragEnd?: DragHandler;
}

interface StyleParams {
  dragging: boolean;
}

export const SVGDraggable = forwardRef<DragInterface, Props>(
  (props: Props, ref) => {
    const gRef = useRef<SVGGElement>(null);
    const [dragging, setDragging] = useState(false);

    const [initialPosition, setInitialPosition] = useState<{
      x: number;
      y: number;
      offsetX: number;
      offsetY: number;
    } | null>(null);

    const setPosition = (x: number, y: number) => {
      if (gRef.current) {
        gRef.current.setAttribute('transform', `translate(${x} ${y})`);
      }
    };

    useImperativeHandle(ref, () => {
      return {
        setPosition,
      };
    });

    useEffect(() => {
      const getDragData = (e: MouseEvent): DragData | undefined => {
        if (gRef.current && gRef.current.ownerSVGElement && initialPosition) {
          const { x: localX, y: localY } = documentToSvg(
            {
              x: e.clientX,
              y: e.clientY,
            },
            gRef.current.ownerSVGElement
          );
          const { x: initialLocalX, y: initialLocalY } = documentToSvg(
            {
              x: initialPosition.x,
              y: initialPosition.y,
            },
            gRef.current.ownerSVGElement
          );
          const { x: cndcX, y: cndcY } = ndcToCndc(
            documentToNdc(
              {
                x: e.clientX,
                y: e.clientY,
              },
              gRef.current.ownerSVGElement.parentElement!
            )
          );
          return {
            x: e.clientX,
            y: e.clientY,
            localX,
            localY,
            initialX: initialPosition.x,
            initialY: initialPosition.y,
            initialLocalX,
            initialLocalY,
            cndcX,
            cndcY,
            offsetLocalX: initialLocalX - initialPosition.offsetX,
            offsetLocalY: initialLocalY - initialPosition.offsetY,
          };
        }
        return;
      };

      const handleMouseUp = (e: MouseEvent) => {
        setDragging(false);
        const dragData = getDragData(e);
        if (dragData) {
          props.onDragEnd && props.onDragEnd(dragData);
        }
        setInitialPosition(null);
      };

      const handleMouseMove = (e: MouseEvent) => {
        const dragData = getDragData(e);
        if (dragData) {
          const result = props.onDrag && props.onDrag(dragData);
          if (!props.static) {
            if (result) {
              setPosition(result.x, result.y);
            } else {
              setPosition(
                dragData.localX - dragData.offsetLocalX,
                dragData.localY - dragData.offsetLocalY
              );
            }
          }
        }
      };
      if (dragging) {
        window.addEventListener('mouseup', handleMouseUp);
        window.addEventListener('mousemove', handleMouseMove);
      }
      return () => {
        window.removeEventListener('mouseup', handleMouseUp);
        window.removeEventListener('mousemove', handleMouseMove);
      };
    }, [dragging, props, initialPosition]);

    return (
      <g
        onMouseDown={(e: ReactMouseEvent) => {
          e.preventDefault();
          e.stopPropagation();
          if (gRef.current) {
            const transform = gRef.current.transform.baseVal.getItem(0);
            const rect = gRef.current.getBoundingClientRect();
            setInitialPosition({
              x: e.clientX,
              y: e.clientY,
              offsetX: transform.matrix.e,
              offsetY: transform.matrix.f,
            });
            setDragging(true);
          }
        }}
        style={{
          pointerEvents: dragging ? 'none' : 'auto',
        }}
        transform={`translate(${props.x} ${props.y})`}
        ref={gRef}
      >
        {props.children}
      </g>
    );
  }
);
