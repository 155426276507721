import { useUIStore } from '@assemblio/frontend/stores';
import { Step } from '@assemblio/shared/next-types';

export const useStepDisplayState = (
  step: Step
): {
  isInteractable: boolean;
  selected: boolean;
  animated: boolean;
  highlighted: boolean;
  annotationHighlight: boolean;
} => {
  // const step = useMemo(() => {
  //   if (stepId) {
  //     return StepController.getStep(stepId);
  //   }
  //   return undefined;
  // }, [stepId]);

  const annotationHighlight = useUIStore((state) => {
    return state.highlightedAnnotation !== undefined
      ? step.annotations.includes(state.highlightedAnnotation)
      : false;
  });

  const isInteractable = useUIStore((state) => !state.isAnimating);

  const selected = useUIStore(
    (state) =>
      state.selectedStep !== undefined && state.selectedStep.id === step.id
  );
  const animated = useUIStore((state) => state.highlightedStepSet.has(step.id));

  const highlighted = false;

  return {
    isInteractable,
    selected,
    animated,
    highlighted,
    annotationHighlight,
  };
};
