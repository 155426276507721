import { assign, createActor, fromPromise, setup } from 'xstate';
import { hideAllAnnotations, showAnnotationsInPlayingSequence, stopEditingAnnotations } from './annotation.actions';
import {
  disableSurfaceCollision,
  hideCameraGizmo,
  hideLineRenderer,
  hideTransformGizmo,
  hideViewportControls,
  preventCameraInteraction,
} from './editor.actions';
import { hidePartsInSelectedStepAndFollowing, movePartsToDisassembledPosition } from './model.actions';
import { FetchInstructionResult, VideoContext, VideoEvent } from './types/video.machine.types';
import {
  advance,
  applyStepGroupSelection,
  createAnimations,
  fetchInstruction,
  nextStep,
  notifyComplete,
  notifyError,
  notifyInvalid,
  notifyReadyToCapture,
  notifyReadyToRender,
  projectHasSteps,
  registerEventHandlers,
} from './video.actions';

export const videoMachine = setup({
  types: {
    context: {} as VideoContext,
    events: {} as VideoEvent,
  },
  actions: {
    advance,
    applyStepGroupSelection,
    createAnimations,
    disableSurfaceCollision,
    hideAllAnnotations,
    hideCameraGizmo,
    hideLineRenderer,
    hidePartsInSelectedStepAndFollowing,
    hideTransformGizmo,
    hideViewportControls,
    movePartsToDisassembledPosition,
    nextStep,
    notifyComplete,
    notifyError,
    notifyInvalid,
    notifyReadyToCapture,
    notifyReadyToRender,
    preventCameraInteraction,
    registerEventHandlers,
    showAnnotationsInPlayingSequence,
    stopEditingAnnotations,
  },
  actors: {
    fetchInstruction: fromPromise<FetchInstructionResult, VideoContext>(async ({ input }) => {
      return await fetchInstruction(input);
    }),
  },
}).createMachine({
  id: 'video-machine',
  context: {},
  initial: 'idle',
  states: {
    idle: {
      entry: [
        'registerEventHandlers',
        //General Stuff *salutes*
        'hideTransformGizmo',
        'hideLineRenderer',
        'hideViewportControls',
        'disableSurfaceCollision',
        'hideCameraGizmo',
        'preventCameraInteraction',
        'stopEditingAnnotations',
        'hideAllAnnotations',
      ],
      on: {
        PREPARE: {
          target: 'loading',
          actions: [
            assign({
              exportMessage: ({ event }) => event.message,
            }),
          ],
        },
      },
    },
    loading: {
      invoke: {
        src: 'fetchInstruction',
        input: ({ context }) => ({ ...context }),
        onDone: {
          actions: assign(({ event }) => {
            return {
              ...event.output,
            };
          }),
        },
      },
      on: {
        LOADED: [{ target: 'ready', guard: projectHasSteps }, { target: 'finished' }],
        ERROR: { target: 'error' },
        INVALID: { target: 'invalid' },
      },
    },
    ready: {
      entry: [
        'createAnimations',
        'applyStepGroupSelection',
        'hidePartsInSelectedStepAndFollowing',
        'movePartsToDisassembledPosition',
        'notifyReadyToRender',
      ],
      on: {
        START: { target: 'rendering' },
      },
    },
    rendering: {
      always: { target: 'waiting' },
      exit: ['notifyReadyToCapture'],
    },
    waiting: {
      entry: ['advance'],
      on: {
        CAPTURED: { target: 'rendering' },
        STOP: { target: 'finished' },
      },
    },
    finished: {
      entry: ['notifyComplete'],
      on: {},
    },
    invalid: {
      entry: ['notifyInvalid'],
      on: {},
    },
    error: {
      entry: ['notifyError'],
    },
  },
});

export const videoActor = createActor(videoMachine);
