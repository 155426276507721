import {
  MoveStep,
  StepRename,
  StepSetAnimationSpeed,
  StepSetCamera,
  StepSetData,
  StepSetMTM,
  StepSetPlayWithAbove,
  StepSetText,
  StepSetType,
} from './types/step.mutation.types';
import { axiosHandler } from '../utils/axios-utils';
import { CreateStepDto, StepDto } from '@assemblio/shared/next-types';
import Joi from 'joi';

const STEP_URL = '/steps';

export const createStep = async (data: CreateStepDto): Promise<StepDto> => {
  return axiosHandler('post', STEP_URL, {
    data: data,
  });
};

export const renameStep = async ({ id, data }: StepRename) => {
  return axiosHandler('patch', `${STEP_URL}/${id}/rename`, { data });
};

export const setPlayWithAbove = async ({ id, data }: StepSetPlayWithAbove) => {
  // Updates the 'play-with-above' setting. If data is true, it sets it. If false, it removes it.
  if (data) {
    return axiosHandler('put', `${STEP_URL}/${id}/play-with-above`);
  } else {
    return axiosHandler('delete', `${STEP_URL}/${id}/play-with-above`);
  }
};

export const deleteStep = async (id: string) => {
  return axiosHandler('delete', `${STEP_URL}/${id}`);
};

export const setType = async ({ id, data }: StepSetType) => {
  return axiosHandler('patch', `${STEP_URL}/${id}/type`, { data });
};

export const setCamera = async ({ id, data }: StepSetCamera) => {
  return axiosHandler('patch', `${STEP_URL}/${id}/camera-settings`, { data });
};

export const updateStepData = async ({ id, data }: StepSetData) => {
  return axiosHandler('put', `${STEP_URL}/${id}/data`, { data });
};

export const setAnimationSpeed = async ({ id, data }: StepSetAnimationSpeed) => {
  return axiosHandler('patch', `${STEP_URL}/${id}/animation-speed`, { data });
};

export const setMTM = async ({ id, data }: StepSetMTM) => {
  return axiosHandler('patch', `${STEP_URL}/${id}/mtm`, { data });
};

export const moveStep = async ({ id, data }: MoveStep) => {
  return axiosHandler('patch', `${STEP_URL}/${id}/move`, { data });
};

export const setStepText = async ({ id, data }: StepSetText) => {
  return axiosHandler('put', `${STEP_URL}/${id}/text`, { data });
};

export const deleteStepText = async (stepId: string) => {
  return axiosHandler('delete', `${STEP_URL}/${stepId}/text`);
};
