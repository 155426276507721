import { axiosHandlerWithResponse, axiosHandler } from '../utils';
import { UploadBrandingFile } from './types/branding.requests.types';
import { BrandingResponseDTO, UpdateBrandingDTO } from '@assemblio/shared/dtos';
import { BrandingFileType } from '@assemblio/type/branding';

export const fetchBrandingOptions = async (): Promise<BrandingResponseDTO> => {
  return axiosHandler('get', '/branding');
};

export const fetchBrandingFile = async (type: BrandingFileType): Promise<Blob | null> => {
  const response = await axiosHandlerWithResponse('get', `/branding/file/${type}`, { responseType: 'blob' });

  if (response.status === 204 || response.status === 404) {
    return null;
  }

  return response.data;
};

export const uploadBrandingFile = async (upload: UploadBrandingFile) => {
  const formData = new FormData();
  formData.append('file', upload.file);
  return axiosHandler('post', `/branding/file/${upload.type}`, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: formData,
  });
};

export const updateBrandingOptions = async (options: UpdateBrandingDTO): Promise<BrandingResponseDTO> => {
  return axiosHandler('put', '/branding', { data: options });
};
