import { TransformDto, TextDto } from '@assemblio/shared/dtos';
import { Step, StepType } from '../../interfaces';
import { Quaternion, Vector } from '@assemblio/type/3d';

export class CreateStepDto {
  id!: string;
  stepGroupId!: string;
  name!: string;
  previousStepId!: string | null;
  cameraSettings!: StepCameraSettingsDto;
  data!: StepDataDto;
  playWithAbove!: boolean;
}

export const stepToCreateDto = (step: Step, prevStepId: string | null): CreateStepDto => {
  return {
    id: step.id,
    name: step.name,
    playWithAbove: step.playWithAbove,
    stepGroupId: step.stepGroupId,
    previousStepId: prevStepId,
    cameraSettings: step.cameraSettings,
    data: step.data,
  };
};

export class StepNameDto {
  name!: string;
}

export class StepMTMDto {
  mtm!: number;
}

export class StepAnimationSpeedDto {
  animationSpeed!: number;
}

export class StepTypeDto {
  type!: StepType;
}

export class PatchStepCameraSettingsDto {
  cameraSettings!: StepCameraSettingsDto;
}

export class StepDto {
  id!: string;
  name!: string;
  stepGroupId!: string;
  next!: string | null;
  prev!: string | null;
  type!: StepType;
  playWithAbove!: boolean;
  animationSpeed!: number;
  mtm!: number;
  cameraSettings!: StepCameraSettingsDto;
  data!: StepDataDto;
  text!: TextDto | null;
  annotations!: AnnotationToStepDto[];
}

export interface PartProperty {
  partIds: string[];
  partGroupIds: string[];
}

export class StepCameraSettingsDto {
  near!: number;
  far!: number;
  zoom!: number;
  distance!: number;
  projection!: number;
  transform!: StepCameraTransformDto;
}

export class StepCameraTransformDto {
  rotation!: Quaternion;
  position!: Vector;
}

export class AnnotationToStepDto {
  id?: string;
}

export class StepDataDto {
  path!: Array<TransformDto>;
  parts!: Array<PartInStepDto>;
}

export class PartInStepDto {
  partGltfIndex!: number;
  start!: TransformDto;
  end!: TransformDto;
}
