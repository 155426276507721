import { ProductSortProps } from '@assemblio/type/product';
import { SortingOption } from '../../../types';

export const FAVORITE_QUERY_SORTING_OPTIONS: SortingOption<ProductSortProps>[] = [
  {
    value: 'name-desc',
    queryValue: ProductSortProps.name,
    label: 'Name (A to Z)',
    ascending: true,
  },
  {
    value: 'name-asc',
    queryValue: ProductSortProps.name,
    label: 'Name (Z to A)',
    ascending: false,
  },
  {
    value: 'updatedAt',
    queryValue: ProductSortProps.updatedAt,
    label: 'Last modified',
    ascending: true,
  },
  {
    value: 'createdAt',
    queryValue: ProductSortProps.createdAt,
    label: 'Oldest created',
    ascending: false,
  },
  {
    value: 'state',
    queryValue: ProductSortProps.state,
    label: 'State (A to Z)',
    ascending: false,
  },
];
