import { JobMessage, Progress, State } from '@assemblio/type/job';
import produce from 'immer';
import { EventStore, useEventStore } from '../stores/EventsStore';

export namespace EventController {
  export const connectSocket = () => {
    useEventStore.getState().socket.connect();
  };

  export const setConnected = (isConnected: boolean) => {
    useEventStore.setState(
      produce<EventStore>((state) => {
        state.connected = isConnected;
      })
    );
  };

  export const setJobProgress = (message: JobMessage) => {
    useEventStore.setState(
      produce<EventStore>((state) => {
        state.jobProgress.set(
          message.jobId,
          +(message.payload as Progress).fraction
        );
      })
    );
  };

  export const deleteJobProgress = (jobId: string) => {
    useEventStore.setState(
      produce<EventStore>((state) => {
        state.jobProgress.delete(jobId);
      })
    );
  };

  export const setJobState = (message: JobMessage) => {
    useEventStore.setState(
      produce<EventStore>((state) => {
        state.jobStates.set(message.jobId, (message.payload as State).state);
      })
    );
  };

  export const deleteJobState = (jobId: string) => {
    useEventStore.setState(
      produce<EventStore>((state) => {
        state.jobStates.delete(jobId);
      })
    );
  };

  export const removeJob = (jobId: string) => {
    deleteJobProgress(jobId);
    deleteJobState(jobId);
    deleteJobMessages(jobId);
  };

  export const disconnectSocket = () => {
    useEventStore.getState().socket.disconnect();
  };

  const deleteJobMessages = (jobId: string) => {
    useEventStore.setState(
      produce<EventStore>((state) => {
        state.jobMessages.delete(jobId);
      })
    );
  };
}
