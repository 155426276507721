import { ModelController } from '@assemblio/frontend/stores';
import Papa, { ParseResult } from 'papaparse';
import { useCallback, useEffect, useState } from 'react';

interface CSVRow {
  [key: string]: string;
}
type CSVData = Array<CSVRow>;

export type PartAssemblyWithName = {
  gltfIndex: number;
  currentName: string;
  newName: string;
};

export const PARSE_ERROR_CODES = {
  TOO_FEW_FIELDS: 'TooFewFields',
  UNDETECTABLE_DELIMITER: 'UndetectableDelimiter',
};

export const useCSVData = () => {
  const [csvData, setCSVData] = useState<CSVData>([]);
  const [csvHeader, setCSVHeader] = useState<string[] | undefined>([]);
  const [file, setFile] = useState<File | null>(null);
  const [parsingErrors, setParsingErrors] = useState<
    Array<Papa.ParseError | { code: string; row: undefined }>
  >([]);
  const [hasHeader, setHasHeader] = useState<boolean>(true);

  const handleCSVImport = (file: File | null) => {
    if (file) {
      const fileNameParts = file.name.split('.');
      const extension = fileNameParts[fileNameParts.length - 1].toLowerCase();

      if (extension === 'csv') {
        setFile(file);
      } else {
        setParsingErrors([
          {
            code: 'Invalid file type. Please upload a CSV file.',
            row: undefined,
          },
        ]);
      }
    }
  };

  const parseFile = useCallback(
    (file: File) => {
      Papa.parse(file, {
        complete: (results: ParseResult<object>) => {
          const castedData: CSVData = results.data as CSVData;
          // Filter data to remove rows that are all empty strings
          const cleanedData = castedData.filter((row) => {
            const fieldNames = Object.keys(row);
            return (
              Object.values(row).some((value) => value.trim() !== '') &&
              row[fieldNames[0]] !== row[fieldNames[1]]
            );
          });

          // Filter errors to remove "TooFewFields" errors for the last row
          const cleanedErrors = results.errors.filter((error) => {
            return !(
              error.code === PARSE_ERROR_CODES.TOO_FEW_FIELDS &&
              error.row === cleanedData.length
            );
          });

          const hasUndetectableDelimiter = results.errors.some(
            (error) => error.code === PARSE_ERROR_CODES.UNDETECTABLE_DELIMITER
          );

          if (!hasUndetectableDelimiter) {
            setCSVData(cleanedData);
            setCSVHeader(results.meta.fields);
          }

          setParsingErrors(cleanedErrors);
        },
        header: hasHeader,
        skipEmptyLines: true,
      });
    },
    [hasHeader]
  );

  useEffect(() => {
    if (file) parseFile(file);
  }, [file, parseFile]);

  const handleClearCSV = () => {
    setCSVData([]);
    setCSVHeader([]);
    setFile(null);
    setParsingErrors([]);
  };

  return {
    csvData,
    csvHeader,
    file,
    parsingErrors,
    hasHeader,
    setHasHeader,
    handleCSVImport,
    handleClearCSV,
  };
};

export const createRenamingList = (
  data: CSVData,
  header: string[] | undefined
) => {
  let list: {
    old: string;
    new: string;
  }[];

  if (!header) {
    list = data.map((element) => {
      return { old: element[0], new: element[1] };
    });
  } else if (header.length === 2) {
    const headerOld = header[0];
    const headerNew = header[1];

    list = data.map((element) => {
      return { old: element[headerOld], new: element[headerNew] };
    });
  } else {
    return [];
  }

  return getPartAssemblyListByNames(list);
};

const getPartAssemblyListByNames = (
  namePairs: {
    old: string;
    new: string;
  }[]
): PartAssemblyWithName[] => {
  const partAssemblyWithNames: PartAssemblyWithName[] = [];

  namePairs.forEach((namePair) => {
    const partsByName = ModelController.getPartsByCurrentName(namePair.old);
    const assembliesByName = ModelController.getAssembliesByCurrentName(
      namePair.old
    );

    const namedParts = partsByName.map((part) => ({
      gltfIndex: part.gltfIndex,
      currentName: namePair.old,
      newName: namePair.new,
    }));
    const namedAssemblies = assembliesByName.map((assembly) => ({
      gltfIndex: assembly.gltfIndex,
      currentName: namePair.old,
      newName: namePair.new,
    }));

    partAssemblyWithNames.push(...namedParts, ...namedAssemblies);
  });

  partAssemblyWithNames.sort((a, b) => a.gltfIndex - b.gltfIndex);

  return partAssemblyWithNames;
};
