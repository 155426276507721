import { Box, Button, Stack, Text } from '@mantine/core';

export const ErrorScreen = () => {
  const refreshPage = () => {
    window.location.reload();
  };
  return (
    <Box
      style={{
        background: '#251E29',
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Stack>
        <Text c="white" size={'xl'}>
          Something went wrong
        </Text>
        <Button onClick={refreshPage}>Reload Page</Button>
      </Stack>
    </Box>
  );
};
