import { ActiveUserData, AppRole } from '@assemblio/type/user';
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import { useAuthStore } from '../stores/AuthStore';

const BASE_URL = '/api';

// Client without interceptors for login and logout
const client = axios.create({ baseURL: BASE_URL });

export const axiosBaseRequest = ({ ...options }) => {
  return client(options);
};

export const refreshTokens = async () => {
  try {
    const response = await axiosBaseRequest({
      url: '/auth/refresh-tokens',
      method: 'get',
      withCredentials: true,
    });

    const accessToken = response?.data?.accessToken;
    if (!accessToken) {
      return useAuthStore.getState().clearSession();
    }
    const user = jwtDecode<ActiveUserData>(accessToken);

    useAuthStore.getState().setSession({
      userId: user.sub,
      email: user.email,
      accessToken,
      appRole: user.tenant.role as AppRole,
      organizationId: user.tenant.id,
      tenant: user.tenant,
    });

    return accessToken;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
