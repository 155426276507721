import { LineBasicMaterial, LineSegments, Mesh, MeshStandardMaterial, Object3D } from 'three';

/*
  THESE FUNCTIONS SHOULD ONLY BE CALLED BY ModelController and ModelVisibilityController
  FUNCTIONS. THEIR STATE IS NOT TRACKED OR EASILY ACCESSIBLE IF USED INDEPENDENTLY.
*/
export namespace ImperativeModelController {
  // Maps gltf indices to objects of a three js model
  const modelIndex = new Map<number, Object3D>();

  export const getModel = (gltfIndex: number): Object3D | undefined => {
    return modelIndex.get(gltfIndex);
  };

  export const setModel = (gltfIndex: number, model: Object3D): void => {
    modelIndex.set(gltfIndex, model);
  };

  export const clearModel = (gltfIndex: number): void => {
    modelIndex.delete(gltfIndex);
  };

  export const setColor = (gltfIndex: number, color: number) => {
    const model = getModel(gltfIndex);
    if (model) {
      setModelColor(model, color);
    }
  };

  export const setVisible = (gltfIndex: number, visible: boolean) => {
    const model = getModel(gltfIndex);
    if (model) {
      model.visible = visible;
    }
  };

  export const excludeModel = (gltfIndex: number) => {
    const model = getModel(gltfIndex);
    if (model) {
      model.visible = false;
      setModelTransparency(model, 0);
    }
  };

  export const setModelTransparency = (model: Object3D, transparency: number) => {
    switch (model.type) {
      case 'Mesh':
        ((model as Mesh).material as MeshStandardMaterial).opacity = transparency;
        ((model as Mesh).material as MeshStandardMaterial).transparent = true;
        ((model as Mesh).material as MeshStandardMaterial).depthWrite = true;
        break;

      case 'LineSegments':
        ((model as LineSegments).material as LineBasicMaterial).opacity = transparency;
        ((model as LineSegments).material as LineBasicMaterial).transparent = true;
        ((model as LineSegments).material as LineBasicMaterial).depthWrite = true;
        break;
    }
    model.children.forEach((gltfIndex) => {
      setModelTransparency(gltfIndex, transparency);
    });
  };

  export const setTransparent = (gltfIndex: number, transparent: boolean) => {
    const model = getModel(gltfIndex);
    if (model) {
      setModelTransparent(model, transparent);
    }
  };

  export const setTransparency = (gltfIndex: number, transparency: number) => {
    const model = getModel(gltfIndex);
    if (model) {
      setModelTransparency(model, transparency);
    }
  };

  const setModelColor = (model: Object3D, color: number) => {
    if (model.type === 'Mesh') {
      const material = (model as Mesh).material as MeshStandardMaterial;
      material.color.setHex(color, 'srgb-linear');
    } else {
      /* Model is a multi part mesh */
      model.children.forEach((gltfIndex) => {
        setModelColor(gltfIndex, color);
      });
    }
  };

  const setModelTransparent = (model: Object3D, transparent: boolean) => {
    if (model.type === 'Mesh') {
      ((model as Mesh).material as MeshStandardMaterial).opacity = transparent ? 0.5 : 1.0;
      ((model as Mesh).material as MeshStandardMaterial).transparent = transparent;
    } else {
      /* Model is a multi part mesh */
      model.children.forEach((gltfIndex) => {
        setModelTransparent(gltfIndex, transparent);
      });
    }
  };

  export const reset = () => {
    modelIndex.clear();
  };
}
