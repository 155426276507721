import produce from 'immer';
import { create } from 'zustand';

export interface ProjectIndices {
  assemblyParents: Map<number, number>;
  partParents: Map<number, number>;
  gltfIndexToAssemblyIndexMap: Map<number, number>;
  gltfIndexToPartIndexMap: Map<number, number>;
  assemblyIndexToGltfIndexMap: Map<number, number>;
  partIndexToGltfIndexMap: Map<number, number>;
}

export const useProjectIndices = create<ProjectIndices>(() => ({
  assemblyParents: new Map<number, number>(),
  partParents: new Map<number, number>(),
  gltfIndexToAssemblyIndexMap: new Map<number, number>(),
  gltfIndexToPartIndexMap: new Map<number, number>(),
  assemblyIndexToGltfIndexMap: new Map<number, number>(),
  partIndexToGltfIndexMap: new Map<number, number>(),
}));

export const setPartParent = (part: number, parent: number) => {
  useProjectIndices.setState(
    produce<ProjectIndices>((state: ProjectIndices) => {
      state.partParents.set(part, parent);
    })
  );
};

export const setAssemblyParent = (assembly: number, parent: number) => {
  useProjectIndices.setState(
    produce<ProjectIndices>((state: ProjectIndices) => {
      state.assemblyParents.set(assembly, parent);
    })
  );
};

export const clear = () => {
  useProjectIndices.setState(
    produce<ProjectIndices>((state: ProjectIndices) => {
      state.assemblyParents.clear();
      state.partParents.clear();
      state.assemblyIndexToGltfIndexMap.clear();
      state.partIndexToGltfIndexMap.clear();
      state.gltfIndexToAssemblyIndexMap.clear();
      state.gltfIndexToPartIndexMap.clear();
    })
  );
};

