import { ResizableContainerRef } from '@assemblio/frontend/types';
import { useHotkeys } from '@mantine/hooks';

export const useEditorShortcuts = (
  hierarchyContainer: React.RefObject<ResizableContainerRef>,
  sequencerContainer: React.RefObject<ResizableContainerRef>
) => {
  useHotkeys([
    [
      'alt+c',
      () => {
        hierarchyContainer.current?.collapse();
        sequencerContainer.current?.collapse();
      },
    ],
    [
      'alt+e',
      () => {
        hierarchyContainer.current?.expand();
        sequencerContainer.current?.expand();
      },
    ],
  ]);
};
