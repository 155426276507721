import { Step, StepGroup } from '@assemblio/shared/next-types';
import produce from 'immer';
import { create } from 'zustand';
import { useSequenceStore } from '../stores';

export type StepIndex = { stepGroupIndex: number; stepIndex: number };

export interface StepIndices {
  // Maps GLTF Indices to Step Ids
  modelStepMap: Map<number, Set<string>>;
  // Maps Step Ids to Step Indexes (faster lookup)
  stepMap: Map<string, StepIndex>;
  stepGroupMap: Map<string, number>;
  reset: () => void;
}

const init = () => ({
  modelStepMap: new Map<number, Set<string>>(),
  stepMap: new Map<string, StepIndex>(),
  stepGroupMap: new Map<string, number>(),
});

export const useStepIndex = create<StepIndices>((set) => ({
  ...init(),
  reset: () => {
    set(init());
  },
}));

const syncStep = (step: Step, stepIndices: StepIndex) => {
  useStepIndex.setState(
    produce<StepIndices>((state: StepIndices) => {
      state.stepMap.set(step.id, stepIndices);
      step.data &&
        step.data.parts.forEach((part) => {
          if (!state.modelStepMap.has(part.partGltfIndex)) {
            state.modelStepMap.set(part.partGltfIndex, new Set<string>());
          }
          if (step.id) {
            state.modelStepMap.get(part.partGltfIndex)?.add(step.id);
          }
        });
    })
  );
};

const associateModelAndStep = (gltfIndex: number, stepId: string) => {
  useStepIndex.setState(
    produce<StepIndices>((state: StepIndices) => {
      if (!state.modelStepMap.has(gltfIndex)) {
        state.modelStepMap.set(gltfIndex, new Set<string>());
      }
      if (stepId) {
        state.modelStepMap.get(gltfIndex)?.add(stepId);
      }
    })
  );
};

const disassociateModelAndStep = (gltfIndex: number, stepId: string) => {
  useStepIndex.setState(
    produce<StepIndices>((state: StepIndices) => {
      const modelToStep = state.modelStepMap.get(gltfIndex);
      if (modelToStep) {
        modelToStep.delete(stepId);
        if (modelToStep.size === 0) state.modelStepMap.delete(gltfIndex);
      }
    })
  );
};

const updateStepMap = (
  stepId: string,
  sourceGroupIndex: number,
  destinationGroupIndex: number,
  destinationIndex: number
) => {
  useStepIndex.setState(
    produce<StepIndices>((state: StepIndices) => {
      const currentStepIndex = state.stepMap.get(stepId);
      if (!currentStepIndex) {
        console.warn('Step not found in the current state');
        return;
      }

      const sourceSteps = useSequenceStore.getState().stepGroups[sourceGroupIndex].steps;

      if (sourceGroupIndex === destinationGroupIndex) {
        const start = Math.min(currentStepIndex.stepIndex, destinationIndex);
        const end = Math.max(currentStepIndex.stepIndex, destinationIndex);

        for (let i = start; i <= end; i++) {
          state.stepMap.set(sourceSteps[i].id, {
            stepGroupIndex: sourceGroupIndex,
            stepIndex: i,
          });
        }
      } else {
        const destSteps = useSequenceStore.getState().stepGroups[destinationGroupIndex].steps;

        for (let i = currentStepIndex.stepIndex; i < sourceSteps.length; i++) {
          state.stepMap.set(sourceSteps[i].id, {
            stepGroupIndex: sourceGroupIndex,
            stepIndex: i,
          });
        }

        for (let i = destinationIndex; i < destSteps.length; i++) {
          state.stepMap.set(destSteps[i].id, {
            stepGroupIndex: destinationGroupIndex,
            stepIndex: i,
          });
        }
      }
    })
  );
};

const updateStepGroupMap = (fromIndex: number, toIndex: number) => {
  useStepIndex.setState(
    produce<StepIndices>((state: StepIndices) => {
      if (fromIndex !== toIndex) {
        const stepGroups = useSequenceStore.getState().stepGroups;
        const start = Math.min(fromIndex, toIndex);
        const end = Math.max(fromIndex, toIndex);

        for (let i = start; i <= end; i++) {
          state.stepGroupMap.set(stepGroups[i].id, i);
          for (const step of stepGroups[i].steps) {
            const stepIndex = state.stepMap.get(step.id);
            if (stepIndex) {
              stepIndex.stepGroupIndex = i;
            }
          }
        }
      }
    })
  );
};

const desyncStep = (step: Step) => {
  useStepIndex.setState(
    produce<StepIndices>((state: StepIndices) => {
      const index = state.stepMap.get(step.id);
      if (index !== undefined) {
        state.stepMap.delete(step.id);
        const length = useSequenceStore.getState().stepGroups[index.stepGroupIndex].steps.length;

        for (let i = index.stepIndex; i < length; i++) {
          state.stepMap.set(useSequenceStore.getState().stepGroups[index.stepGroupIndex].steps[i].id, {
            stepGroupIndex: index.stepGroupIndex,
            stepIndex: i,
          });
        }

        step.data &&
          step.data.parts.forEach((part) => {
            const modelSteps = state.modelStepMap.get(part.partGltfIndex);
            if (modelSteps) {
              const stepIds = Array.from(modelSteps).filter((stepId) => stepId !== step.id);
              if (stepIds.length === 0) state.modelStepMap.delete(part.partGltfIndex);
              else {
                state.modelStepMap.set(part.partGltfIndex, new Set(stepIds));
              }
            }
          });
      }
    })
  );
};

const syncStepGroup = (stepGroup: StepGroup, stepGroupIndex: number) => {
  useStepIndex.setState(
    produce<StepIndices>((state: StepIndices) => {
      state.stepGroupMap.set(stepGroup.id, stepGroupIndex);
    })
  );
};

const desyncStepGroup = (stepGroupId: string) => {
  let index;
  useStepIndex.setState(
    produce<StepIndices>((state: StepIndices) => {
      index = state.stepGroupMap.get(stepGroupId);

      if (index !== undefined) {
        const deleted = state.stepGroupMap.delete(stepGroupId);
        if (deleted) {
          const length = useSequenceStore.getState().stepGroups.length;

          for (let i = index; i < length; i++) {
            state.stepGroupMap.set(useSequenceStore.getState().stepGroups[i].id, i);
          }
        }
      }
    })
  );
  if (index !== undefined) {
    syncStepGroupsFromIndex(index);
  }
};

const syncStepGroupsFromIndex = (index: number) => {
  if (index < 0 || index > useSequenceStore.getState().stepGroups.length) return;
  useStepIndex.setState(
    produce<StepIndices>((state: StepIndices) => {
      for (let i = index; i < useSequenceStore.getState().stepGroups.length; i++) {
        const group = useSequenceStore.getState().stepGroups[i];
        state.stepGroupMap.set(group.id, i);
        group.steps.forEach((step) => {
          const stepIndex = state.stepMap.get(step.id);
          if (stepIndex) {
            stepIndex.stepGroupIndex = i;
            state.stepMap.set(step.id, stepIndex);
          }
        });
      }
    })
  );
};

const getStepGroupIndex = (stepGroupId: string): number => {
  const index = useStepIndex.getState().stepGroupMap.get(stepGroupId);
  if (index === undefined) {
    throw new Error(`Expected stepGroupId '${stepGroupId}' to be present in the map`);
  }

  return index;
};

const getStep = (stepId: string): Step => {
  const index = useStepIndex.getState().stepMap.get(stepId);
  if (index === undefined) {
    throw new Error(`Expected stepId '${stepId}' to be present in the map`);
  }
  return useSequenceStore.getState().stepGroups[index.stepGroupIndex].steps[index.stepIndex];
};

const getStepIndex = (stepId: string): StepIndex => {
  const index = useStepIndex.getState().stepMap.get(stepId);

  if (index === undefined) {
    throw new Error(`Expected stepId '${stepId}' to be present in the map`);
  }

  return index;
};

const hasStep = (gltfIndex: number): boolean => {
  return useStepIndex.getState().modelStepMap.has(gltfIndex);
};

const getStepsByGltfIndex = (gltfIndex: number): Step[] => {
  const stepIds = useStepIndex.getState().modelStepMap.get(gltfIndex);
  if (stepIds) {
    return Array.from(stepIds)
      .map((id) => getStep(id))
      .filter((value) => value !== undefined) as Step[];
  }
  return [];
};

const getStepsByGltfIndexInDisassemblyOrder = (gltfIndex: number): Step[] => {
  const stepIds = useStepIndex.getState().modelStepMap.get(gltfIndex);
  if (stepIds) {
    return useSequenceStore.getState().stepGroups.flatMap((g) => {
      return g.steps.filter((step) => stepIds.has(step.id));
    });
  }
  return [];
};

const getStepsByGltfIndexInAssemblyOrder = (gltfIndex: number): Step[] => {
  return getStepsByGltfIndexInDisassemblyOrder(gltfIndex).reverse();
};

export const StepIndex = {
  associateModelAndStep,
  disassociateModelAndStep,
  syncStep,
  updateStepMap,
  desyncStep,
  syncStepGroup,
  updateStepGroupMap,
  desyncStepGroup,
  getStepGroupIndex,
  hasStep,
  getStepsByGltfIndex,
  getStepsByGltfIndexInAssemblyOrder,
  getStepsByGltfIndexInDisassemblyOrder,
  getStep,
  getStepIndex,
};
