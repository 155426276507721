import { useModelStore } from '@assemblio/frontend/stores';
import { Line } from '@react-three/drei';
import { useMemo } from 'react';
import { Color, Vector3 } from 'three';

interface GridProps {
  normal?: Vector3;
  radius: number;
  spacing: number;
  lineColor: string;
  lineWidth: number;
}

const generateGridPoints = (
  radius: number,
  spacing: number,
  yOffset: number
) => {
  const points: Vector3[][] = [];
  for (let x = -radius + 1; x < radius; x += spacing) {
    points.push([
      new Vector3(x, yOffset, -radius),
      new Vector3(x, yOffset, radius),
    ]);
    points.push([
      new Vector3(-radius, yOffset, x),
      new Vector3(radius, yOffset, x),
    ]);
  }
  return points;
};

export const Grid = (props: GridProps) => {
  const { radius, spacing } = props;
  const yOffset = useModelStore((state) => state.yOffset);

  const points = useMemo(
    () => generateGridPoints(radius, spacing, yOffset),
    [radius, spacing]
  );
  return (
    <>
      {points.map((points: Vector3[]) => (
        <Line
          key={`grid-line-${points[0].x}-${points[1].z}`}
          points={points}
          color={new Color(props.lineColor).getHex()}
          opacity={0.1}
          lineWidth={props.lineWidth}
        />
      ))}
    </>
  );
};
