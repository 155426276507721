import {
  Paper,
  ScrollArea,
  Skeleton,
  Stack,
  Table,
  useMantineColorScheme,
  useMantineTheme,
} from '@mantine/core';
import classes from './ProjectExplorer.module.scss';
import { ErrorScreen } from './ErrorScreen';
import { ProjectRow } from './RowItems/ProjectRow';
import { FolderRow } from './RowItems/FolderRow';
import { InstructionRow } from './RowItems/InstructionRow';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { createRef } from 'react';
import { CSSTransitionProps } from 'react-transition-group/CSSTransition';
import { ExplorerItem } from './types';

interface ListViewProps {
  items: ExplorerItem[];
  error: boolean;
  loading: boolean;
}

const sharedTransitionProps: CSSTransitionProps = {
  timeout: Number(classes.animation_duration),
  classNames: {
    enter: classes['item-enter'],
    enterActive: classes['item-enter-active'],
    exit: classes['item-exit'],
    exitActive: classes['item-exit-active'],
  },
};

export const ListView = ({ items, error, loading }: ListViewProps) => {
  const theme = useMantineTheme();
  const { colorScheme } = useMantineColorScheme();
  const rows = items.map((item) => {
    const ref = createRef<HTMLTableRowElement>();
    switch (item.type) {
      case 'project':
        return (
          <CSSTransition
            key={`row-${item.id}`}
            nodeRef={ref}
            {...sharedTransitionProps}
          >
            <ProjectRow {...item.handlers} {...item.props} ref={ref} />
          </CSSTransition>
        );
      case 'folder':
        return (
          <CSSTransition
            key={`row-${item.id}`}
            nodeRef={ref}
            {...sharedTransitionProps}
          >
            <FolderRow {...item.props} {...item.handlers} ref={ref} />
          </CSSTransition>
        );
      case 'instruction':
        return (
          <CSSTransition
            key={`row-${item.id}`}
            nodeRef={ref}
            {...sharedTransitionProps}
          >
            <InstructionRow {...item.props} {...item.handlers} ref={ref} />
          </CSSTransition>
        );
      default:
        return null;
    }
  });

  return (
    <Paper radius={'lg'} className={classes.mainGrid__explorerPanel}>
      {!error ? (
        <ScrollArea
          h={'100%'}
          w={'100%'}
          p={'lg'}
          classNames={{
            scrollbar: classes.customScrollbar__bar,
            corner: classes.customScrollbar__corner,
            viewport: classes.fullWidth__scrollArea__viewport,
          }}
          type="scroll"
        >
          {loading ? (
            <Stack gap={'2px'}>
              <Skeleton h={'50px'} w={'100%'} />
              <Skeleton h={'50px'} w={'100%'} />
              <Skeleton h={'50px'} w={'100%'} />
              <Skeleton h={'50px'} w={'100%'} />
            </Stack>
          ) : (
            <Table
              withRowBorders={true}
              className={classes.table}
              striped
              w={'100%'}
              stripedColor={theme.other.color.editor.black_10}
              highlightOnHover
              highlightOnHoverColor={
                colorScheme === 'dark'
                  ? theme.other.color.editor.white_10
                  : theme.other.color.editor.black_20
              }
            >
              <Table.Tbody>
                <TransitionGroup component={null}>{rows}</TransitionGroup>
              </Table.Tbody>
            </Table>
          )}
        </ScrollArea>
      ) : (
        <ErrorScreen />
      )}
    </Paper>
  );
};
