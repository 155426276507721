import { assign, createActor, emit, fromPromise, sendTo, setup } from 'xstate';
import { hideAllAnnotations, showAnnotationsInPlayingSequence, stopEditingAnnotations } from './annotation.actions';
import {
  disableSurfaceCollision,
  hideCameraGizmo,
  hideLineRenderer,
  hideTransformGizmo,
  hideViewportControls,
  preventCameraInteraction,
} from './editor.actions';
import {
  advance,
  applyStepGroupSelection,
  checkProjectValidity,
  createAnimations,
  fetchInstruction,
  notifyAwaitingCommand,
  notifyComplete,
  notifyError,
  notifyInvalid,
  notifyReadyToCapture,
  notifyReadyToRender,
  notifyTaskCompleted,
  registerEventHandlers,
  setViewportOptions,
} from './media-export.actions';
import { hidePartsInSelectedStepAndFollowing, movePartsToDisassembledPosition } from './model.actions';
import { FetchInstructionResult, MediaExportContext, MediaExportEvent } from './types/media-export.machine.types';

export const videoExportMachine = setup({
  types: {
    context: {} as MediaExportContext,
    events: {} as MediaExportEvent,
  },
  actions: {
    checkProjectValidity,
    createAnimations,
    applyStepGroupSelection,
    hidePartsInSelectedStepAndFollowing,
    movePartsToDisassembledPosition,
    notifyReadyToRender,
    showAnnotationsInPlayingSequence,
    notifyReadyToCapture,
    advance,
  },
}).createMachine({
  id: 'video-export-machine',
  context: ({ input }) => ({ ...input }),
  initial: 'ready',
  states: {
    ready: {
      entry: [
        'checkProjectValidity',
        'createAnimations',
        'applyStepGroupSelection',
        'hidePartsInSelectedStepAndFollowing',
        'movePartsToDisassembledPosition',
        'notifyReadyToRender',
      ],
      on: {
        START: { target: 'rendering' },
      },
    },
    rendering: {
      always: { target: 'waiting' },
      exit: ['notifyReadyToCapture'],
    },
    waiting: {
      entry: ['advance'],
      on: {
        CAPTURED: { target: 'rendering' },
        STOP: { target: 'finalWait' },
      },
    },
    finalWait: {
      on: {
        CAPTURED: { target: 'finished' },
      },
    },
    finished: {
      type: 'final',
    },
  },
});

export const coverExportMachine = setup({
  types: {
    context: {} as MediaExportContext,
    events: {} as MediaExportEvent,
  },
  actions: { notifyReadyToRender, notifyReadyToCapture, hideAllAnnotations },
}).createMachine({
  id: 'cover-export-machine',
  context: ({ input }) => ({ ...input }),
  initial: 'ready',
  states: {
    ready: {
      entry: ['hideAllAnnotations', 'notifyReadyToRender'],
      on: {
        START: { target: 'rendering' },
      },
    },
    rendering: {
      always: { target: 'waiting' },
      exit: ['notifyReadyToCapture'],
    },
    waiting: {
      on: {
        CAPTURED: { target: 'finished' },
      },
    },
    finished: {
      type: 'final',
    },
  },
});

export const mediaExportMachine = setup({
  types: {
    context: {} as MediaExportContext,
    events: {} as MediaExportEvent,
  },
  actions: {
    applyStepGroupSelection,
    createAnimations,
    disableSurfaceCollision,
    hideAllAnnotations,
    hideCameraGizmo,
    hideLineRenderer,
    hidePartsInSelectedStepAndFollowing,
    hideTransformGizmo,
    hideViewportControls,
    movePartsToDisassembledPosition,
    notifyComplete,
    notifyError,
    notifyInvalid,
    notifyAwaitingCommand,
    notifyTaskCompleted,
    preventCameraInteraction,
    registerEventHandlers,
    setViewportOptions,
    showAnnotationsInPlayingSequence,
    stopEditingAnnotations,
  },
  actors: {
    fetchInstruction: fromPromise<FetchInstructionResult, MediaExportContext>(async ({ input }) => {
      return await fetchInstruction(input);
    }),
    videoMachine: videoExportMachine,
    coverMachine: coverExportMachine,
  },
}).createMachine({
  id: 'media-export-machine',
  initial: 'idle',
  states: {
    idle: {
      entry: [
        'registerEventHandlers',
        //General Stuff *salutes*
        'hideTransformGizmo',
        'hideLineRenderer',
        'hideViewportControls',
        'disableSurfaceCollision',
        'hideCameraGizmo',
        'preventCameraInteraction',
        'stopEditingAnnotations',
        'hideAllAnnotations',
      ],
      on: {
        PREPARE: {
          target: 'loading',
          actions: [
            assign({
              workingData: ({ event }) => event.message,
            }),
          ],
        },
      },
    },
    loading: {
      invoke: {
        src: 'fetchInstruction',
        input: ({ context }) => ({ ...context }),
        onDone: {
          actions: assign(({ event }) => {
            return {
              ...event.output,
            };
          }),
        },
      },
      on: {
        LOADED: {
          target: 'awaitingCommand',
        },
        ERROR: { target: 'error' },
        INVALID: { target: 'invalid' },
      },
    },
    awaitingCommand: {
      entry: ['notifyAwaitingCommand'],
      on: {
        EXECUTE: {
          actions: assign({
            currentTask: ({ event }) => event.task,
          }),
          target: 'selectSubmachine',
        },
        FINISHED: {
          target: 'finished',
        },
      },
    },
    selectSubmachine: {
      entry: ['setViewportOptions'],
      always: [
        { target: 'processVideo', guard: ({ context }) => context.currentTask?.action === 'video' },
        { target: 'processCover', guard: ({ context }) => context.currentTask?.action === 'cover' },
        { target: 'finished' },
      ],
    },
    processVideo: {
      invoke: {
        src: 'videoMachine',
        id: 'videoMachine',
        input: ({ context }) => ({ ...context }),
        onDone: {
          target: 'taskComplete',
        },
        onError: {
          target: 'invalid',
        },
      },
      always: {
        actions: sendTo('videoMachine', ({ event }) => event),
      },
    },
    processCover: {
      invoke: {
        src: 'coverMachine',
        id: 'coverMachine',
        input: ({ context }) => ({ ...context }),
        onDone: {
          target: 'taskComplete',
        },
      },
      always: {
        actions: sendTo('coverMachine', ({ event }) => event),
      },
    },
    taskComplete: {
      entry: ['notifyTaskCompleted'],
      always: { target: 'awaitingCommand' },
    },
    finished: {
      type: 'final',
      entry: ['notifyComplete'],
    },
    invalid: {
      type: 'final',
      entry: ['notifyInvalid'],
    },
    error: {
      type: 'final',
      entry: ['notifyError'],
    },
  },
});

export const mediaExportActor = createActor(mediaExportMachine);
//mediaExportActor.subscribe((e) => console.debug('MediaExportActor event', e));
