import { BrandingOptionsDTO } from '@assemblio/shared/dtos';
import { Box, Group, Image, Text } from '@mantine/core';
import { DocumentHeader } from './DocumentHeader';
import { InstructionInformationTable } from './HeaderInformationTable';
import { IconInfoCircle } from '@tabler/icons-react';
import { BrandingColorsType } from '../../BrandingColors';

const LOGO_PATH = 'assets/editor/Logo.png';
const PRODUCT_PATH = 'assets/editor/pdf_preview_product.png';

interface PdfContentProps {
  scalingFactor: number;
  imgSource: string | undefined;
  colors: BrandingColorsType;
  headerInfo: BrandingOptionsDTO | null;
}
export const PdfContent = ({
  scalingFactor,
  imgSource,
  colors,
  headerInfo,
}: PdfContentProps) => {
  return (
    <>
      <Text style={{ position: 'relative', top: '-4%' }}>Preview</Text>
      <Box
        id="content"
        style={{
          margin: '0 5%',
        }}
      >
        <DocumentHeader
          color={colors.headerColor}
          imgSource={imgSource}
          scalingFactor={scalingFactor}
        />

        {/*Product Title*/}
        <Text
          style={{
            position: 'absolute',
            left: '5%',
            top: '10%',
            transform: `scale(${scalingFactor})`,
          }}
          fw={600}
          size="xl"
          c={colors.titleColor}
        >
          Your Project
        </Text>
        <InstructionInformationTable
          headerInfo={headerInfo}
          scalingFactor={scalingFactor}
          color={colors.textColor}
        />

        {/*Product Preview Image*/}
        <Image
          src={PRODUCT_PATH}
          style={{
            position: 'absolute',
            border: '1px solid black',
            top: '22%',
            left: '5%',
            objectFit: 'contain',
          }}
          w={'90%'}
          mah={'36%'}
        />

        {/*Created With*/}
        <Text
          c={'#000'}
          size="8px"
          style={{
            position: 'absolute',
            top: '61.3%',
            left: '34%',
            transform: `scale(${scalingFactor})`,
          }}
        >
          created with
        </Text>
        <Image
          src={LOGO_PATH}
          style={{
            position: 'absolute',
            top: '60.5%',
            left: '45.5%',
            objectFit: 'contain',
            transform: `scale(${scalingFactor})`,
          }}
          w={'100px'}
        />

        {/*Footer */}
        <Text
          c={colors.footerColor}
          size={'8px'}
          style={{
            position: 'absolute',
            top: '96%',
            left: '92%',
            transform: `scale(${scalingFactor})`,
          }}
        >
          1/X
        </Text>
      </Box>
      <Group style={{ position: 'relative', top: '98%' }} mt={'xs'} gap={'xs'}>
        <IconInfoCircle size={14} width={'5%'} />
        <Text size="xs" c={'dimmed'} w={'90%'}>
          This is just a representation of the effects the changes you make will
          have. The final exported PDF File might differ slightly.
        </Text>
      </Group>
    </>
  );
};
