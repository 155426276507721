import {
  useModelStore,
  useSettingsStore,
  useUIStore,
} from '@assemblio/frontend/stores';
import { shallow } from 'zustand/shallow';
import { CameraControls } from './CameraControls';
import { useFramer } from './Framer';
import { Grid } from './Grid';
import { LineRenderer } from './LineRenderer';
import { ImperativeModel } from './Model/ImperativeModel';
import { ObjectControls } from './ObjectControls';
import { Surface } from './Surface';

interface Props {
  onCameraChange?: () => void;
}

export const CanvasElements = ({ onCameraChange }: Props) => {
  const model = useModelStore((state) => state.model);
  const { isGridEnabled, lineWidth, lineColor } = useSettingsStore(
    (state) => ({
      isGridEnabled: state.viewport.grid.enabled,
      lineWidth: state.viewport.grid.thickness,
      lineColor: state.viewport.grid.color,
    }),
    shallow
  );
  // const { isGridEnabled, lineWidth, lineColor } = useSettingsStore(
  //   useShallow((state) => ({ nuts: state.nuts, honey: state.honey })),
  // )
  useFramer();
  const cameraControlsActive = useUIStore(
    (state) => state.cameraControlsActive
  );

  return (
    <>
      <directionalLight
        position={[0, 10, 0]}
        castShadow
        shadow-mapSize={[2048, 2048]}
        intensity={1.5}
      />
      <ambientLight intensity={2} color={0xffffff} />
      {isGridEnabled && (
        <Grid
          radius={20}
          spacing={1}
          lineWidth={lineWidth}
          lineColor={lineColor}
        />
      )}
      {model ? <ImperativeModel gltf={model} /> : null}

      <CameraControls active={cameraControlsActive} onChange={onCameraChange} />
      <ObjectControls />
      <LineRenderer />
      <Surface />
    </>
  );
};
