import { DocumentExportMessage } from '@assemblio/shared/next-types';
import { assign, createActor, fromPromise, setup } from 'xstate';
import {
  createAnimations,
  nextClip,
  skipToEndOfClip,
} from './animation.actions';
import {
  hideAllAnnotations,
  showAnnotationsInPlayingSequence,
} from './annotation.actions';
import {
  disableSurfaceCollision,
  hideCameraGizmo,
  hideLineRenderer,
  hideTransformGizmo,
  hideViewportControls,
  preventCameraInteraction,
  showLineRenderer,
} from './editor.actions';
import {
  hidePartsInSelectedStepAndFollowing,
  movePartsToDisassembledPosition,
} from './model.actions';
import {
  applyStepGroupSelection,
  checkLastSequenceStep,
  fetchInstruction,
  getEmptyContext,
  notifyComplete,
  notifyError,
  notifyReadyToCapture,
  notifyReadyToCaptureCover,
  notifyReadyToRender,
  projectHasSteps,
  registerEventHandlers,
} from './pdf.actions';
import {
  FetchInstructionResult,
  PdfContext,
  PdfEvent,
} from './types/pdf.machine.types';

export const pdfMachine = setup({
  types: {
    context: {} as PdfContext,
    events: {} as PdfEvent,
  },
  actions: {
    applyStepGroupSelection,
    checkLastSequenceStep,
    clearContext: assign(() => getEmptyContext()),
    createAnimations,
    disableSurfaceCollision,
    hideAllAnnotations,
    hideCameraGizmo,
    hideLineRenderer,
    hidePartsInSelectedStepAndFollowing,
    hideTransformGizmo,
    hideViewportControls,
    movePartsToDisassembledPosition,
    nextClip,
    notifyComplete,
    notifyError,
    notifyReadyToCapture,
    notifyReadyToCaptureCover,
    notifyReadyToRender,
    preventCameraInteraction,
    registerEventHandlers,
    showAnnotationsInPlayingSequence,
    showLineRenderer,
    skipToEndOfClip,
  },
  actors: {
    fetchInstruction: fromPromise<FetchInstructionResult, PdfContext>(
      async ({ input }) => {
        return await fetchInstruction(input);
      }
    ),
  },
}).createMachine({
  id: 'pdf-machine',

  context: {},
  initial: 'idle',
  states: {
    idle: {
      entry: [
        'clearContext',
        'registerEventHandlers',
        //General Stuff *salutes*
        'hideTransformGizmo',
        //'hideLineRenderer',
        'showLineRenderer',
        'hideViewportControls',
        'disableSurfaceCollision',
        'hideCameraGizmo',
        'preventCameraInteraction',
      ],
      on: {
        PREPARE: {
          target: 'loading',
          actions: [
            assign({
              exportMessage: ({ event }) => event.message,
            }),
          ],
        },
      },
    },
    loading: {
      invoke: {
        src: 'fetchInstruction',
        input: ({ context }) => ({ ...context }),
        onDone: {
          actions: assign(({ event }) => {
            return {
              ...event.output,
            };
          }),
        },
      },
      on: {
        LOADED: [
          { target: 'ready', guard: projectHasSteps },
          { target: 'finished' },
        ],
        ERROR: { target: 'error' },
      },
    },
    ready: {
      entry: [
        'createAnimations',
        'applyStepGroupSelection',
        'hidePartsInSelectedStepAndFollowing',
        'movePartsToDisassembledPosition',
        'nextClip',
        'notifyReadyToRender',
      ],
      on: {
        START: { target: 'waiting' },
      },
      exit: ['showAnnotationsInPlayingSequence'],
    },
    waiting: {
      entry: ['showLineRenderer', 'notifyReadyToCapture'],
      on: {
        CAPTURED: {
          target: 'check_next_task',
        },
      },
    },
    check_next_task: {
      entry: ['checkLastSequenceStep'],
      on: {
        CONTINUE: { target: 'set_unassembled_position' },
        STOP: { target: 'capture_cover' },
      },
    },
    capture_cover: {
      entry: [
        'skipToEndOfClip',
        'hideLineRenderer',
        'hideAllAnnotations',
        'notifyReadyToCaptureCover',
      ],
      on: {
        CAPTURED: {
          target: 'finished',
        },
      },
    },
    set_unassembled_position: {
      entry: [
        'nextClip',
        'hideAllAnnotations',
        'showAnnotationsInPlayingSequence',
      ],
      always: { target: 'waiting' },
    },
    finished: {
      entry: ['notifyComplete'],
      on: {},
    },
    error: {
      entry: ['notifyError'],
    },
  },
});

export const pdfActor = createActor(pdfMachine);
