import { useAuthStore } from '@assemblio/frontend/data-access';
import { JobState } from '@assemblio/type/job';
import { io, Socket } from 'socket.io-client';
import { create } from 'zustand';
import { devtools, subscribeWithSelector } from 'zustand/middleware';
import {
  ClientToServerEvents,
  ServerToClientEvents,
} from '@assemblio/type/websocket';
export interface EventStore {
  socket: Socket<ServerToClientEvents, ClientToServerEvents>;
  connected: boolean;
  jobStates: Map<string, JobState>;
  jobMessages: Map<string, Array<string>>;
  jobProgress: Map<string, number>;
}

export const useEventStore = create<EventStore>()(
  subscribeWithSelector(
    devtools(
      () => {
        const socket = io({
          auth: (cb) => {
            cb({ token: useAuthStore.getState().accessToken });
          },
          autoConnect: false,
        });
        return {
          socket,
          connected: false,
          jobStates: new Map<string, JobState>(),
          jobMessages: new Map<string, Array<string>>(),
          jobProgress: new Map<string, number>(),
        } as EventStore;
      },
      {
        name: 'Event Store',
        stateSanitizer: <EventStore>(state: EventStore) => {
          return { ...state, socket: undefined };
        },
      }
    )
  )
);
