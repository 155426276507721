import { Button, ComboboxItem, NativeSelect, Paper } from '@mantine/core';

interface TenantSelectionProps {
  tenants: ComboboxItem[];
  selectedTenant: string;
  onTenantChange: (value: string) => void;
  onTenantSelection: () => void;
}

export const TenantSelection = ({
  tenants,
  selectedTenant,
  onTenantChange,
  onTenantSelection,
}: TenantSelectionProps) => (
  <Paper
    withBorder
    shadow="md"
    p={30}
    mt={30}
    radius="md"
    data-cy="tenant-selection"
  >
    <NativeSelect
      label="Select your tenant"
      value={selectedTenant}
      onChange={(event) => onTenantChange(event.currentTarget.value)}
      data={tenants}
      data-cy="select-tenant-type"
    />
    <Button
      fullWidth
      mt="xl"
      data-cy="login-button-select-tenant"
      onClick={() => onTenantSelection()}
    >
      Select Tenant
    </Button>
  </Paper>
);
