import { Button, Paper, PasswordInput, TextInput } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { useFocusTrap } from '@mantine/hooks';

interface LoginFormProps {
  form: UseFormReturnType<{
    email: string;
    password: string;
  }>;
  onSubmit: (
    values: {
      email: string;
      password: string;
    },
    event: React.FormEvent<HTMLFormElement> | undefined
  ) => void;
  loading: boolean;
}

export const LoginForm = ({ form, onSubmit, loading }: LoginFormProps) => {
  const focusTrapRef = useFocusTrap();
  return (
    <form
      ref={focusTrapRef}
      onSubmit={form.onSubmit((values, event) => onSubmit(values, event))}
    >
      <Paper withBorder shadow="md" p={30} mt={30} radius="md">
        <TextInput
          label="Email"
          name="email"
          placeholder="your@email.com"
          withAsterisk
          {...form.getInputProps('email')}
          data-cy="login-input-email"
        />
        <PasswordInput
          label="Password"
          name="password"
          placeholder="Your password"
          withAsterisk
          mt="md"
          {...form.getInputProps('password')}
          data-cy="login-input-password"
        />
        <Button
          loading={loading}
          type="submit"
          fullWidth
          mt="xl"
          data-cy="login-button-submit"
        >
          {loading ? 'Signing in' : 'Sign in'}
        </Button>
      </Paper>
    </form>
  );
};
