import axios, { AxiosRequestConfig } from 'axios';
import { useAuthStore } from '../stores/AuthStore';
import { refreshTokens } from './axios-base.utils';

interface FailedRequestQueueItem {
  resolve: (value?: any) => void;
  reject: (reason?: any) => void;
}

let isRefreshing = false;
let failedQueue: FailedRequestQueueItem[] = [];

const processQueue = (error: any, token: string | null = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

const BASE_URL = '/api';

export const axiosClient = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

export const axiosHandler = async <T>(
  method: 'post' | 'get' | 'patch' | 'put' | 'delete',
  url: string,
  config?: AxiosRequestConfig
): Promise<T> => {
  return axiosClient({ url, method, ...config }).then((res) => res.data);
};

export const axiosHandlerWithResponse = async (
  method: 'post' | 'get' | 'patch' | 'put' | 'delete',
  url: string,
  config?: AxiosRequestConfig
) => {
  return axiosClient({ url, method, ...config });
};

// Request interceptor for API calls
axiosClient.interceptors.request.use(
  async (config) => {
    if (config.headers) {
      if (!config.headers['Authorization']) {
        const accessToken = useAuthStore.getState().accessToken;
        config.headers['Authorization'] = `Bearer ${accessToken}`;
      }
    } else {
      Promise.reject('Malformed Request');
    }

    return config;
  },
  (error) => Promise.reject(error)
);

axiosClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    const prevRequest = error?.config;
    const errorStatus = error?.response?.status;

    // Check if the error is a 401 (Unauthorized) or 403 (Forbidden).
    // And also check if the request has not been retried yet.
    if ((errorStatus === 403 || errorStatus === 401) && !prevRequest?._retry) {
      // If there's a token refresh in progress, then push this request to the queue
      // to be retried after the token has been refreshed.
      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            prevRequest.headers['Authorization'] = `Bearer ${token}`;
            return axiosClient(prevRequest);
          })
          .catch((err) => Promise.reject(err));
      }

      // If no refresh is in progress, initiate the token refresh.
      prevRequest._retry = true;
      isRefreshing = true;

      return refreshTokens()
        .then((newAccessToken) => {
          isRefreshing = false;
          processQueue(null, newAccessToken); // Process the queued requests with the new token

          // Set the new access token in the header and retry the original request.
          prevRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
          return axiosClient(prevRequest);
        })
        .catch((err) => {
          isRefreshing = false;
          processQueue(err, null); // Process the queued requests with an error

          // Clear the session since the refresh token call failed.
          useAuthStore.getState().clearSession();
          return Promise.reject(err);
        });
    }

    // If we get a 401 or 403 error, and the request has been retried already,
    // then clear the session. This might mean both the access and refresh tokens are invalid.
    if ((errorStatus === 403 || errorStatus === 401) && prevRequest?._retry) {
      useAuthStore.getState().clearSession();
      return Promise.reject(error);
    }

    // For all other errors, simply return a rejected promise with the error.
    return Promise.reject(error);
  }
);

/*
// Uncomment the following lines for debug purposes.
*/

// const onRequest = (config: AxiosRequestConfig): AxiosRequestConfig => {
//   console.debug(`[request] [${JSON.stringify(config)}]`);
//   return config;
// };
// const onRequestError = (error: AxiosError): Promise<AxiosError> => {
//   console.debug(`[request error] [${JSON.stringify(error)}]`);
//   return Promise.reject(error);
// };
// const onResponse = (response: AxiosResponse): AxiosResponse => {
//   console.debug(`[response] [${JSON.stringify(response)}]`);
//   return response;
// };
// const onResponseError = (error: AxiosError): Promise<AxiosError> => {
//   console.debug(`[response error] [${JSON.stringify(error)}]`);
//   return Promise.reject(error);
// };
// client.interceptors.request.use(onRequest, onRequestError);
// client.interceptors.response.use(onResponse, onResponseError);
