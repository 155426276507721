import { AnimationController } from '@assemblio/frontend/stores';
import { TartisState } from 'libs/frontend/shared/stores/src/lib/controller/animation/Tartis.types';
import { useEffect, useState } from 'react';

export const usePlaybackState = (): TartisState => {
  const [state, setState] = useState<TartisState>('stopped');

  useEffect(() => {
    return AnimationController.on('stateChange', (state) => {
      setState(state);
    });
  });

  return state;
};
