import { InstructionSequence, PartInStep, Step, StepGroup, StepGroupDto } from '@assemblio/shared/next-types';
import { Transform } from '@assemblio/type/3d';
import produce from 'immer';
import { Quaternion, Vector3 } from 'three';
import { v4 as uuidv4 } from 'uuid';
import { dataToQuaternion, dataToVector3 } from '../helper';
import { StepIndex, useStepIndex } from '../indexes/StepIndex';
import { SelectedStepGroup, Sequence, useSequenceStore } from '../stores/SequenceStore';
import { AnnotationController } from './AnnotationController';
import { StepGroupController } from './StepGroupController';

export namespace SequenceController {
  export const initSequenceStore = (
    selectedSequenceId: string,
    stepGroupsDto: StepGroupDto[],
    instructionSequences: InstructionSequence[]
  ) => {
    setSelectedSequence(selectedSequenceId);
    setStepGroupsFromDto(stepGroupsDto);
    setSequences(instructionSequences);
  };

  export const setStepGroups = (stepGroups: StepGroup[]) => {
    useSequenceStore.setState(
      produce<Sequence>((state: Sequence) => {
        state.stepGroups = stepGroups;
      })
    );

    stepGroups.forEach((stepGroup, stepGroupIndex) => {
      StepIndex.syncStepGroup(stepGroup, stepGroupIndex);
      stepGroup.steps.forEach((step, stepIndex) => {
        StepIndex.syncStep(step, { stepGroupIndex, stepIndex });
        step.annotations.forEach((annotationId) => {
          AnnotationController.associateStepAndAnnotation(annotationId, step.id);
        });
      });
    });
  };

  export const setSelectedStepGroup = (stepGroupId: string) => {
    const index = StepIndex.getStepGroupIndex(stepGroupId);
    useSequenceStore.setState(
      produce<Sequence>((state: Sequence) => {
        state.selectedStepGroup = { id: stepGroupId, index };
      })
    );
  };

  export const setSelectedStepGroupByStepId = (stepId: string) => {
    const stepGroupId = StepIndex.getStep(stepId).stepGroupId;
    setSelectedStepGroup(stepGroupId);
  };

  export const deselectStepGroup = () => {
    useSequenceStore.setState(
      produce<Sequence>((state: Sequence) => {
        state.selectedStepGroup = undefined;
      })
    );
  };

  export const addSequence = (): InstructionSequence | null => {
    let newSequence: InstructionSequence | null = null;
    useSequenceStore.setState(
      produce<Sequence>((state) => {
        newSequence = {
          id: uuidv4(),
          name: 'New Sequence',
          excludedParts: new Array<number>(),
          locked: {
            partIds: new Array<string>(),
            partGroupIds: new Array<string>(),
          },
        };
        state.sequences.push(newSequence);
      })
    );
    return newSequence;
  };

  export const renameSelectedSequence = (name: string) => {
    useSequenceStore.setState(
      produce<Sequence>((state: Sequence) => {
        const selectedSequence = state.sequences.find((s) => s.id === state.selectedSequenceId);
        if (selectedSequence) selectedSequence.name = name;
      })
    );
  };

  const setSelectedSequence = (sequenceId: string) => {
    useSequenceStore.setState(
      produce<Sequence>((state: Sequence) => {
        state.selectedSequenceId = sequenceId;
      })
    );
  };

  const setSequences = (instructionSequences: InstructionSequence[]) => {
    useSequenceStore.setState(
      produce<Sequence>((state: Sequence) => {
        state.sequences = instructionSequences;
      })
    );
  };

  export const setStepGroupsFromDto = (stepGroupsDto: StepGroupDto[]) => {
    setStepGroups(transformSequenceFromDto(stepGroupsDto));
  };

  export const getCurrentStepGroup = (): SelectedStepGroup => {
    const selectedStepGroup = useSequenceStore.getState().selectedStepGroup;
    if (selectedStepGroup) return selectedStepGroup;
    const lastStepGroup = getLastStepGroup();
    return {
      id: lastStepGroup.id,
      index: StepGroupController.getStepGroupDisassemblyOrderById(lastStepGroup.id),
    };
  };

  export const getLastStepGroup = (): StepGroup => {
    return useSequenceStore.getState().stepGroups.at(-1)!;
  };

  export const getLastStep = (): Step | null => {
    const steps = useSequenceStore.getState().stepGroups.at(-1)?.steps;
    if (steps && steps.length > 0) {
      return steps[steps.length - 1];
    } else {
      return null;
    }
  };

  export const addStepGroupAtEnd = (): {
    stepGroup: StepGroup | null;
    prevStepGroupId: string | null;
  } => {
    const instructionSequenceId = useSequenceStore.getState().selectedSequenceId;
    let newStepGroup: StepGroup | null = null;
    let prevStepGroupId: string | null = null;
    useSequenceStore.setState(
      produce<Sequence>((state) => {
        const prevStepGroup = state.stepGroups.at(-1);
        if (prevStepGroup) {
          prevStepGroupId = prevStepGroup.id;
          newStepGroup = {
            id: uuidv4(),
            name: 'New Group',
            instructionSequenceId,
            steps: [],
          };
          const index = state.stepGroups.push(newStepGroup) - 1;
          StepIndex.syncStepGroup(newStepGroup, index);
        }
      })
    );
    return { stepGroup: newStepGroup, prevStepGroupId };
  };

  export const reset = () => {
    useSequenceStore.getState().reset();
    useStepIndex.getState().reset();
  };

  export const getCurrentSequence = () => {
    const selectedSequenceId = useSequenceStore.getState().selectedSequenceId;
    const currentSequence = useSequenceStore
      .getState()
      .sequences.find((sequence) => sequence.id === selectedSequenceId);

    if (!currentSequence) throw new Error(`Something went wrong loading the current sequence`);

    return currentSequence;
  };

  // Move to Adapter pattern when loading data from server
  const transformSequenceFromDto = (stepGroupsDto: StepGroupDto[]): StepGroup[] => {
    const stepGroups: StepGroup[] = stepGroupsDto.map((stepGroupDto) => {
      const steps: Step[] = stepGroupDto.steps.map((stepDto) => {
        const path = stepDto.data
          ? stepDto.data.path.map((element) => {
              const { x, y, z } = element.position;
              const { x: rx, y: ry, z: rz, w: rw } = element.rotation;
              return {
                position: new Vector3(x, y, z),
                rotation: new Quaternion(rx, ry, rz, rw),
              };
            })
          : undefined;
        const parts = stepDto.data
          ? stepDto.data.parts.map<PartInStep>((part) => {
              return {
                partGltfIndex: part.partGltfIndex,
                start: {
                  position: dataToVector3(part.start.position),
                  rotation: dataToQuaternion(part.start.rotation),
                },
                end: {
                  position: dataToVector3(part.end.position),
                  rotation: dataToQuaternion(part.end.rotation),
                },
              };
            })
          : undefined;

        const cameraSettings = {
          ...stepDto.cameraSettings,
          transform: {
            position: dataToVector3(stepDto.cameraSettings.transform.position),
            rotation: dataToQuaternion(stepDto.cameraSettings.transform.rotation),
          },
        };

        const step: Step = {
          ...stepDto,
          cameraSettings: cameraSettings,
          data: {
            path: path as Array<Transform>,
            parts: parts as Array<PartInStep>,
          },
          annotations: stepDto.annotations
            .map((annotation) => annotation.id)
            .filter((value) => value !== undefined) as Array<string>,
        };
        return step;
      });
      return {
        ...stepGroupDto,
        steps,
      };
    });
    return stepGroups;
  };
}
