export enum MediaExportProtocolEvent {
  LOAD_PROJECT = 'load-project',
  READY_TO_RECEIVE = 'ready-to-receive',
  FRAME_RECORDED = 'frame-recorded',
  SET_COMMAND = 'set-command',
  EXPORT_COMPLETE = 'export-complete',
}

export enum MediaExportMachineEvent {
  PREPARE = 'PREPARE',
  LOADED = 'LOADED',
  START = 'START',
  EXECUTE = 'EXECUTE',
  RENDERED = 'RENDERED',
  CAPTURED = 'CAPTURED',
  STOP = 'STOP',
  NEXT = 'NEXT',
  ERROR = 'ERROR',
  INVALID = 'INVALID',
  FINISHED = 'FINISHED',
}
