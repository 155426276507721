import { useStepSetCamera } from '@assemblio/frontend/data-access';
import {
  StepController,
  UIController,
  useCanvasStore,
  useUIStore,
} from '@assemblio/frontend/stores';
import { notifications } from '@mantine/notifications';
import _ from 'lodash';
import { useCallback } from 'react';
import { Box3, Camera, OrthographicCamera, Quaternion, Vector3 } from 'three';

export const useStepCamera = () => {
  const camera = useCanvasStore((state) => state.camera);
  const stepSetCameraMutation = useStepSetCamera();
  const setCameraClipboard = UIController.setCameraClipboard;
  const cameraClipboard = useUIStore((state) => state.cameraClipboard);

  const handleSetCamera = useCallback(() => {
    const selectedStep = useUIStore.getState().selectedStep;
    const selectionBounds = useUIStore.getState().selectionBounds;

    if (camera && selectedStep && selectedStep.id) {
      const previousCameraSettings =
        StepController.getSelectedStep()?.cameraSettings;

      const { position, rotation, distance, zoom } = getCameraSettings(
        camera,
        selectionBounds
      );

      const currentSettings = StepController.setStepCameraTransform(
        selectedStep.id,
        {
          position,
          rotation,
        },
        distance,
        zoom
      );

      stepSetCameraMutation.mutate(
        {
          id: selectedStep.id,
          data: {
            cameraSettings: currentSettings,
          },
        },
        {
          onError: () => {
            if (previousCameraSettings) {
              StepController.setStepCameraTransform(
                selectedStep.id,
                previousCameraSettings.transform,
                previousCameraSettings.distance,
                previousCameraSettings.zoom
              );
            }
          },
          onSuccess: () => {
            notifications.show({
              id: 'save-camera',
              message: 'Camera has been set',
              color: 'blue',
            });
          },
        }
      );
    }
  }, [camera, stepSetCameraMutation]);

  const handleResetCamera = useCallback(() => {
    const selected = useUIStore.getState().selectedStep;
    if (selected) {
      const selectedStep = StepController.getStep(selected.id);
      if (selectedStep) {
        const defaultSettings = useUIStore.getState().defaultCameraSettings;
        const distance = defaultSettings.distance;
        const zoom = defaultSettings.zoom;
        const position = {
          ...defaultSettings.transform.position,
        };

        const rotation = {
          ...defaultSettings.transform.rotation,
        };
        const previousSettings = _.cloneDeep(selectedStep.cameraSettings);

        StepController.setStepCameraTransform(
          selectedStep.id,
          {
            position,
            rotation,
          },
          distance,
          zoom
        );
        stepSetCameraMutation.mutate(
          {
            id: selectedStep.id,
            data: {
              cameraSettings: _.cloneDeep(defaultSettings),
            },
          },
          {
            onError: () => {
              if (previousSettings) {
                StepController.setStepCameraTransform(
                  selectedStep.id,
                  previousSettings.transform,
                  previousSettings.distance,
                  previousSettings.zoom
                );
              }
            },
            onSuccess: () => {
              notifications.show({
                id: 'save-camera',
                message: 'Camera has been set',
                color: 'blue',
              });
            },
          }
        );
      }
    }
    _.defer(() => UIController.setCameraTransformFromSelectedStep());
  }, [stepSetCameraMutation]);

  const copyCameraSettings = useCallback(() => {
    const selectedStep = useUIStore.getState().selectedStep;
    if (!selectedStep) {
      console.error('No step is selected to copy camera settings from.');
      return;
    }

    const step = StepController.getStep(selectedStep.id);
    if (!step) {
      console.error(`Step with ID ${selectedStep.id} not found.`);
      return;
    }

    setCameraClipboard(step.cameraSettings);
    notifications.show({
      id: 'copy-camera',
      message: 'Camera settings copied',
      color: 'green',
    });
  }, [setCameraClipboard]);

  const pasteCameraSettings = useCallback(() => {
    const selectedStep = useUIStore.getState().selectedStep;
    if (!selectedStep) {
      console.error('No step is selected to paste camera settings to.');
      return;
    }

    if (!cameraClipboard) {
      console.error('No camera settings in clipboard to paste.');
      return;
    }

    StepController.setStepCameraSettings(selectedStep.id, cameraClipboard);

    stepSetCameraMutation.mutate(
      {
        id: selectedStep.id,
        data: {
          cameraSettings: cameraClipboard,
        },
      },
      {
        onSuccess: () => {
          notifications.show({
            id: 'paste-camera',
            message: 'Camera settings pasted',
            color: 'blue',
          });
        },
      }
    );
    _.defer(() => UIController.setCameraTransformFromSelectedStep());
  }, [cameraClipboard, stepSetCameraMutation]);

  return {
    handleSetCamera,
    handleResetCamera,
    handleSetCameraPositionToStep,
    copyCameraSettings,
    pasteCameraSettings,
  };
};

const handleSetCameraPositionToStep = () => {
  UIController.setCameraTransformFromSelectedStep();
};

const getCameraSettings = (camera: Camera, selectionBounds: Box3) => {
  const distance = camera.position.distanceTo(
    selectionBounds.getCenter(new Vector3())
  );
  const position = {
    x: camera.position.x,
    y: camera.position.y,
    z: camera.position.z,
  };
  const quaternion = new Quaternion(
    camera.quaternion.x,
    camera.quaternion.y,
    camera.quaternion.z,
    camera.quaternion.w
  );
  const rotation = {
    x: quaternion.x,
    y: quaternion.y,
    z: quaternion.z,
    w: quaternion.w,
  };
  const zoom = (camera as OrthographicCamera).zoom;

  return { position, rotation, distance, zoom };
};
