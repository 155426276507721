import { Box, Button, Group } from '@mantine/core';
import classes from '../ExplorerFolderListView/ExplorerFolderListView.module.scss';
import { IconCirclePlusFilled } from '@tabler/icons-react';
import { useModalContext } from '@assemblio/frontend/components';
import { ExplorerItemType } from '../../../../pages/assembly-plans/types/items.types';

interface ItemMoveModalButtonsProps {
  currentFolderId: string | undefined;
  parentFolderId: string | undefined;
  onClose: () => unknown;
  onProjectMove: (targetId: string) => unknown;
}

export const ExplorerMoveModalButtons = ({
  currentFolderId,
  parentFolderId,
  onClose,
  onProjectMove,
}: ItemMoveModalButtonsProps) => {
  const { openModal } = useModalContext();

  const openCreateFolderModal = () => {
    openModal('create-explorer-item', {
      type: ExplorerItemType.Folder,
      parentFolderId: currentFolderId,
    });
  };

  return (
    <Group mb={'lg'} px={'lg'} justify={'space-between'}>
      <Button
        size={'sm'}
        fw={'bold'}
        py={'sm'}
        px={'lg'}
        variant={'secondary'}
        leftSection={<IconCirclePlusFilled size={16} />}
        onClick={openCreateFolderModal}
      >
        New Folder
      </Button>
      <Group>
        <Button
          py={'sm'}
          px={'lg'}
          size={'sm'}
          fw={'bold'}
          variant={'secondary'}
          onClick={(e) => {
            if (currentFolderId) {
              e.preventDefault();
              e.stopPropagation();
              onClose();
            }
          }}
        >
          Cancel
        </Button>
        <Button
          py={'sm'}
          px={'lg'}
          size={'sm'}
          fw={'bold'}
          variant={'primary'}
          disabled={parentFolderId === currentFolderId}
          onClick={(e) => {
            if (currentFolderId) {
              e.preventDefault();
              e.stopPropagation();
              onProjectMove(currentFolderId);
            }
          }}
        >
          Move
        </Button>
      </Group>
    </Group>
  );
};
