import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteArtefact } from './artefact.requests';
import { notifications } from '@mantine/notifications';

export const useArtefactDelete = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (options: { instructionId: string; artefactId: string }) =>
      deleteArtefact(options.instructionId, options.artefactId),
    {
      onSuccess: (_data, variables) => {
        queryClient.invalidateQueries(['artefacts', variables.instructionId]);
      },
      onError: () => {
        notifications.show({
          id: 'delete-artefact-error',
          message: 'Artefact could not be deleted',
          color: 'red',
        });
      },
    }
  );
};
