import produce from 'immer';
import { Sequence, useSequenceStore } from '../stores/SequenceStore';
import { StepIndex } from '../indexes/StepIndex';
import { SequenceController } from './SequenceController';
import { PartInStep } from '@assemblio/shared/next-types';
import { MachineController, StepController } from '.';

export namespace StepGroupController {
  export const getStepGroupDisassemblyOrderById = (stepGroupId: string): number => {
    return StepIndex.getStepGroupIndex(stepGroupId);
  };

  export const renameStepGroup = (stepGroupId: string, name: string): void => {
    const index = getStepGroupDisassemblyOrderById(stepGroupId);

    useSequenceStore.setState(
      produce<Sequence>((state) => {
        state.stepGroups[index].name = name;
      })
    );
  };

  export const reorder = (
    startIndex: number,
    endIndex: number
  ): {
    movedStepGroupId: string | undefined;
    newPrev: string | undefined | null;
  } => {
    let movedStepGroupId: string | undefined;
    let newPrev: string | undefined | null;

    useSequenceStore.setState(
      produce<Sequence>((state: Sequence) => {
        const stepGroups = state.stepGroups;
        const [removed] = stepGroups.splice(startIndex, 1);
        stepGroups.splice(endIndex, 0, removed);

        movedStepGroupId = removed.id;
        newPrev = endIndex > 0 ? stepGroups[endIndex - 1].id : null;
      })
    );

    StepIndex.updateStepGroupMap(startIndex, endIndex);

    return { movedStepGroupId, newPrev };
  };

  export const getStepGroup = (groupId: string) => {
    return useSequenceStore.getState().stepGroups.find((group) => group.id === groupId);
  };

  export const getStepByIndex = (groupId: string, index: number) => {
    return getStepGroup(groupId)?.steps.at(index);
  };

  export const getGroupIndex = (groupId: string) => {
    return useSequenceStore.getState().stepGroups.findIndex((group) => group.id === groupId);
  };

  export const getGroupByIndex = (index: number) => {
    return useSequenceStore.getState().stepGroups.at(index);
  };

  export const orderIndices = (
    a: { groupIndex: number; stepIndex: number },
    b: { groupIndex: number; stepIndex: number }
  ) => {
    if (a.groupIndex < b.groupIndex) {
      return [a, b];
    }
    if (a.groupIndex > b.groupIndex) {
      return [b, a];
    }
    if (a.stepIndex < b.stepIndex) {
      return [a, b];
    }
    if (a.stepIndex > b.stepIndex) {
      return [b, a];
    }
    return [a, b];
  };

  export const getStepsBetweenIndices = (
    fromIndex: { groupIndex: number; stepIndex: number },
    toIndex: { groupIndex: number; stepIndex: number }
  ) => {
    const fromStep = StepController.getStepByIndex(fromIndex.groupIndex, fromIndex.stepIndex);
    const [from, to] = orderIndices(fromIndex, toIndex);

    const intermediateSteps = [];
    for (let i = from.groupIndex; i <= to.groupIndex; i++) {
      const stepGroup = getGroupByIndex(i);
      if (stepGroup) {
        const start = i === from.groupIndex ? from.stepIndex : 0;
        const finish = i === to.groupIndex ? to.stepIndex : stepGroup?.steps.length;
        if (finish !== 0) {
          for (let j = start; j <= finish; j++) {
            const step = stepGroup.steps.at(j);
            if (step && step.id !== fromStep?.id) {
              intermediateSteps.push(step);
            }
          }
        }
      }
    }
    return intermediateSteps;
  };

  export const getStepsBetween = (from: { groupId: string; index: number }, to: { groupId: string; index: number }) => {
    const fromIndex = getGroupIndex(from.groupId);
    const toIndex = getGroupIndex(to.groupId);
    return getStepsBetweenIndices(
      { groupIndex: fromIndex, stepIndex: from.index },
      { groupIndex: toIndex, stepIndex: to.index }
    );
  };

  export const getStepGroupsBetweenIndices = (from: number, to: number) => {
    const fromGroup = getGroupByIndex(from);
    [from, to] = from < to ? [from, to] : [to, from];
    const groupsBetween = [];
    for (let i = from; i <= to; i++) {
      const group = getGroupByIndex(i);
      if (group && group.id !== fromGroup?.id) {
        groupsBetween.push(group);
      }
    }
    return groupsBetween;
  };

  export const getPartsInGroup = (id: string) => {
    return getStepGroup(id)
      ?.steps.flatMap((step) => step.data.parts)
      .filter((part) => part !== undefined) as PartInStep[];
  };

  export const canStepGroupBeMoved = (from: number, to: number) => {
    const fromGroup = getGroupByIndex(from);
    const toGroup = getGroupByIndex(to);
    if (!fromGroup || !toGroup) return false;
    const groupsBetween = getStepGroupsBetweenIndices(from, to);
    const partsInFromGroup = getPartsInGroup(fromGroup.id);
    const partsBetween = groupsBetween.flatMap((group) => getPartsInGroup(group.id));
    return partsBetween.every(
      (part) => partsInFromGroup.find((other) => other.partGltfIndex === part.partGltfIndex) === undefined
    );
  };

  export const deleteStepGroup = (stepGroupId: string): boolean => {
    if (useSequenceStore.getState().stepGroups.length <= 1) {
      return false;
    }
    const index = getStepGroupDisassemblyOrderById(stepGroupId);

    useSequenceStore.setState(
      produce<Sequence>((state) => {
        state.stepGroups.splice(index, 1);
      })
    );

    StepIndex.desyncStepGroup(stepGroupId);
    SequenceController.deselectStepGroup();
    //UIController.selectStepById();
    MachineController.deselect();
    return true;
  };
}
