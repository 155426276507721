import { Quaternion, Transform, Vector } from '@assemblio/type/3d';
import { Matrix4, Quaternion as ThreeQuaternion, Vector3 } from 'three';

export const vector3ToData = (vector: Vector3) => {
  return {
    x: vector.x,
    y: vector.y,
    z: vector.z,
  };
};

export const dataToVector3 = (vector: Vector) => {
  return new Vector3(vector.x, vector.y, vector.z);
};

export const quaternionToData = (quaternion: ThreeQuaternion) => {
  return {
    x: quaternion.x,
    y: quaternion.y,
    z: quaternion.z,
    w: quaternion.w,
  };
};

export const dataToQuaternion = (quaternion: Quaternion) => {
  return new ThreeQuaternion(
    quaternion.x,
    quaternion.y,
    quaternion.z,
    quaternion.w
  );
};

export const transformToMatrix = ({ position, rotation }: Transform) => {
  return new Matrix4().compose(
    dataToVector3(position),
    dataToQuaternion(rotation),
    new Vector3(1, 1, 1)
  );
};

export const matrixToTransform = (matrix: Matrix4) => {
  const position = new Vector3();
  const rotation = new ThreeQuaternion();
  const scale = new Vector3();
  matrix.decompose(position, rotation, scale);
  return {
    position: vector3ToData(position),
    rotation: quaternionToData(rotation),
  };
};
