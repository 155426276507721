import { useEventStore } from '@assemblio/frontend/stores';
import { Box, Button, Modal, Overlay, Text } from '@mantine/core';

export const InactiveWindowOverlay = () => {
  const socket = useEventStore((state) => state.socket);

  const setSessionActive = () => {
    socket.emit('activate-session');
  };
  return (
    <Overlay>
      <Modal
        withCloseButton={false}
        centered
        withinPortal
        opened
        onClose={() => null}
      >
        <Box p={'sm'}>
          <Text fw={700} size="xl">
            Window is Inactive
          </Text>
          <Text c={'dimmed'} size="sm">
            You appear to be using the application in another browser tab or
            window. There can only be one active window at a time. If you want
            to continue editing here, please activate the window.
          </Text>
          <Button fullWidth mt={'xl'} onClick={setSessionActive}>
            Activate this window
          </Button>
        </Box>
      </Modal>
    </Overlay>
  );
};
