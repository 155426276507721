export enum SelectionMode {
  ADD,
  SUBTRACT,
  REPLACE,
}

export enum DrawMode {
  SOLID,
  TRANSPARENT,
  WIREFRAME,
}

export enum TransformMode {
  DISASSEMBLY,
  GLOBAL,
}

export interface Grid {
  [property: string]: number | boolean | string;
  enabled: boolean;
  color: string;
  thickness: number;
}

// keyof Grid doesn't seem to work well with the indexer
// typing does not work at the moment, this needs to be fixed
//export type GridKeys = 'enabled' | 'color' | 'transparency' | 'thickness';
export type GridKeys = keyof Grid;

export interface Table {
  [property: string]: boolean | string;
  enabled: boolean;
  color: string;
}

// keyof Table doesn't seem to work well with the indexer
// typing does not work at the moment, this needs to be fixed
//export type TableKeys = 'enabled' | 'color';
export type TableKeys = keyof Table;
