import axios, { AxiosRequestConfig } from 'axios';
import { axiosClient } from './axios-utils';

declare global {
  interface Window {
    apiKey: string;
  }
}

export const axiosSharedClient = (
  view: 'editor' | 'viewer' | 'renderer',
  config: AxiosRequestConfig
) => {
  if (view === 'renderer') {
    return axiosAPIKeyClient(config);
  } else {
    return axiosClient(config);
  }
};

const BASE_URL = '/api';

//Client is used by Microservice Frontends and the Editor
export const axiosAPIKeyClient = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

// Request interceptor for API calls
axiosAPIKeyClient.interceptors.request.use(
  async (config) => {
    if (config.headers) {
      if (!config.headers['Authorization']) {
        const apiKey = window.apiKey;
        config.headers['Authorization'] = `ApiKey ${apiKey}`;
      }
    } else {
      Promise.reject('Malformed Request');
    }

    return config;
  },
  (error) => Promise.reject(error)
);

