import { Button, Modal, Group, Text } from '@mantine/core';

interface ConfirmationDialogProps {
  opened: boolean;
  prompt: string;
  onClose: (result: boolean) => void;
}

export const ConfirmationDialog = ({
  opened,
  prompt,
  onClose,
}: ConfirmationDialogProps) => {
  return (
    <Modal
      title={<Text>Please confirm</Text>}
      data-cy="confirmation-modal"
      opened={opened}
      withCloseButton
      onClose={() => onClose(false)}
      size="md"
      radius={0}
    >
      <Text size="sm" style={{ marginBottom: 10 }} fw={500}>
        {prompt}
      </Text>

      <Group align="flex-end" justify="right">
        <Button onClick={() => onClose(false)}>No</Button>
        <Button onClick={() => onClose(true)}>Yes</Button>
      </Group>
    </Modal>
  );
};
