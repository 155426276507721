import { axiosHandler } from '../utils/axios-utils';
import { SequencePartReference } from './types/sequences.mutation.types';

export const excludePartFromSequence = async ({ id, data }: SequencePartReference) => {
  return await axiosHandler('put', `/sequences/${id}/parts/exclude`, { data });
};

export const includePartInSequence = async ({ id, data }: SequencePartReference) => {
  return await axiosHandler('delete', `/sequences/${id}/parts/exclude`, { data });
};
