import { ImageDto } from '@assemblio/shared/dtos';
import { AnnotationMetaDto } from '@assemblio/shared/next-types';
import {
  UseMutationResult,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { useRef } from 'react';
import { ProgressCallback } from '../utils/progress-event';
import {
  addAnnotationToStep,
  createAnnotation,
  createImageAnnotation,
  deleteAnnotation,
  deleteImageAnnotation,
  removeAnnotationFromStep,
  updateAnnotation,
  uploadAnnotationImage,
} from './annotation.requests';
import {
  AnnotationCreate,
  AnnotationImageCreate,
  ImagesUpload,
} from './types/annotation.mutation.types';

export const useUploadAnnotationImages = (
  events?: ProgressCallback
): [
  UseMutationResult<
    Readonly<{ images: ImageDto[] }>,
    unknown,
    ImagesUpload,
    unknown
  >,
  React.MutableRefObject<AbortController | null>
] => {
  const abortControllerRef = useRef<AbortController | null>(null);
  const queryClient = useQueryClient();
  return [
    useMutation(
      async (imageUpload: ImagesUpload) => {
        abortControllerRef.current = new AbortController();
        return uploadAnnotationImage(
          imageUpload,
          abortControllerRef.current?.signal,
          events
        );
      },
      {
        onSettled: () => {
          queryClient.invalidateQueries(['images']);
        },
      }
    ),
    abortControllerRef,
  ];
};

export const useAnnotationCreate = () => {
  return useMutation((create: AnnotationCreate) => {
    return createAnnotation(
      create.id,
      create.data.stepId,
      create.data.annotation
    );
  });
};

export const useAnnotationImageCreate = () => {
  return useMutation((create: AnnotationImageCreate) => {
    return createImageAnnotation(
      create.id,
      create.data.stepId,
      create.data.annotation,
      create.data.file
    );
  });
};

export const useUpdateAnnotation = () => {
  return useMutation(
    (data: { id: string; meta: Partial<AnnotationMetaDto> }) => {
      return updateAnnotation(data.id, data.meta);
    }
  );
};

export const useAddAnnotationToStep = () => {
  return useMutation((data: { annotationId: string; stepId: string }) => {
    return addAnnotationToStep(data.annotationId, data.stepId);
  });
};

export const useRemoveAnnotationFromStep = () => {
  return useMutation((data: { annotationId: string; stepId: string }) => {
    return removeAnnotationFromStep(data.annotationId, data.stepId);
  });
};

export const useDeleteAnnotation = () => {
  return useMutation((annotationId: string) => {
    return deleteAnnotation(annotationId);
  });
};

export const useDeleteImageAnnotation = () => {
  return useMutation((annotationId: string) => {
    return deleteImageAnnotation(annotationId);
  });
};
