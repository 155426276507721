import { StepCameraSettingsDto } from '@assemblio/shared/next-types';
import { Quaternion, Transform, Vector } from '@assemblio/type/3d';

export const sanitizeQuaternion = (quaternion: Quaternion) => {
  return {
    x: quaternion.x,
    y: quaternion.y,
    z: quaternion.z,
    w: quaternion.w,
  };
};

export const sanitizeVector = (vector: Vector): Vector => {
  return {
    x: vector.x,
    y: vector.y,
    z: vector.z,
  };
};

export const sanitizeTransform = (transform: Transform): Transform => {
  return {
    position: sanitizeVector(transform.position),
    rotation: sanitizeQuaternion(transform.rotation),
  };
};

export const sanitizeCameraSettings = (settings?: StepCameraSettingsDto) => {
  if (!settings) return settings;
  return { ...settings, transform: sanitizeTransform(settings.transform) };
};
