import { Divider, Image, Text } from '@mantine/core';
interface DocumentHeaderProps {
  color: string;
  imgSource: string | undefined;
  scalingFactor: number;
}

export const DocumentHeader = ({
  color,
  imgSource,
  scalingFactor,
}: DocumentHeaderProps) => {
  return (
    <>
      <Text
        style={{
          position: 'absolute',
          left: '5%',
          top: '5.5%',
          transform: `scale(${scalingFactor})`,
        }}
        size="6px"
        c={color}
      >
        <Text span mr={'5px'} fw={600}>
          Your Project
        </Text>{' '}
        Editor @ Your Company 01.01.2000 - v1.0
      </Text>
      <Image
        style={{
          position: 'absolute',
          top: '0%',
          left: '76%',
          height: '9%',
          paddingTop: '4%',
          objectFit: 'contain',
          transform: `scale(${scalingFactor})`,
        }}
        w={100}
        src={imgSource}
      />
      <Divider
        style={{
          position: 'absolute',
          top: '9%',
          width: '90%',
        }}
      />
    </>
  );
};
