import { AspectRatio, useCanvasStore } from '@assemblio/frontend/stores';
import { RootState } from '@react-three/fiber';
import _ from 'lodash';
import { PropsWithChildren, useEffect, useRef, useState } from 'react';

interface Props {
  ratio: AspectRatio;
  rootState?: RootState;
}

const getAspectFactor = (
  ratio: AspectRatio,
  screen: { width: number; height: number }
): number => {
  switch (ratio) {
    case '16/10':
      return 16 / 10;
    case '16/9':
      return 16 / 9;
    case '4/3':
      return 4 / 3;
    case 'pdf':
      return 210 / 90;
    default:
      return screen.width / screen.height;
  }
};

const ifRatioIsAuto = (
  ratio: AspectRatio,
  ifTrue: string,
  ifFalse: string
): string => {
  return ratio === 'auto' ? ifTrue : ifFalse;
};

export const AspectContainer = (props: PropsWithChildren<Props>) => {
  const ratio = props.ratio;
  const [width, setWidth] = useState('100%');
  const [height, setHeight] = useState('100%');
  const containerRef = useRef<HTMLDivElement>(null);
  const camera = useCanvasStore((state) => state.camera);

  useEffect(() => {
    if (containerRef.current && containerRef.current.parentElement) {
      const parent = containerRef.current.parentElement;
      const observer = new ResizeObserver((entries) => {
        if (entries && entries.length && props.rootState) {
          const rect = entries[0].contentRect;
          const aspectFactor = getAspectFactor(ratio, rect);
          let newHeight = rect.height;
          let newWidth = rect.width;
          if (window.visualViewport) {
            if (rect.width / aspectFactor < window.visualViewport.height) {
              newHeight = rect.width / aspectFactor;
              newWidth = rect.width;
            } else {
              newWidth = rect.height * aspectFactor;
              newHeight = rect.height;
            }
          }
          setHeight(`${newHeight}px`);
          setWidth(`${newWidth}px`);
        }
      });
      observer.observe(parent);

      return () => {
        observer.unobserve(parent);
      };
    }
    return _.noop;
  }, [containerRef, ratio, camera, props.rootState]);
  return (
    <div
      ref={containerRef}
      style={{
        position: 'relative',
        width: ifRatioIsAuto(ratio, width, `calc(${width} - 4px)`),
        height: ifRatioIsAuto(ratio, height, `calc(${height} - 4px)`),
        overflow: 'hidden',
        margin: ifRatioIsAuto(ratio, '0px', '-4px'),
        border: ifRatioIsAuto(ratio, 'none', '2px dashed rgb(193, 194, 197)'),
      }}
    >
      {props.children}
    </div>
  );
};
