import { useQuery } from '@tanstack/react-query';
import { fetchExportJobs, fetchJobsForInstruction } from './job.requests';

export const useInstructionJobQuery = (instructionId: string) => {
  return useQuery(['jobs', instructionId], () => fetchJobsForInstruction(instructionId));
};

export const useProductExportJobQuery = (productId: string | undefined) => {
  return useQuery(['export-jobs', productId], () => fetchExportJobs(productId));
};
