import { useAuthStore } from '@assemblio/frontend/data-access';
import { AppRole } from '@assemblio/type/user';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { InactiveWindowOverlay } from '../Common/InactiveWindowWarning';

interface RequireAuthProps {
  allowedRoles: AppRole[];
}

export const RequireAuth = ({ allowedRoles }: RequireAuthProps) => {
  const { appRole, accessToken } = useAuthStore();
  const location = useLocation();

  const isSessionActive = useAuthStore((state) => state.isSessionActive);

  return allowedRoles.includes(appRole as AppRole) ? (
    <>
      {!isSessionActive && <InactiveWindowOverlay />}
      <Outlet />
    </>
  ) : accessToken ? (
    <Navigate to="/unauthorized" state={{ from: location }} replace />
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};
