import {
  PatchState,
  StoreVersion,
  UndoRedoAction,
  UndoRedoStore,
  UndoRedoVersionStore,
} from '../stores/types/undo-redo.types';
import produce, { applyPatches, Patch } from 'immer';
import { StoreApi, UseBoundStore } from 'zustand';
import { StepIndex } from '../indexes/StepIndex';
import { useProjectStore, useSequenceStore } from '../stores';
import { useUndoRedoStore } from '../stores/UndoRedoStore';
import { MachineController } from './MachineController';
import { StepController, ModelController } from '.';
import { ModelVisibilityController } from './ModelVisibilityController';

export namespace UndoRedoController {
  /**
   * Indicates whether a rollback to a previous version is possible
   * @returns {boolean}
   */
  export const canUndo = (version: number) => {
    const { versionHistory, deletedVersionsOffset } = useUndoRedoStore.getState();
    return version > 0 && versionHistory.size > 0 && version >= deletedVersionsOffset;
  };
  /**
   * Indicates whether a recovery to a newer version is possible
   * @returns {boolean}
   */
  export const canRedo = (version: number) => {
    const { versionHistory } = useUndoRedoStore.getState();
    return versionHistory.get(version + 1);
  };
  /**
   * Creates a new patch and stores it as a new version in the corresponding store
   * @param  {UseBoundStore<StoreApi<T>>} store - The store in which a change was made
   * @param  {T} baseState - The current state of the store
   * @param  {Patch[]} patches - Patches which are created by immer
   * @param  {Patch[]} inversePatches - InversePatches which are created by immer
   */
  export const createPatch = <T extends PatchState>(
    store: UseBoundStore<StoreApi<T>>,
    baseState: T,
    patches: Patch[],
    inversePatches: Patch[],
  ) => {
    store.setState(
      produce(baseState, (draft: T) => {
        const patchStateSize = draft.patchState.size;
        if (draft.currentVersion < patchStateSize) {
          for (let i = draft.currentVersion + 1; i <= patchStateSize; i++) {
            draft.patchState.delete(i);
          }
        }

        draft.currentVersion++;
        draft.patchState.set(draft.currentVersion, {
          inversePatches: inversePatches,
          patches: patches,
        });
      }),
    );
  };
  /**
   * Utility function to create a patch along with the version.
   * @param  {UseBoundStore<StoreApi<T>>} store - The store in which a change was made
   * @param  {string} description - Description text of the change made
   * @param  {UndoRedoAction} undoRedoAction - ActionType with custom data
   * @param  {T} baseState - The current state of the store
   * @param  {Patch[]} patches - Patches which are created by immer
   * @param  {Patch[]} inversePatches - InversePatches which are created by immer
   */
  export const createPatchAndVersion = <T extends PatchState>(
    store: UseBoundStore<StoreApi<T>>,
    description: string,
    undoRedoAction: UndoRedoAction,
    baseState: T,
    patches: Patch[],
    inversePatches: Patch[],
  ) => {
    createPatch(store, baseState, patches, inversePatches);
    createVersion(description, undoRedoAction);
  };

  /**
   * Reverts the state of a store to a specific patch version
   * @param  {StoreVersion} storeVersion - Contains the target patch version as well as the corresponding store to modify
   */
  export const applyUndoPatch = (storeVersion: StoreVersion) => {
    const { currentVersion, patchState } = storeVersion.store.getState();
    try {
      let patches: Patch[] = [];
      if (currentVersion === storeVersion.version) {
        const patch = patchState.get(currentVersion);
        if (patch) {
          patches = patches.concat(patch.inversePatches);
        }
      } else {
        for (let i = currentVersion - 1; i >= storeVersion.version; i--) {
          const patch = patchState.get(i);
          if (patch) {
            patches = patches.concat(patch.inversePatches);
          }
        }
      }

      if (patches.length > 0) {
        storeVersion.store.setState(
          produce(storeVersion.store.getState(), (draft: UndoRedoVersionStore) => {
            draft.currentVersion = storeVersion.version;
            applyPatches(draft, patches);
          }),
          true,
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  /**
   * Restores the state of a store to a specific patch version
   * @param  {StoreVersion} storeVersion - Contains the target patch version as well as the corresponding store to modify
   */
  export const applyRedoPatch = (storeVersion: StoreVersion) => {
    const { currentVersion, patchState } = storeVersion.store.getState();

    let patches: Patch[] = [];
    if (currentVersion === storeVersion.version) {
      const patch = patchState.get(currentVersion);
      if (patch) {
        patches = patches.concat(patch.patches);
      }
    } else {
      for (let i = currentVersion + 1; i <= storeVersion.version; i++) {
        const patch = patchState.get(i);
        if (patch) {
          patches = patches.concat(patch.patches);
        }
      }
    }

    if (patches.length > 0) {
      storeVersion.store.setState(
        produce(storeVersion.store.getState(), (draft: UndoRedoVersionStore) => {
          draft.currentVersion = storeVersion.version;
          applyPatches(draft, patches);
        }),
        true,
      );
    }
  };
  /**
   * Generates a new UndoRedo version in the version history
   * @param  {string} description - Description text of the change made
   * @param  {UndoRedoAction} undoRedoAction - ActionType with custom data
   */

  export const createVersion = (description: string, undoRedoAction: UndoRedoAction) => {
    const MAX_AMOUNT_VERSIONS = 50;
    /*
    States from which the state is to be saved, future stores must 1. be added
    to the type "UndoRedoVersionStore" and 2. be extended by the interface "PatchState".
    */
    const STORE_WATCH_LIST: UseBoundStore<StoreApi<UndoRedoVersionStore>>[] = [useProjectStore];

    useUndoRedoStore.setState(
      produce(useUndoRedoStore.getState(), (draft: UndoRedoStore) => {
        const versionHistorySize = draft.versionHistory.size;
        // If an overwrite of a version takes place, all versions following will be deleted.
        if (draft.currentVersion < versionHistorySize) {
          for (let i = draft.currentVersion + 1; i <= versionHistorySize; i++) {
            draft.versionHistory.delete(i);
          }
        }
        draft.currentVersion++;
        draft.versionHistory.set(draft.currentVersion, {
          description: description,
          action: undoRedoAction,
          storeVersions: STORE_WATCH_LIST.map(
            (store) =>
              ({
                version: store.getState().currentVersion,
                store: store,
              }) as StoreVersion,
          ),
        });
        // Deletion of the previous versions if "MAX_AMOUNT_VERSIONS" has been reached.
        if (draft.versionHistory.size > MAX_AMOUNT_VERSIONS) {
          draft.versionHistory.delete(draft.deletedVersionsOffset);
          draft.deletedVersionsOffset++;
        }
      }),
    );
  };

  /**
   * Revert the state of all tracked stores to the last version (if one is available)
   */
  export const applyUndoVersion = () => {
    const { currentVersion, versionHistory } = useUndoRedoStore.getState();
    const undoRedoVersion = versionHistory.get(currentVersion);
    if (undoRedoVersion === undefined) return;

    applyAction(undoRedoVersion.action, true, undoRedoVersion.storeVersions);

    useUndoRedoStore.setState(
      produce(useUndoRedoStore.getState(), (draft: UndoRedoStore) => {
        draft.currentVersion--;
      }),
    );
  };

  /**
   * Restores the state to the next version (if one is available)
   */
  export const applyRedoVersion = () => {
    const { currentVersion, versionHistory } = useUndoRedoStore.getState();
    const undoRedoVersion = versionHistory.get(currentVersion + 1);
    if (undoRedoVersion === undefined) return;

    applyAction(undoRedoVersion.action, false, undoRedoVersion.storeVersions);

    useUndoRedoStore.setState(
      produce(useUndoRedoStore.getState(), (draft: UndoRedoStore) => {
        draft.currentVersion++;
      }),
    );
  };

  /**
   * Clears the whole version history in the UndoRedoStore
   */
  export const clearVersionHistory = () => {
    useUndoRedoStore.setState(
      produce(useUndoRedoStore.getState(), (draft: UndoRedoStore) => {
        draft.currentVersion = 0;
        draft.versionHistory.clear();
      }),
    );
  };

  /**
   * Applies individual actions depending on the type.
   * Execution of the "applyPatch" functionality often depends on the specific action type,
   * which is why it is performed individually here.
   * @param  {UndoRedoAction} action - ActionType with custom data
   * @param  {boolean} isUndo - Whether Undo or Redo is performed
   */
  export const applyAction = (action: UndoRedoAction, isUndo: boolean, storeVersionArr: StoreVersion[]) => {
    if (action.type === 'no-action-needed' && isUndo) {
      for (const storeVersion of storeVersionArr) {
        applyUndoPatch(storeVersion);
      }
    }

    if (action.type === 'no-action-needed' && !isUndo) {
      for (const storeVersion of storeVersionArr) {
        applyRedoPatch(storeVersion);
      }
    }

    if (action.type === 'sync-step-index' && action.data.stepIndex !== undefined) {
      for (const storeVersion of storeVersionArr) {
        if (isUndo) {
          applyUndoPatch(storeVersion);
        } else {
          applyRedoPatch(storeVersion);
        }
      }
      const step = useSequenceStore.getState().stepGroups[0].steps[action.data.stepIndex];
      //StepIndex.sync(step, action.data.stepIndex);

      step.data.parts.forEach((part) => {
        ModelController.movePartByProgress(part.partGltfIndex, 'disassembled');
        ModelVisibilityController.setPartVisible(part.partGltfIndex, true);
      });
    }

    // Executed when adding a step is being undone
    if (action.type === 'add-step' && isUndo && action.data.stepId) {
      const step = StepController.getStep(action.data.stepId);
      if (!step) {
        console.error('[UndoRedoController] applyAction - Step was not found');
        return;
      }
      StepIndex.desyncStep(step);

      step.data.parts.forEach((part) => {
        ModelController.movePartByProgress(part.partGltfIndex, 'assembled');
        ModelVisibilityController.setPartVisible(part.partGltfIndex, true);
      });

      MachineController.deselect();

      for (const storeVersion of storeVersionArr) {
        applyUndoPatch(storeVersion);
      }
    }

    // Executed when adding a step is restored
    if (action.type === 'add-step' && !isUndo && action.data.stepId) {
      for (const storeVersion of storeVersionArr) {
        applyRedoPatch(storeVersion);
      }

      const step = StepController.getStep(action.data.stepId);
      const index = StepIndex.getStepIndex(action.data.stepId);
      if (!step) {
        console.error('[UndoRedoController] applyAction - Step was not found');
        return;
      }
      //StepIndex.sync(step, index);

      step.data.parts.forEach((part) => {
        ModelVisibilityController.setPartVisible(part.partGltfIndex, false);
        ModelController.movePartByProgress(part.partGltfIndex, 'disassembled');
      });

      MachineController.deselect();
    }

    // Executed when removing a step is being undone
    if (action.type === 'remove-step' && isUndo && action.data.stepId) {
      for (const storeVersion of storeVersionArr) {
        applyUndoPatch(storeVersion);
      }

      const step = StepController.getStep(action.data.stepId);
      const index = StepIndex.getStepIndex(action.data.stepId);
      if (!step) {
        console.error('[UndoRedoController] applyAction - Step was not found');
        return;
      }
      //StepIndex.sync(step, index);

      step.data.parts.forEach((part) => {
        ModelVisibilityController.setPartVisible(part.partGltfIndex, false);
        ModelController.movePartByProgress(part.partGltfIndex, 'disassembled');
      });

      MachineController.deselect();
    }

    // Executed when removing a step is restored
    if (action.type === 'remove-step' && !isUndo && action.data.stepId) {
      const step = StepController.getStep(action.data.stepId);
      if (!step) {
        console.error('[UndoRedoController] applyAction - Step was not found');
        return;
      }
      StepIndex.desyncStep(step);

      step.data.parts.forEach((part) => {
        ModelController.movePartByProgress(part.partGltfIndex, 'assembled');
        ModelVisibilityController.setPartVisible(part.partGltfIndex, true);
      });

      MachineController.deselect();

      for (const storeVersion of storeVersionArr) {
        applyRedoPatch(storeVersion);
      }
    }
  };
}
